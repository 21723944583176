<template>
  <div>
    <div style="min-height: 100vh">
      <headerComponent />
      <v-container>
        <v-row>
          <v-spacer />
          <v-col :cols="getCols">
            <h1 class="encodeFont">Impressum</h1>

            <h2 class="encodeFont">Herausgeber</h2>
            <p style="font-size: 18px">
              Industrie- und Handelskammer Halle-Dessau<br />
              Franckestraße 5, 06110 Halle (Saale)<br />
              Tel.: 0345 2126-0<br />
              Fax: 0345 2126-105<br />
              E-Mail: info@halle.ihk.de
            </p>
            <h2 class="encodeFont">Vertretungsberechtigte</h2>
            <p style="font-size: 18px">
              Gem. § 7 Abs. 2 IHKG in Verbindung mit § 12 Abs. 1 der Satzung der IHK
              Halle-Dessau vertreten der Präsident und der Hauptgeschäftsführer die IHK
              rechtsgeschäftlich und gerichtlich. Für die Geschäfte der laufenden
              Verwaltung ist der Hauptgeschäftsführer allein vertretungsberechtigt (§ 12
              Abs. 3 Satzung der IHK Halle-Dessau)
            </p>
            <h2 class="encodeFont">Präsident der IHK Halle-Dessau:</h2>
            <p style="font-size: 18px">
              Sascha Gläßer<br />
              Industrie- und Handelskammer Halle-Dessau<br />
              Franckestraße 5, 06110 Halle (Saale)<br />
              Tel.: 0345 2126-0<br />
              Fax: 0345 2126-105<br />
              E-Mail: info@halle.ihk.de
            </p>
            <h2 class="encodeFont">Hauptgeschäftsführer:</h2>
            <p style="font-size: 18px">
              Prof. Dr. Thomas Brockmeier<br />
              Industrie- und Handelskammer Halle-Dessau<br />
              Franckestraße 5, 06110 Halle (Saale)<br />
              Tel.: 0345 2126-0<br />
              Fax: 0345 2126-105<br />
              E-Mail: info@halle.ihk.de
            </p>
            <h2 class="encodeFont">Aufsichtsbehörde</h2>
            <p style="font-size: 18px">
              Zuständige Aufsichtsbehörde ist gemäß § 11 Abs. 1 IHKG in Verbindung mit § 2 Abs. 1 des Gesetzes über die Industrie- und Handelskammern in Sachsen-Anhalt führt das für Wirtschaft zuständige Ministerium: Ministerium für Wirtschaft, Tourismus, Landwirtschaft und Forsten<br />
              Hasselbachstraße 4<br />
              39104 Magdeburg<br />
              Tel.: +49 391 567-01 (Zentrale)<br />
              Fax: +49 391 61 50 72<br />
              E-Mail: poststelle@mw.sachsen-anhalt.de<br />
              Die IHK Halle-Dessau ist eine Körperschaft des öffentlichen Rechts gemäß § 3
              Abs. 1 IHKG.
            </p>
            <h2 class="encodeFont">Umsatzsteuer-Identifikationsnummer</h2>
            <p style="font-size: 18px">DE335734575</p>
            <h2 class="encodeFont">Weitere Informationen</h2>
            <p style="font-size: 18px">
              Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische
              Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die
              Sie unter https://ec.europa.eu/consumers/odr/ finden. Hinweis nach § 36
              Verbraucherstreitbeilegungsgesetz (VSBG): Zur Teilnahme an einem
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle ist die
              IHK Halle-Dessau nicht verpflichtet und nicht bereit.
            </p>
            <h3 class="encodeFont">Copyright</h3>
            <p style="font-size: 18px">
              Alle Rechte vorbehalten: Jeder Text, jedes Website-Design, alle Graphiken,
              jede Auswahl bzw. jedes Layout davon und jede Software sind weltweit
              urheberrechtlich geschützt. Die Seiten dürfen insbesondere nur zum privaten
              Gebrauch vervielfältigt, Änderungen nicht vorgenommen und
              Vervielfältigungsstücke ohne Genehmigung nicht verbreitet werden.
            </p>
            <h3 class="encodeFont">Hinweise</h3>
            <p style="font-size: 18px">
              Wer das Urheberrecht widerrechtlich verletzt, kann vom Verletzten auf
              Beseitigung der Beeinträchtigung, bei Wiederholungsgefahr auf Unterlassung
              und, wenn dem Verletzer Vorsatz oder Fahrlässigkeit zur Last fällt, auch auf
              Schadenersatz in Anspruch genommen werden. An Stelle des Schadenersatzes
              kann der Verletzte die Herausgabe des Gewinns, den der Verletzer durch die
              Verletzung des Rechts erzielt hat, und Rechnungslegung über diesen Gewinn
              verlangen (§ 97 Abs. 1 UrhG). Wer in anderen als den gesetzlich zugelassenen
              Fällen ohne Einwilligung des Berechtigten ein Werk oder eine Bearbeitung
              oder Umgestaltung eines Werkes vervielfältigt, verbreitet oder öffentlich
              wiedergibt, wird mit Freiheitsstrafe bis zu drei Jahren oder mit Geldstrafe
              bestraft (§ 106 Abs. 1 UrhG).
            </p>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </div>

    <footerComponent />
  </div>
</template>

<script>
import headerComponent from "../../components/Main/Header.vue";
import footerComponent from "../../components/Main/Footer.vue";

export default {
  name: "imprint",

  components: {
    headerComponent,
    footerComponent,
  },

  computed: {
    getCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 10;
        case "md":
          return 10;
        case "lg":
          return 8;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
