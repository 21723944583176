<template>
  <div id="eventEditView">
    <v-card
      :style="{ width: cardWidth }"
      style="min-height: 50vh; max-height: 90vh"
      class="scrollable px-8 py-12"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form ref="eventForm">
        <v-container>
          <v-row class="mb-2">
            <v-col class="py-0 px-3">
              <h2 class="encodeFont px-3" v-if="!isMobile">Eventinformationen</h2>
              <h2 class="encodeFont px-3" v-else>
                Event- <br />
                informationen
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pr-3" :cols="isMobile ? 12 : 6">
              <v-container class="pb-0 pr-0" fluid>
                <v-row class="ma-0 pa-0 px-0" v-if="!editorView">
                  <v-col class="pa-0">
                    <v-combobox
                      outlined
                      :label="'Firma' + (editorView ? '' : '*')"
                      v-model="company"
                      :items="companies"
                      return-object
                      item-text="name"
                      :rules="editorView ? [validObject] : [required, validObject]"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row class="ma-0 pa-0 px-0">
                  <v-col class="pa-0">
                    <v-text-field
                      label="Eventname"
                      v-model="title"
                      outlined
                      :rules="[maxLength60]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0 px-0">
                  <v-col class="pa-0">
                    <v-text-field
                      label="Webseite"
                      v-model="weblink"
                      :rules="[maxLength200]"
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0 px-0">
                  <v-col :cols="isMobile ? 12 : 6" class="px-0 py-0">
                    <v-menu
                      class="py-0"
                      ref="startDateMenu"
                      v-model="startDateMenu"
                      transition="scale-transition"
                      :close-on-content-click="false"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="getReadableDate(startDate)"
                          label="Startdatum*"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-inner-icon="mdi-calendar"
                          outlined
                          id="startDateTextField"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        locale="de-de"
                        :first-day-of-week="1"
                        @click:date="startDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    :cols="isMobile ? 12 : 6"
                    class="px-0 py-0"
                    :class="{ 'pl-2': !isMobile }"
                  >
                    <v-menu
                      class="py-0"
                      ref="startTimeMenu"
                      v-model="startTimeMenu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="startTime"
                          label="Von"
                          v-bind="attrs"
                          v-on="on"
                          prepend-inner-icon="mdi-clock-outline"
                          outlined
                          v-model="startTime"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="startTime"
                        :allowed-minutes="allowedStep"
                        @click:minute="startTimeMenu = false"
                        format="24hr"
                        landscape
                        scrollable
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 6" class="px-0 py-0">
                    <v-menu
                      class="py-0"
                      ref="endDateMenu"
                      v-model="endDateMenu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                      :disabled="!startDate"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="getReadableDate(endDate)"
                          label="Enddatum*"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-inner-icon="mdi-calendar"
                          outlined
                          :rules="[required]"
                          :disabled="!startDate"
                          id="endDateTextField"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        locale="de-de"
                        :first-day-of-week="1"
                        @click:date="endDateMenu = false"
                        :allowed-dates="allowedEndDates"
                        :disabled="!startDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    :cols="isMobile ? 12 : 6"
                    class="py-0 px-0"
                    :class="{ 'pl-2': !isMobile }"
                  >
                    <v-menu
                      class="py-0"
                      ref="endTimeMenu"
                      v-model="endTimeMenu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="endTime"
                          label="Bis"
                          v-model="endTime"
                          v-bind="attrs"
                          v-on="on"
                          prepend-inner-icon="mdi-clock-outline"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="endTime"
                        @click:minute="endTimeMenu = false"
                        :allowed-minutes="allowedStep"
                        format="24hr"
                        landscape
                        scrollable
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col class="pb-0 pr-3" :cols="isMobile ? 12 : 6">
              <v-container class="pb-0" fluid :class="{ 'pt-0': isMobile }">
                <v-row class="mb-2 pa-0 pr-3" :class="{ 'px-3': isMobile }">
                  <v-spacer />
                  <v-col cols="12" class="pa-0 pt-3">
                    <v-img
                      class="imageCover"
                      v-if="logo"
                      :src="getImgSrc(logo)"
                      style="background-size: cover !important; height: 224px"
                      :alt="
                        'Eventlogo für ' +
                        (eventData && eventData.title ? eventData.title : 'neues Event')
                      "
                    ></v-img>
                    <v-img
                      v-else
                      class="imageCover"
                      style="background-size: cover !important; height: 226px"
                      src="../../../assets/images/noImg.svg"
                      :alt="
                        'Platzhalterlogo für ' +
                        (eventData && eventData.title ? eventData.title : 'neues Event')
                      "
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row class="pr-3" :class="{ 'px-3': isMobile }" style="margin-top: 28px">
                  <v-col
                    class="pa-0 mb-2"
                    :cols="isMobile ? 12 : 6"
                    :class="{ 'pr-1': !isMobile, 'pr-3': !isMobile }"
                  >
                    <div class="imageUploader" @keyup.enter="showUpload">
                      <image-uploader
                        tabindex="0"
                        class="imageUploadFocus"
                        style="height: 56px"
                        :debug="0"
                        :maxWidth="1024"
                        :maxHeight="1024"
                        :quality="0.6"
                        :autoRotate="false"
                        outputFormat="verbose"
                        :preview="false"
                        accept="image/*"
                        id="logoID"
                        v-model="logo"
                        @input="setImage"
                      >
                        <label
                          id="uploadLabel"
                          for="logoID"
                          slot="upload-label"
                          style="width: inherit !important"
                        >
                          <span
                            style="
                            border-radius: 4px !important
                              width: 100% !important;
                              display: flex;
                              align-items: center;
                              text-align: center;
                              justify-content: center;
                            "
                            block
                            elevation="0"
                            class="primaryOnWhiteButton lightOutline pa-2 focusable"
                          >
                            <v-icon class="mr-3 primary--text">mdi-camera</v-icon>
                            Hochladen</span
                          >
                        </label>
                      </image-uploader>
                    </div>
                  </v-col>
                  <v-col
                    :cols="isMobile ? 12 : 6"
                    class="pa-0 mb-2"
                    :class="{ 'pl-1': !isMobile, 'pl-3': !isMobile, 'mb-7': isMobile }"
                  >
                    <v-btn
                      block
                      elevation="0"
                      class="primaryOnWhiteButton lightOutline px-2 py-3 ma-0"
                      style="height: 56px !important; font-weight: bold"
                      @click="logo = null"
                      ><v-icon class="mr-3">mdi-delete</v-icon>Löschen</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              Vorschau Hauptseite:
            </v-col>
          </v-row>
                <v-row>
                  <v-spacer />
                  <v-col :cols="previewCols" class="pt-0">
                    <EventBannerVue :events="eventPreview" :editView="true" />
                  </v-col>
                   <v-spacer />
                </v-row>

          <v-row class="ma-0 py-0 px-0 mb-2" v-if="editorView">
            <v-col class="py-0 mx-0 pt-4">
              <v-radio-group
                mandatory
                class="radioButtonRow hideInputMessage mt-0"
                v-model="isCompanyEvent"
                :disabled="eventData != undefined"
              >
                <v-radio label="Eigener Standort" :value="1"></v-radio>

                <v-radio label="Firmenstandort" :value="2"></v-radio>

                <v-radio label="Virtuell" :value="3"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0 px-0" v-if="editorView && isCompanyEvent == 2">
            <v-col class="py-0">
              <v-combobox
                :disabled="eventData != undefined"
                return-object
                item-text="name"
                label="Firma"
                v-model="company"
                outlined
                :items="companies"
              >
                <template v-slot:item="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <AddressComponentVue
            v-if="editorView && isCompanyEvent == 1"
            :isRequired="editorView & !(isCompanyEvent == 1)"
            :addressData="address"
            :disabled="eventData != undefined"
            :validateNow="validateNow"
            @formValid="validAddressForm"
          />

          <v-row class="ma-0 pa-0 px-0" v-if="editorView && isCompanyEvent == 3">
            <v-col class="py-0">
              <i>Das Event findet online statt!</i>
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0 px-3 mt-3">
            <v-col class="pa-0">
              <vue-editor
                ref="vue2Editor"
                class="encodeFont"
                :class="{ redOutline: htmlForEditor.length > 2000 }"
                id="editor"
                v-model="htmlForEditor"
                :editorToolbar="customToolbar"
              >
              </vue-editor>
              <div style="display: flex; justify-content: space-between">
                <div>
                  <p
                    class="pl-3"
                    style="font-size: small; color: red"
                    :style="{ display: htmlForEditor.length <= 2000 ? 'none' : 'block' }"
                  >
                    Maximal 2000 Zeichen!
                  </p>
                </div>
                <p class="pr-3" style="font-size: small">
                  {{ htmlForEditor.length }}/2000
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2 mx-0 px-0 py-2">
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 4" class="">
              <v-btn
                block
                large
                elevation="0"
                class="primaryOnWhiteButton lightOutline py-3"
                :class="{ 'pa-1': isMobile }"
                @click="close()"
                id="cancelButton"
                ><v-icon class="mr-4">mdi-cancel</v-icon> Abbrechen
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 8">
              <v-btn
                block
                large
                elevation="0"
                class="primaryButton py-3"
                :class="{ 'pa-1': isMobile }"
                @click="saveData()"
                ><v-icon class="mr-4">mdi-check</v-icon> Speichern
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">
      Fokus zurück zu "Schließen"
    </div>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import ImageUploader from "vue-image-upload-resize";
import AddressComponentVue from "../../Main/Address.vue";
import EventBannerVue from "@/views/Calendar/EventBanner.vue";

const axios = require("axios");

export default {
  name: "eventEdit",
  props: ["eventData", "editorView", "preSelectedCompany"],

  data() {
    return {
      startDate: "",
      endDate: "",

      startTime: undefined,
      endTime: undefined,

      startDateMenu: false,
      endDateMenu: false,

      startTimeMenu: false,
      endTimeMenu: false,

      allowedStep: (m) => m % 15 === 0,

      address: {
        id: undefined,
        City: {
          city: undefined,
          postcode: undefined,
          id: undefined,
        },
        street: undefined,
        additional: undefined,
      },

      addressSave: {},

      title: undefined,
      logo: undefined,
      weblink: undefined,
      company: undefined,
      companies: [],

      isCompanyEvent: 1,

      validateNow: 0,
      addressFormIsValid: false,

      htmlForEditor: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"] // Add link button
      ],

      startDateRule: () => this.startDate || "Pflichtfeld!",
      endDateRule: () => this.endDate || "Pflichtfeld!",
      maxLength60: (value) =>
        (!!value && value.length <= 60) || "Pflichtfeld, maximal 30 Zeichen!",

      maxLength200: (value) => !value || value.length <= 200 || "Max. 200 Zeichen!",
      required: (value) => !!value || "Pflichtfeld!",
      validObject: (value) =>
        !value || typeof value == "object" || "Firma nicht im System!",

      allowedEndDates: (val) => this.startDate && val >= this.startDate,

      loading: false,
    };
  },

  components: {
    VueEditor,
    ImageUploader,
    AddressComponentVue,
    EventBannerVue,
  },

  computed: {
    eventPreview() {
      let event = {
        end_date: this.endDate ? this.endDate : "Enddatum",
        logo: this.logo,
        start_date: this.startDate ? this.startDate : "Startdatum",
        title: this.title ? this.title : "Titel eintragen...",
      };
      return [event];
    },
    previewCols(){
        switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 10;
        case "sm":
          return 8;
        case "md":
          return 6;
        case "lg":
          return 6;
        case "xl":
          return 5;
        default:
          return 4;
      }
    },
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "85vw";
        case "sm":
          return "75vw";
        case "md":
          return "65vw";
        case "lg":
          return "55vw";
        case "xl":
          return "50vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    resetFocus(e) {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("closeButton").focus();
    },
    showUpload(e) {
      e.preventDefault();
      e.stopPropagation();

      document.getElementById("uploadLabel").click();
    },
    focusCancelButton() {
      document.getElementById("cancelButton").focus();
    },
    validAddressForm(data) {
      this.addressFormIsValid = data;
    },
    setImage(output) {
      this.logo = output;
    },
    getImgSrc(img) {
      if (img.dataUrl) return img.dataUrl;
      else return img;
    },
    close(resp) {
      this.$emit("close", resp);
    },
    getReadableDate(date) {
      let temp = date.split("-");

      if (temp.length < 3) {
        return "";
      }

      return temp[2] + "." + temp[1] + "." + temp[0];
    },
    base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    saveData() {
      if (this.$refs.eventForm.validate() && this.htmlForEditor.length <= 2000) {
        this.loading = true;
        //collect basic event data
        let formData = new FormData();
        formData.append("title", this.title);
        formData.append("description", this.htmlForEditor.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));

        formData.append("start_date", this.startDate + " " + "00:00:00");
        formData.append("end_date", this.endDate + " " + "00:00:00");
        if (this.startTime) formData.append("start_time", this.startTime);
        if (this.endTime) formData.append("end_time", this.endTime);
        formData.append("weblink", this.weblink ? this.weblink : "");

        //append logo if one was uploaded
        if (this.logo && this.logo.dataUrl) {
          var dataURL = this.logo.dataUrl;
          dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
          var blob = this.base64toBlob(dataURL, "image/jpeg");

          formData.append("logo", blob, "logo.jpg");
        }

        //this.company exists if event is being edited. Changing Companies
        //is not allowed so use that company
        if (this.company) {
          formData.append("Company", this.company.id);
        }

        //if eventData has been supplied patch it
        //changing from a company event to a address based event is also not allowed
        //so address is not checked or supplied here
        if (this.eventData && this.eventData.id) {
          formData.append("id", this.eventData.id);

          axios
            .patch(axios.defaults.baseURL + "events/patch/" + this.eventData.id, formData)
            .then((resp) => {
              this.loading = false;
              this.close(resp.data);
            })
            .catch((e) => {
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              this.loading = false;
              console.log(e);
            });
        } else {
          //companyData has not been supplied, post new instead
          //check if either a company was selected (editor only)
          //or the eventEdit was given a preSelectedCompany (company only)
          //if a companyAccount tries using a company that it isnt bound to the db will
          //refuse the request
          if (
            this.company ||
            (this.preSelectedCompany && this.$store.state.permission == 3)
          ) {
            let company;

            if (this.preSelectedCompany && this.$store.state.permission == 3) {
              company = this.preSelectedCompany;
            } else {
              company = this.company;
            }

            formData.append("Company", company.id);

            //fetch company's address and post event at that location
            //connect event and company
            axios
              .get(axios.defaults.baseURL + "address/by/company/" + company.id)
              .then((r) => {
                formData.append("Address", r.data.Address.id);
                axios
                  .post(axios.defaults.baseURL + "events/post/", formData)
                  .then((resp) => {
                    this.loading = false;
                    this.close(resp.data);
                  })
                  .catch((e) => {
                    window.dispatchEvent(new CustomEvent("showErrorDisplay"));
                    this.loading = false;
                    console.log(e);
                  });
              })
              .catch((e) => {
                window.dispatchEvent(new CustomEvent("showErrorDisplay"));
                this.loading = false;
                console.log(e);
              });
          } else if (this.isCompanyEvent == 3) {
            formData.append("digital", true);

            axios
              .post(axios.defaults.baseURL + "events/post/", formData)
              .then((resp) => {
                this.loading = false;
                this.close(resp.data);
              })
              .catch((e) => {
                window.dispatchEvent(new CustomEvent("showErrorDisplay"));
                this.loading = false;
                console.log(e);
              });
          } else {
            //no company has been specified (editor only)

            //post new address for event
            axios
              .post(axios.defaults.baseURL + "address/post/", {
                street: this.address.street,
                City: this.address.City.city
                  ? this.address.City.city.id
                  : this.address.City.id,
                additional: this.address.additional,
              })
              .then((r) => {
                formData.append("Address", r.data.id);

                //post event and connect event and address
                axios
                  .post(axios.defaults.baseURL + "events/post/", formData)
                  .then((resp) => {
                    this.loading = false;
                    this.close(resp.data);
                  })
                  .catch((e) => {
                    window.dispatchEvent(new CustomEvent("showErrorDisplay"));
                    this.loading = false;
                    console.log(e);
                  });
              })
              .catch((e) => {
                window.dispatchEvent(new CustomEvent("showErrorDisplay"));
                this.loading = false;
                console.log(e);
              });
          }
        }
      }
    },
    closeStartDateMenu() {
      setTimeout(() => {
        this.startDateMenu = false;
      }, 100);
    },
    closeEndDateMenu() {
      setTimeout(() => {
        this.endDateMenu = false;
      }, 100);
    },
    fetchCompanies() {
      let url = "companies/";
      if (this.editorView) {
        url += "simple";
      } else {
        url += "simple/own";
      }

      axios
        .get(axios.defaults.baseURL + url)
        .then((resp) => {
          this.companies = resp.data;

          this.setValues();
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    setValues() {
      if (this.eventData) {
        this.htmlForEditor = this.eventData.description;

        this.title = this.eventData.title;

        this.logo = this.eventData.logo;

        this.weblink = this.eventData.weblink;

        let startDateSplitter = this.eventData.start_date.split("T");
        this.startDate = startDateSplitter[0];

        if (this.eventData.start_time) {
          startDateSplitter = this.eventData.start_time.split(":");
          this.startTime = startDateSplitter[0] + ":" + startDateSplitter[1];
        }

        let endDateSplitter = this.eventData.end_date.split("T");
        this.endDate = endDateSplitter[0];

        if (this.eventData.end_time) {
          endDateSplitter = this.eventData.end_time.split(":");
          this.endTime = endDateSplitter[0] + ":" + endDateSplitter[1];
        }

        if (this.eventData.digital) {
          this.isCompanyEvent = 3;
        } else if (this.eventData.Company) {
          this.isCompanyEvent = 2;

          this.company = this.eventData.Company;
        } else if (this.eventData.Address) {
          this.isCompanyEvent = 1;
          this.addressSave.street = this.eventData.Address.street;
          this.addressSave.cityID = this.eventData.Address.City.id;

          this.address = this.eventData.Address;
        }
      }
    },
  },

  mounted() {
    this.fetchCompanies();

    document.getElementById("startDateTextField").addEventListener(
      "focus",
      function () {
        setTimeout(() => {
          this.startDateMenu = true;
        }, 100);
      }.bind(this)
    );

    document.getElementById("endDateTextField").addEventListener(
      "focus",
      function () {
        setTimeout(() => {
          this.endDateMenu = true;
        }, 100);
      }.bind(this)
    );

    document.getElementById("closeButton").focus();

    // delete this.$refs.vue2Editor.quill.keyboard.bindings[9];

    //add esc listener to WYSIWYG editor
    this.$refs.vue2Editor.quill.keyboard.bindings[27] = [
      {
        key: 27,
        shiftKey: false,
        handler: () => {
          document.getElementById("cancelButton").focus();
        },
      },
    ];
  },
};
</script>
