<template>
  <div>
    <div style="min-height: 100vh">
      <headerComponent />
      <v-container fill-height style="margin-top: -64px !important; min-height: inherit">
        <v-row justify="center">
          <v-spacer />
          <v-col :cols="colWidth">
            <v-card flat class="pa-6" v-if="!messageRecieved">
              <v-container fluid class="ma-auto">
                <v-form ref="contactForm">
                  <v-row justify="center" no-gutters class="pb-4">
                    <v-col :cols="12">
                      <h1>Kontaktformular</h1>
                    </v-col>
                  </v-row>
                  <v-row justify="center" no-gutters>
                    <v-col :cols="12">
                      <v-select
                        :items="receivers"
                        :rules="[required]"
                        v-model="receiver"
                        outlined
                        label="Kammer wählen"
                      ></v-select>
                      <v-text-field
                        label="Betreff"
                        v-model="subject"
                        :rules="[max50, required]"
                        outlined
                        background-color="white"
                      ></v-text-field>
                      <v-textarea
                        :rules="[max5000, required]"
                        placeholder="Beschreibung (max. 5000 Zeichen)"
                        label="Beschreibung"
                        outlined
                        counter=""
                        v-model="text"
                      >
                      </v-textarea>
                      <v-text-field
                        label="Ihre E-Mail"
                        v-model="email"
                        :rules="[emailRule, required]"
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="center" no-gutters>
                    <v-col :cols="12">
                      <v-btn
                        @click="submit"
                        block
                        depressed
                        large
                        color="primary"
                        elevation="2"
                        >Absenden</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card>
            <v-card flat class="pa-6" v-else>
              <v-container fluid class="ma-auto">
                <h1>Wir haben Ihre Anfrage erhalten!</h1>
                <br /><br />
                <p>
                  Bitte geben Sie uns etwas Zeit, um diese zu beantworten. Eine Mitarbeiter*in wird sich bald bei Ihnen melden.
                </p>
                <p>
                 Vielen Dank.
                </p>
                <br /><br />
                <v-btn
                  @click="backToMain()"
                  block
                  depressed
                  large
                  color="primary"
                  elevation="2"
                  >Zur Startseite</v-btn
                >
              </v-container>
            </v-card>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>

    <footerComponent />
  </div>
</template>

<script>
import axios from "axios";

import headerComponent from "../../components/Main/Header.vue";
import footerComponent from "../../components/Main/Footer.vue";

export default {
  name: "contact",

  data() {
    return {
      messageRecieved: false,
      loading: false,

      subject: "",
      text: "",
      email: "",

      receivers: [
        "HWK Magdeburg",
        "IHK Magdeburg",
        "HWK Halle (Saale)",
        "IHK Halle-Dessau",
      ],
      receiver: undefined,

      max5000: (value) => !value || value.length <= 5000 || "Max. 5000 Zeichen!",
      max50: (value) => !value || value.length <= 50 || "Max. 50 Zeichen!",
      required: (value) => !!value || "Pflichtfeld!",
      emailRule: (v) => /.+@.+\..+/.test(v) || "Keine E-mail",
    };
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    colWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 8;
        case "lg":
          return 6;
        case "xl":
          return 5;
        default:
          return 4;
      }
    },
  },

  components: {
    headerComponent,
    footerComponent,
  },

  methods: {
    backToMain() {
      this.$router.push("/");
    },
    submit() {

      if (this.$refs.contactForm.validate()) {
        this.loading = true;
        axios
          .post(axios.defaults.baseURL + "contact/", {
            mail: this.email,
            subject: this.subject,
            text: this.text,
            receiver: this.receivers.indexOf(this.receiver),
          })
          .then(() => {
            this.messageRecieved = true;
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);

            window.dispatchEvent(new CustomEvent("showErrorDisplay"));

            this.loading = false;
          });
      }
    },
  },
};
</script>
