<template>
  <v-card
    :id="result.id + '_card'"
    @click="showDetailView(index)"
    @focus="showDetailView(index)"
    @keyup.enter="switchFocus()"
    flat
    class="smallFont focusable"
  >
    <v-container fluid class="pa-2" v-if="result">
      <v-row class="pa-0 ma-0 mb-1">
        <v-col cols="12" class="pa-0 ma-0">
          <h2 class="ma-0 primaryText encodeFont" v-if="result.job_name">
            {{ result.job_name }}
          </h2>
          <h2 class="ma-0 primaryText encodeFont" v-else-if="result.Job_name">
            {{ result.Job_name.name }}
          </h2>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0">
          <p style="font-weight: bold; text-align: left" class="mb-0">
            {{ result.title }}
          </p>
        </v-col>
        <v-col
          cols="auto"
          style="position: absolute; right: 0px; top: 0px"
          class="pr-1 pt-2"
        >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-chip class="mx-1 mb-2" v-for="(company, index) in result.Companies" :key="index">{{company.name}}</v-chip>
        </v-col>
      </v-row>
      <v-row class="pb-4 px-4">
        <v-col class="pa-0 ma-0" style="display: flex; justify-content: space-between">
          <v-chip
            :style="{
              'background-color':
                result.job_type == 'Praktikum'
                  ? 'var(--v-primary-base) !important'
                  : 'var(--v-accent-base) !important',
              color:
                result.job_type == 'Praktikum' ? 'white !important' : 'black !important',
            }"
          >
            {{ result.job_type }}
          </v-chip>
          <v-chip> Beginn: {{ result.job_start | moment("DD.MM.YY") }} </v-chip>
          <v-chip v-if="result.job_end">
            Bis: {{ result.job_end | moment("DD.MM.YY") }}
          </v-chip>
          <v-chip v-else> Bis: -- </v-chip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
const axios = require("axios");
export default {
  name: "resultDetail",

  props: ["result", "index", "showPublished"],

  data() {
    return {
      url: axios.defaults.url,
    };
  },

  watch: {},

  methods: {
    getLogoUrl(url) {
      if (!url.startsWith("https://")) {
        return this.url + url;
      }

      return url;
    },
    switchFocus() {
      this.$emit("switchFocus");
    },
    showDetailView(index) {
      this.$emit("click", index);
    },
  },
};
</script>
