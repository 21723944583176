<template>
  <div id="eventBanner">
    <vueper-slides
      :autoplay="!editView && false"
      :dragging-distance="100"
      class="no-shadow"
      :visible-slides="sliderNum"
      :slide-multiple="!editView"
      :gap="1"
      :slide-ratio="ratio"
      infinite
    >
      <vueper-slide
        v-for="(slide, i) in events"
        :key="i"
        :title="slide.title"
        style="background-color: white"
      >
        >
        <template #content>
          <v-card
            @click="gotoEvent(slide)"
            flat
            style="
              height: 100%;
              width: 100%;
              background-color: #f2f2f2;
              display: flex;
              flex-direction: column;
            "
            class="pa-2"
          >
            <div>
              <b
                style="
                  height: 48px;
                  width: 100%;
                  overflow: hidden;
                  display: block;
                  position: relative;
                "
                >{{ getTitle(slide.title) }}</b
              >
            </div>
            <div style="width: 100%; display: flex; flex-grow: 1;     max-height: calc(-48px + 100%);">
              <div
                class="pa-1"
                style="
                  width: 40%;
                  display: flex;
                  justify-content: flex-end;
                  flex-direction: column;
                  text-align: center;
                "
                :style="{'line-height': largeSize ? 1.5 : 1}"
              >
                {{ getReadableDate(slide.start_date) }} -
                {{ getReadableDate(slide.end_date) }}
                <div
                  class="pa-1 mt-2"
                  style="
                    background-color: var(--v-primary-base);
                    color: white;
                    cursor: pointer;
                    text-align: center;
                    border-radius: 4px;
                  "
                  v-if="largeSize"
                >
                  <small><b>mehr erfahren</b></small>
                </div>
              </div>
              <div style="height: 100%; width: 60%" class="pa-1">
                <v-card style="height: 100%; width: 100%">
                  <img
                    draggable="false"
                    :src="(slide.logo && slide.logo.dataUrl) ? slide.logo.dataUrl : slide.logo"
                    style="
                      object-fit: contain;
                      border-radius: 4px !important;
                      height: 100%;
                      width: 100%;
                    "
                  />
                </v-card>
              </div>
            </div>
          </v-card>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "eventPage",

  props: ["events", "editView"],

  data() {
    return {
      model: 0,
    };
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "12";
        case "md":
          return "12";
        case "lg":
          return "8";
        case "xl":
          return "8";
        default:
          return "6";
      }
    },
    largeSize() {
    if(this.$vuetify.breakpoint.height < 950){
      return false
    }
    
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        default:
          return true;
      }
    },
    imageCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "3";
        case "sm":
          return "3";
        case "md":
          return "2";
        case "lg":
          return "2";
        case "xl":
          return "2";
        default:
          return "2";
      }
    },
    leftEventCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "12";
        case "md":
          return "12";
        default:
          return "2";
      }
    },
    rightEventCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "12";
        case "md":
          return "12";
        default:
          return "10";
      }
    },
    sliderNum() {
      if (this.editView) {
        return 1;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 3;
        case "sm":
          return 3;
        case "md":
          return 3;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 4;
      }
    },
    ratio() {
      if (this.editView) {
        return 0.2;
      }

      if (this.$vuetify.breakpoint.height > 1000) {
        return 0.2;
      }
      if (this.$vuetify.breakpoint.height > 900) {
        return 0.15;
      }
      if (this.$vuetify.breakpoint.height > 800) {
        return 0.175;
      }
      if (this.$vuetify.breakpoint.height > 700) {
        return 0.15;
      }

      return 1 / 5;
    },
  },

  components: {
    VueperSlides,
    VueperSlide,
  },

  methods: {
    gotoEvent(slide) {
      window.dispatchEvent(new CustomEvent("gotoEvent", { detail: slide.id }));
    },
    getTitle(title) {
      return title.substring(0, 55) + (title.length > 55 ? "..." : "");
    },
    getReadableDate(date) {
      try {
        let temp = date.split("T")[0].split("-");

        if (temp.length < 3) {
          return date;
        }

        return temp[2] + "." + temp[1] + "." + temp[0];
      } catch (e) {
        console.log(e);
        return date;
      }
    },
    getReadableTime(value) {
      if (!value) return "--";
      let temp = value.split(":");
      return temp[0] + ":" + temp[1];
    },
    gotoExternalPage(url) {
      if (!url.startsWith("https://")) {
        if (url.startsWith("http://")) {
          url = url.replace("http://", "https://");
        } else {
          url = "https://" + url;
        }
      }

      window.open(url, "_blank").focus();
    },
  },
};
</script>
