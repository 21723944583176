import Vue from "vue";
import VueRouter from "vue-router";

import mainPage from "../views/MainPage.vue";

import suche from "../views/Search/Results.vue";

// import company from '../views/Companies/Company.vue'
import companyDetail from "../views/Companies/Company.vue";
import contactDetail from "../views/Companies/Contacts.vue";

import jd from "../views/Companies/JobDescriptions.vue";
import companyEvents from "../views/Companies/Events.vue";
import mainCompany from "../views/Companies/Main.vue";

import companySetup from "../views/Companies/Setup.vue";

import mainEditor from "../views/Editors/Main.vue";

import store from "../store";

import impressum from "../views/Imprint/Imprint.vue";
import datenschutz from "../views/Imprint/Privacy.vue";
import kontakt from "../views/Imprint/Contact.vue";

import login from "../views/Login.vue";

import registerMain from "../views/Register/RegisterMain.vue";
import register from "../views/Register/Register.vue";

import jdEditorList from "../views/Editors/JobDescriptions.vue";
import jobNames from "../views/Editors/JobNames.vue";
import mainCompanyView from "../views/Editors/CompanyView.vue";

import companyListComponent from "../views/Editors/CompanyViews/List.vue";
import companyRequestComponent from "../views/Editors/CompanyViews/Request.vue";
import companyBlacklistComponent from "../views/Editors/CompanyViews/Blacklist.vue";

import eventsEditorList from "../views/Editors/Events.vue";
import adminPanel from "../views/Editors/AdminPanel.vue";
import faq from "../views/Editors/FAQ.vue";
import stats from "../views/Statistics/Statistics.vue";
import images from "../views/Editors/Images.vue";

import editorDashboard from "../views/Editors/Dashboard.vue";

import halloPraktikumRoot from "../views/hallo-praktikum/Root.vue"
import halloPraktikumMainPage from "../views/hallo-praktikum/MainPage.vue"
import halloPraktikumSchueler from "../views/hallo-praktikum/Schueler.vue"
import halloPraktikumLehrer from "../views/hallo-praktikum/Lehrer.vue"
import halloPraktikumAzubi from "../views/hallo-praktikum/Azubi.vue"
import halloPraktikumUnternehmen from "../views/hallo-praktikum/Unternehmen.vue"

import { permissions } from "../util/permissions.js";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/", //main langing page for non-logged-in users
    component: mainPage,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
    },
  },
  {
    path: "/unternehmen", //company dashboard
    name: "unternehmen",
    component: mainCompany,
    beforeEnter(to, from, next) {
      if (
        store.state.accessToken &&
        store.state.permission == permissions.UNTERNEHMEN
      ) {
        window.scrollTo(0, 0);
        next();
      } else {
        window.scrollTo(0, 0);
        next("/");
      }
    },
    props: {
      displayInNavigation: true,
    },
    children: [
      {
        path: "setup",
        component: companySetup,
        name: "unternehmen_setup",
        props: {
          displayInNavigation: false,
          icon: "view-dashboard",
          displayName: "Setup",
          path: "setup",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "unternehmensprofil",
        component: companyDetail,
        name: "unternehmen_unternehmensprofil",
        props: {
          displayInNavigation: true,
          icon: "home",
          displayName: "Filialverwaltung",
          path: "unternehmensprofil",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "kontaktpersonen",
        component: contactDetail,
        name: "unternehmen_kontaktpersonen",
        props: {
          displayInNavigation: true,
          icon: "account-multiple",
          displayName: "Kontaktpersonen",
          path: "kontaktpersonen",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "stellenangebote",
        component: jd,
        name: "unternehmen_stellenangebote",
        props: {
          displayInNavigation: true,
          icon: "file-document-multiple",
          displayName: "Stellenangebote",
          path: "stellenangebote",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "events",
        component: companyEvents,
        name: "unternehmen_events",
        props: {
          displayInNavigation: true,
          icon: "calendar",
          displayName: "Events",
          path: "events",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
    ],
  },
  {
    path: "/redakteure", //editor dashboard
    name: "redakteure",
    component: mainEditor,
    beforeEnter(to, from, next) {
      if (
        store.state.accessToken &&
        store.state.permission <= permissions.REDAKTEUR
      ) {
        window.scrollTo(0, 0);
        next();
      } else {
        window.scrollTo(0, 0);
        next("/");
      }
    },
    children: [
      {
        path: "dashboard",
        component: editorDashboard,
        name: "dashboard",
        props: {
          displayInNavigation: true,
          icon: "view-dashboard",
          displayName: "Dashboard",
          path: "dashboard",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "unternehmensprofil",
        name: "unternehmensprofil",
        component: mainCompanyView,
        props: {
          displayInNavigation: true,
          icon: "home",
          displayName: "Unternehmen",
          path: "unternehmensprofil",
          rerouteToChild: true,
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
        children: [
          {
            path: "authentifizierung",
            name: "unternehmensprofil_authentifizierung",
            component: companyRequestComponent,
            props: {
              displayInNavigation: true,
              icon: "card-account-details-outline",
              displayName: "Authentifizierung",
              path: "authentifizierung",
            },
            beforeEnter(to, from, next) {
              window.scrollTo(0, 0);
              next();
            },
          },
          {
            path: "liste/:company?",
            name: "unternehmensprofil_liste",
            component: companyListComponent,
            props: {
              displayInNavigation: true,
              icon: "city-variant-outline",
              displayName: "Liste",
              path: "liste",
            },
            beforeEnter(to, from, next) {
              window.scrollTo(0, 0);
              next();
            },
          },
          {
            path: "gesperrt",
            name: "unternehmensprofil_gesperrt",
            component: companyBlacklistComponent,
            props: {
              displayInNavigation: true,
              icon: "cancel",
              displayName: "Sperrliste",
              path: "gesperrt",
            },
            beforeEnter(to, from, next) {
              window.scrollTo(0, 0);
              next();
            },
          },
        ],
      },
      {
        path: "stellenangebote/:firma?",
        component: jdEditorList,
        name: "stellenangebote",
        props: {
          displayInNavigation: true,
          icon: "file-document-multiple",
          displayName: "Stellenangebote",
          path: "stellenangebote",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "events",
        component: eventsEditorList,
        name: "events",
        props: {
          displayInNavigation: true,
          icon: "calendar",
          displayName: "Events",
          path: "events",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "berufe",
        component: jobNames,
        name: "berufe",
        props: {
          displayInNavigation: true,
          icon: "briefcase",
          displayName: "Berufe",
          path: "berufe",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "admin",
        component: adminPanel,
        name: "admin",
        props: {
          displayInNavigation: true,
          icon: "face-agent",
          displayName: "Redakteure",
          path: "admin",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "faq",
        component: faq,
        name: "faq",
        props: {
          displayInNavigation: true,
          icon: "chat",
          displayName: "FAQ",
          path: "faq",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "statistiken",
        component: stats,
        name: "statistiken",
        props: {
          displayInNavigation: true,
          icon: "poll",
          displayName: "Statistiken",
          path: "statistiken",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
      {
        path: "bilder",
        component: images,
        name: "bilder",
        props: {
          displayInNavigation: true,
          icon: "image-multiple",
          displayName: "Bildverwaltung",
          path: "bilder",
        },
        beforeEnter(to, from, next) {
          window.scrollTo(0, 0);
          next();
        },
      },
    ],
  },
  {
    path: "/registrieren", //register new companies
    component: registerMain,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
      displayName: "Registrierung",
    },
    children: [
      {
        path: "anfrage",
        component: register,
      },
    ],
  },
  {
    path: "/login", //login for both editors and companies
    component: login,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
      displayName: "Login",
    },
  },
  {
    path: "/impressum",
    component: impressum,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
      displayName: "Impressum",
    },
  },
  {
    path: "/datenschutz",
    component: datenschutz,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
      displayName: "Datenschutz",
    },
  },
  {
    path: "/kontakt",
    component: kontakt,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
      displayName: "Kontakt",
    },
  },
  {
    path: "/information",
    component: halloPraktikumRoot,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
      displayName: "Information",
    },
    children: [
      {
        path: "schueler",
        component: halloPraktikumSchueler,
      },
      {
        path: "azubi",
        component: halloPraktikumAzubi,
      },
      {
        path: "lehrer",
        component: halloPraktikumLehrer,
      },
      {
        path: "unternehmen",
        component: halloPraktikumUnternehmen,
      },
      {
        path: "",
        component: halloPraktikumMainPage,
      },
    ]
  },
  {
    path: "/suche/:searchQ?", //search view
    component: suche,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
      displayName: "Suche",
    },
  },
  {
    path: "*",
    component: mainPage,
    beforeEnter(to, from, next) {
      window.scrollTo(0, 0);
      next();
    },
    props: {
      displayInNavigation: false,
      displayName: "Hauptseite",
    },
  },
];

export const router = new VueRouter({
  mode: "hash",
  routes: routes,
});
