<template>
  <div>
    <v-card
      :style="{ width: cardWidth }"
      style="max-height: 90vh"
      class="scrollable px-8 py-12"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form ref="faqForm">
        <v-container>
          <v-row class="mb-2">
            <v-col class="py-0">
              <h2 class="encodeFont">FAQ Eintrag</h2>
            </v-col>
          </v-row>

          <v-container fluid class="pa-0">
            <v-row>
              <v-col :cols="isMobile ? 12 : 6" class="pa-0">
                <v-container>
                  <v-row>
                    <v-col class="pb-0 pl-3" cols="12">
                      <v-text-field
                        outlined
                        label="Titel*"
                        v-model="title"
                        :rules="[required, max256]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pb-0 pl-3" cols="12">
                      <v-combobox
                        outlined
                        label="Kategorien*"
                        :items="categoryChoices"
                        multiple
                        v-model="categories"
                        item-text="key"
                        item-value="key"
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0 px-1">
                      <v-file-input
                        v-model="files"
                        label="Datei"
                        multiple
                        prepend-icon="mdi-paperclip"
                        class="v-input--hide-details hideInputMessage"
                        outlined
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row v-if="currentFiles && currentFiles.length > 0" class="mb-2">
                    <v-col class="py-0">
                      <span>
                        <v-chip class="mr-2" v-for="file in currentFiles" :key="file.id"
                          >{{ file.name }}
                          <span @click="removeFiles(file)"
                            ><v-icon class="ml-2">mdi-close</v-icon></span
                          ></v-chip
                        >
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6">
                <v-container fluid class="pa-0">
                  <v-row class="py-0 ma-0">
                    <v-col
                      class="pa-0 ma-0"
                      tabindex="0"
                      style="display: flex; flex-direction: column"
                    >
                      <vue-editor
                        tabindex="0"
                        ref="vue2Editor"
                        class="encodeFont focusable"
                        id="editor"
                        v-model="description"
                        :editorToolbar="customToolbar"
                      >
                      </vue-editor>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>

          <v-row class="mt-4">
            <v-col class="pt-8" cols="6">
              <v-switch
                v-model="public"
                label="Soll dieser Eintrag öffentlich im Infobereich zur Verfügung gestellt werden?"
              ></v-switch>
            </v-col>

            <v-col class="pt-8" cols="6" v-if="public">
              <v-text-field
                label="Reihenfolge des Eintrags"
                v-model="order"
                :rules="[]"
                outlined
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="public" class="mb-4">
            <v-col class="pt-0" cols="3">
              <v-switch v-model="isSchueler" label="Schüler"></v-switch>
            </v-col>
            <v-col class="pt-0" cols="3">
              <v-switch v-model="isAzubi" label="Azubis"></v-switch>
            </v-col>
            <v-col class="pt-0" cols="3">
              <v-switch v-model="isUnternehmen" label="Unternehmen"></v-switch>
            </v-col>
            <v-col class="pt-0" cols="3">
              <v-switch v-model="isLehrer" label="Lehrer"></v-switch>
            </v-col>
          </v-row>

          <v-row class="mt-2 py-2">
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 4">
              <v-btn
                block
                large
                elevation="0"
                class="primaryOnWhiteButton lightOutline py-3"
                :class="{ 'pa-1': isMobile }"
                @click="close()"
                ><v-icon class="mr-4">mdi-cancel</v-icon> Abbrechen
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 8">
              <v-btn
                block
                large
                elevation="0"
                class="primaryButton py-3"
                :class="{ 'pa-1': isMobile }"
                @click="saveData()"
                ><v-icon class="mr-4">mdi-check</v-icon> Speichern
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">
      Fokus zurück zu "Schließen"
    </div>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");
import { VueEditor } from "vue2-editor";

export default {
  name: "faqEdit",
  props: ["faqData"],

  data() {
    return {
      title: "",
      description: "",
      categories: [],
      files: [],
      currentFiles: [],
      public: false,
      order: 1,

      isLehrer: false,
      isSchueler: false,
      isAzubi: false,
      isUnternehmen: false,

      required: (value) => !!value || "Pflichtfeld!",
      max256: (value) => (!!value && value.length <= 256) || "Max. 256 Zeichen!",
      descriptionValidated: false,

      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"] // Add link button
      ],

      loading: false,
      categoryChoices: [],

      schuelerCategory: undefined,
      lehrerCategory: undefined,
      azubiCategory: undefined,
      unternehmenCategory: undefined,
    };
  },

  components: {
    VueEditor,
  },

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "98vw";
        case "sm":
          return "95vw";
        case "md":
          return "90vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    removeFiles(file) {
      this.currentFiles.splice(this.currentFiles.indexOf(file), 1);
    },
    setValues() {
      if (this.faqData) {
        this.title = this.faqData.title;
        this.description = this.faqData.description;
        console.log(this.faqData)
        this.currentFiles = [...this.faqData.files];

        this.public = this.faqData.public;
        this.order = this.faqData.order;

        this.categories = [];
        for (let i = 0; i < this.faqData.Categories.length; i++) {
          if (!this.faqData.Categories[i].key.startsWith("öffentlich-")) {
            this.categories.push(this.faqData.Categories[i]);
          } else if (
            this.faqData.Categories[i].key.toLowerCase() == "öffentlich-schüler"
          ) {
            this.isSchueler = true;
          } else if (this.faqData.Categories[i].key.toLowerCase() == "öffentlich-azubis") {
            this.isAzubi = true;
          } else if (
            this.faqData.Categories[i].key.toLowerCase() == "öffentlich-unternehmen"
          ) {
            this.isUnternehmen = true;
          } else if (
            this.faqData.Categories[i].key.toLowerCase() == "öffentlich-lehrer"
          ) {
            this.isLehrer = true;
          }
        }
      } else {
        this.title = "";
        this.description = "";
        this.categories = [];
        this.files = [];
        this.currentFiles = [];

        this.public = false;
        this.order = 1;

        this.isLehrer = false;
        this.isSchueler = false;
        this.isAzubi = false;
        this.isUnternehmen = false;
      }
    },
    resetFocus(e) {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("closeButton").focus();
    },
    close(resp) {
      this.$emit("close", resp);
    },
    saveData() {
      if (this.description.length == 0) {
        this.descriptionValidated = true;
        this.$refs.faqForm.validate();

        return;
      }

      if (this.$refs.faqForm.validate()) {
        this.loading = true;

        let formData = new FormData();

        formData.append("title", this.title);
        formData.append("description", this.description);
        this.files.forEach((file, index) => {
          formData.append("file_" + index, file);
        });

        for (let i = 0; i < this.categories.length; i++) {
          formData.append("Categories", this.categories[i].id);
        }

        if (this.isSchueler) {
          formData.append("Categories", this.schuelerCategory.id);
        }
        if (this.isLehrer) {
          formData.append("Categories", this.lehrerCategory.id);
        }
        if (this.isAzubi) {
          formData.append("Categories", this.azubiCategory.id);
        }
        if (this.isUnternehmen) {
          formData.append("Categories", this.unternehmenCategory.id);
        }

        if(this.public){
          formData.append("public", true)
        }
        else{
          formData.append("public", false)
        }

        if(this.order){
          formData.append("order", this.order)
        }
        
        
        let temp = [];
        for (let i = 0; i < this.currentFiles.length; i++) {
          temp.push(this.currentFiles[i].id);
        }

        formData.append("current_files", JSON.stringify(temp));

        if (this.faqData) {
          formData.id = this.faqData.id;

          axios
            .patch(axios.defaults.baseURL + "faq/patch/" + this.faqData.id, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              this.$emit("close", resp.data);
              this.loading = false;
            })
            .catch((e) => {
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              this.loading = false;
              console.log(e);
            });
        } else {
          axios
            .post(axios.defaults.baseURL + "faq/post/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              this.$emit("close", resp.data);
              this.loading = false;
            })
            .catch((e) => {
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              console.log(e);
              this.loading = false;
            });
        }
      }
    },
    fetchCategories() {
      axios
        .get(axios.defaults.baseURL + "faq/categories/")
        .then((resp) => {
          this.categoryChoices = [];

          for (let i = 0; i < resp.data.length; i++) {
            if (!resp.data[i].key.startsWith("öffentlich-")) {
              this.categoryChoices.push(resp.data[i]);
            } else if (resp.data[i].key.toLowerCase() == "öffentlich-schüler") {
              this.schuelerCategory = resp.data[i];
            } else if (resp.data[i].key.toLowerCase() == "öffentlich-azubis") {
              this.azubiCategory = resp.data[i];
            } else if (resp.data[i].key.toLowerCase() == "öffentlich-unternehmen") {
              this.unternehmenCategory = resp.data[i];
            } else if (resp.data[i].key.toLowerCase() == "öffentlich-lehrer") {
              this.lehrerCategory = resp.data[i];
            }
          }

          this.setValues();
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
  },

  mounted() {
    document.getElementById("closeButton").focus();

    this.fetchCategories();
  },
};
</script>
