<template>
  <v-card
    :id="result.id + '_card'"
    @click="showDetailView(index)"
    @focus="showDetailView(index)"
    @keyup.enter="switchFocus()"
    flat
    class="smallFont focusable"
  >
    <v-container fluid class="pa-2" v-if="result">
      <v-row class="pa-0 ma-0 mb-1">
        <v-col cols="11" class="pa-0 ma-0">
          <p class="pa-0 ma-0">
            <span v-if="result.company_name">{{ result.company_name }}</span>
            <span v-else>{{ result.Company.name }}</span>
          </p>
          <h2 class="ma-0 primaryText encodeFont" v-if="result.job_name">
            {{ result.job_name }}
          </h2>
          <h2 class="ma-0 primaryText encodeFont" v-else-if="result.Job_name">
            {{ result.Job_name.name }}
          </h2>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col
          cols="4"
          class="pa-0 ma-0"
          v-if="result.Company && result.Company.logo"
        >
          <v-img
            class="imageContain"
            style="max-height: 80px; min-height: 100%"
            :src="url + result.Company.logo"
          ></v-img>
        </v-col>

        <v-col cols="4" class="pa-0 ma-0" v-else-if="result.company_logo">
          <v-img
            class="imageContain"
            style="max-height: 80px; min-height: 100%"
            :src="getLogoUrl(result.company_logo)"
          ></v-img>
        </v-col>
        <v-col
          :cols="
            (result.Company && result.Company.logo) ||
              (result.company_logo ? 7 : 11)
          "
          class="py-0 pr-2"
          :class="{
            'px-4':
              (result.Company && result.Company.logo) || result.company_logo,
            'pl-0': !(
              (result.Company && result.Company.logo) ||
              result.company_logo
            ),
          }"
        >
          <p style="font-weight: bold; text-align: left" class="mb-0">
            {{ result.title }}
          </p>
          <p class="pa-0 ma-0">
            <v-icon style="margin-left: -3px">mdi-map-marker</v-icon
            ><span v-if="result.company_address">{{
              result.mapBox_city
                ? result.mapBox_city
                : result.company_address.city
            }}</span>
            <span v-else>{{ result.Company.Address.City.city }}</span>
          </p>
        </v-col>
        <v-col
          cols="auto"
          style="position: absolute; right: 0px; top: 0px"
          class="pr-1 pt-2"
        >
          <v-tooltip
            bottom
            v-if="result && result.Company && result.Company.locked"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="width: 32px"
                v-bind="attrs"
                v-on="on"
                color="primary"
                >mdi-lock
              </v-icon>
            </template>
            <span
              >Unternehmen gesperrt, Stellenanzeige in Suche nicht
              sichtbar</span
            >
          </v-tooltip>
          <v-tooltip bottom v-if="result.published && showPublished">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="width: 32px"
                v-bind="attrs"
                v-on="on"
                color="primary"
                >mdi-eye
              </v-icon>
            </template>
            <span>Stelle öffentlich</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-else-if="
              showPublished &&
                !result.published &&
                result.availability_end_date &&
                new Date(result.availability_end_date).toLocaleString() <
                  new Date().toLocaleString()
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon style="width: 32px" v-bind="attrs" v-on="on" color="gray"
                >mdi-eye-off
              </v-icon>
            </template>
            <span>Stelle abgelaufen</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="showPublished && !result.published">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="width: 32px"
                v-bind="attrs"
                v-on="on"
                color="primary"
                >mdi-eye-off
              </v-icon>
            </template>
            <span>Stelle privat</span>
          </v-tooltip>
          <span v-if="resultInLocalStorage">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="removeFromFavorites($event)"
                  text
                  style="width: 32px"
                >
                  <v-icon
                    style="width: 32px"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    >mdi-star
                  </v-icon>
                </v-btn>
              </template>
              <span>Nicht mehr merken</span>
            </v-tooltip>
          </span>
          <span v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="addToFavorites($event)" text style="width: 32px">
                  <v-icon
                    style="width: 32px"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    >mdi-star-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Merken</span>
            </v-tooltip>
          </span>
          <v-tooltip bottom v-if="result.verified">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="width: 32px"
                v-bind="attrs"
                v-on="on"
                color="primary"
                >mdi-check-circle
              </v-icon>
            </template>
            <span>Verifiziert</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="pb-4 px-4">
        <v-col
          class="pa-0 ma-0"
          style="display: flex; justify-content: space-between"
        >
          <v-chip
            :style="{
              'background-color':
                result.job_type == 'Praktikum'
                  ? 'var(--v-primary-base) !important'
                  : 'var(--v-accent-base) !important',
              color:
                result.job_type == 'Praktikum'
                  ? 'white !important'
                  : 'black !important',
            }"
          >
            {{ result.job_type }}
          </v-chip>
          <v-chip> Beginn: {{ result.job_start | moment("DD.MM.YY") }} </v-chip>
          <v-chip v-if="result.job_end">
            Bis: {{ result.job_end | moment("DD.MM.YY") }}
          </v-chip>
          <v-chip v-else> Bis: -- </v-chip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
const axios = require("axios");
export default {
  name: "resultDetail",

  props: ["result", "index", "showPublished", "favorites"],

  data() {
    return {
      url: axios.defaults.url,
      items: undefined,
    };
  },

  watch: {},

  computed: {
    resultInLocalStorage() {
      if (
        this.favorites &&
        this.favorites.indexOf(this.result.id.toString()) != -1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    getLogoUrl(url) {
      if (!url.startsWith("https://")) {
        return this.url + url;
      }

      return url;
    },
    switchFocus() {
      this.$emit("switchFocus");
    },
    showDetailView(index) {
      this.$emit("click", index);
    },
    addToFavorites(event) {
      event.stopPropagation();
      event.preventDefault();

      this.$emit("addFavorite", this.result.id);
    },
    removeFromFavorites(event) {
      event.stopPropagation();
      event.preventDefault();

      this.$emit("removeFavorite", this.result.id);
    },
  },
};
</script>
