<template>
  <div>
    <v-card
      :style="{ width: cardWidth }"
      style="min-height: 50vh; max-height: 90vh"
      class="scrollable pa-8"
      flat
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form ref="jdForm">
        <v-container>
          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0 mb-4">
              <h2 class="encodeFont">Stellenanzeige</h2>
            </v-col>
          </v-row>
          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0">
              <v-combobox
                outlined
                :label="'Firmen*'"
                v-model="company"
                :multiple="true"
                :items="isEditor ? allCompaniesList : companies"
                return-object
                item-value="id"
                :chips="!isEditor"
                :no-filter="true"
                :rules="[
                  !isEditor ? requiredArray : required,
                  companyValidRule,
                  sameCompanyOwnerRule,
                ]"
                :search-input.sync="searchString"
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-chip close v-bind="attrs" @click:close="removeCompany(item)">
                    {{ item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  {{ item.name }}
                  <small class="ml-2" v-if="item.Contacts && item.Contacts.length > 0"
                    >{{ item.Contacts[0].first_name }}
                    {{ item.Contacts[0].last_name }}</small
                  >
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0">
              <v-text-field
                outlined
                label="Titel*"
                v-model="title"
                :rules="[required, max100]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0" :cols="isMobile ? 12 : 4">
              <v-select
                outlined
                label="Art der Stelle*"
                v-model="jobType"
                :items="jobTypes"
                return-object
                item-text="name"
                :rules="[required]"
              >
              </v-select>
            </v-col>
            <v-col
              v-if="jobType.name == 'Praktikum'"
              class="py-0 ma-0"
              :cols="isMobile ? 12 : 8"
            >
              <v-combobox
                outlined
                label="Berufsbezeichnung*"
                v-model="jobName"
                :items="jobNames"
                return-object
                item-text="name"
                :rules="[required, jobNameValidRule]"
              >
              </v-combobox>
            </v-col>

            <v-col v-else class="py-0 ma-0" :cols="isMobile ? 11 : 7">
              <v-combobox
                outlined
                label="Berufsbezeichnung*"
                v-model="jobName"
                :items="allowedJobNamesApprenticeship"
                return-object
                item-text="name"
                :rules="[required, jobNameValidRule]"
                :no-data-text="'Dem Unternehmen wurden keine Ausbildungsberufe zugewiesen.'"
              >
              </v-combobox>
            </v-col>

            <v-col v-if="jobType.name == 'Ausbildung'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary" tabindex="0"
                    >mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >Sie dürfen nur in den hier gelisteten Berufen Ausbildungen auf unserer
                  Plattform anbieten. Ihre Stellenanzeigen für Ausbildungen werden dafür
                  als verifiziert markiert.
                  <a href="https://hallo-beruf.de/#/kontakt">Kontaktieren Sie uns</a>, um
                  weitere Berufe hinzuzufügen.</span
                >
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0" :cols="12">
              <v-combobox
                outlined
                label="Branche*"
                v-model="profession"
                :items="professions"
                return-object
                item-text="name"
                :rules="[required, validObject]"
              >
              </v-combobox>
            </v-col>
          </v-row>

          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0" :cols="isMobile ? 12 : 6">
              <!-- <v-menu
                class="py-0"
                ref="startDateMenu"
                v-model="startDateMenu"
                transition="scale-transition"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }"> -->
                  <v-text-field
                  v-model="startDate"
                    label="Beginn der Stelle"
                    outlined
                    :rules="[]"
                    type="date"
                    id="startDateTextField"
                  ></v-text-field>
                <!-- </template>
                <v-date-picker
                  v-model="startDate"
                  locale="de-de"
                  :first-day-of-week="1"
                  @click:date="startDateMenu = false"
                ></v-date-picker>
              </v-menu> -->
            </v-col>
            <v-col class="py-0 ma-0" :cols="isMobile ? 12 : 6">
              <!-- <v-menu
                class="py-0"
                ref="endDateMenu"
                v-model="endDateMenu"
                transition="scale-transition"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }"> -->
                  <v-text-field
                  v-model="endDate"
                    label="Ende der Stelle"
                    outlined
                    :rules="[]"
                    type="date"
                    id="endDateTextField"
                  ></v-text-field>
                <!-- </template>
                <v-date-picker
                  v-model="endDate"
                  locale="de-de"
                  :first-day-of-week="1"
                  @click:date="endDateMenu = false"
                ></v-date-picker>
              </v-menu> -->
            </v-col>
          </v-row>
          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0" :cols="isMobile ? 12 : 6">
              <!-- <v-menu
                class="py-0"
                ref="availStartDateMenu"
                v-model="availStartDateMenu"
                transition="scale-transition"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }"> -->
                  <v-text-field
                  v-model="availStartDate"
                    label="Stelle sichtbar ab"
                    outlined
                    :rules="[]"
                    type="date"
                    id="availStartDateTextField"
                  ></v-text-field>
                <!-- </template>
                <v-date-picker
                  v-model="availStartDate"
                  locale="de-de"
                  :first-day-of-week="1"
                  @click:date="availStartDateMenu = false"
                ></v-date-picker>
              </v-menu> -->
            </v-col>
            <v-col class="py-0 ma-0" :cols="isMobile ? 12 : 6">
              <!-- <v-menu
                class="py-0"
                ref="availEndDateMenu"
                v-model="availEndDateMenu"
                transition="scale-transition"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }"> -->
                  <v-text-field
                  v-model="availEndDate"
                    label="Stelle sichtbar bis"
                    outlined
                    :rules="[]"
                    type="date"
                    id="availEndDateTextField"
                  ></v-text-field>
                <!-- </template>
                <v-date-picker
                  v-model="availEndDate"
                  locale="de-de"
                  :first-day-of-week="1"
                  @click:date="availEndDateMenu = false"
                ></v-date-picker>
              </v-menu> -->
            </v-col>
          </v-row>

          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0"> Stellenbeschreibung* </v-col>
          </v-row>
          <v-row class="py-0 ma-0">
            <v-col
              class="py-0 ma-0"
              tabindex="0"
              id="editorCol"
              @focus="
                closeAllDatePickers();
                focusEditor();
              "
            >
              <vue-editor
                tabindex="0"
                ref="vue2Editor"
                class="encodeFont focusable"
                id="editor"
                v-model="htmlDescription"
                :editorToolbar="customToolbar"
                :class="{
                  redOutline:
                    htmlDescription.length > 5000 ||
                    (isJdDetailEmpty && htmlDescription.length == 0),
                }"
              >
              </vue-editor>
              <div style="display: flex; justify-content: space-between">
                <div>
                  <p
                    class="pl-3"
                    style="font-size: small; color: red"
                    :style="{
                      display: htmlDescription.length <= 5000 ? 'none' : 'block',
                    }"
                  >
                    Maximal 5000 Zeichen!
                  </p>
                </div>
                <p class="pr-3" style="font-size: small">
                  {{ htmlDescription.length }}/5000
                </p>
              </div>
            </v-col>
          </v-row>
          <br />
          <v-row class="py-0 ma-0">
            <v-col class="py-0 ma-0">
              <v-text-field
                id="annotationField"
                class="encodeFont"
                v-model="htmlAnnotation"
                outlined
                label="Kommentar/ Anmerkung"
                :rules="[max1000]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col class="py-0 px-6">
              <span>
                <v-chip class="mr-2 mb-2" v-for="file in currentFiles" :key="file.id"
                  >{{ file.name }}
                  <span @click="removeFiles(file)"
                    ><v-icon class="ml-1">mdi-close</v-icon></span
                  ></v-chip
                >
              </span>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="pb-0 pl-5 pr-6" :cols="11">
              <v-file-input
                v-model="files"
                label="Datei"
                multiple
                prepend-icon="mdi-paperclip"
                class=""
                outlined
                chips
                accept="application/pdf"
                :rules="[max3Files, max3MB]"
              >
              </v-file-input>
            </v-col>
            <v-col class="pb-8">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary" tabindex="1"
                    >mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >Alle hier eingestellten Dateien sind für alle Besucher der Plattform
                  öffentlich zugänglich.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 4">
              <v-btn
                block
                elevation="0"
                class="primaryOnWhiteButton lightOutline pa-1 py-3"
                @click="close()"
                ><v-icon class="mr-4">mdi-cancel</v-icon> Abbrechen
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 8">
              <v-btn block elevation="0" class="primaryButton py-3" @click="saveData()"
                ><v-icon class="mr-4">mdi-check</v-icon> Speichern
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container> </v-form
    ></v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">
      Fokus zurück zu "Schließen"
    </div>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { permissions } from "../../../util/permissions.js";

const axios = require("axios");

export default {
  name: "resultEdit",

  props: ["jdData", "companies", "refreshDisplay"],

  data() {
    return {
      jobNames: [],

      profession: undefined,
      professions: [],

      jobTypes: [
        { name: "Praktikum", id: 1 },
        { name: "Ausbildung", id: 2 },
      ],

      title: "",
      jobName: "",
      jobType: { name: "Praktikum", id: 1 },
      htmlDescription: "",
      htmlAnnotation: "",

      startDate: undefined,
      startDateMenu: false,

      endDate: undefined,
      endDateMenu: false,

      availStartDate: undefined,
      availStartDateMenu: false,

      availEndDate: undefined,
      availEndDateMenu: false,

      company: undefined,
      allCompaniesList: [],

      companyValid: true,
      jobNameValid: true,

      files: [],
      currentFiles: [],

      searchString: "",

      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"], // Add link button
      ],
      required: (value) => !!value || "Pflichtfeld!",
      requiredArray: (value) => (!!value && value.length > 0) || "Pflichtfeld!",
      companyValidRule: () => this.companyValid || "Firma nicht im System!",
      sameCompanyOwnerRule: () =>
        this.sameCompanyOwner ||
        "Die angegebenen Firmen gehören zu unterschiedlichen User-Accounts!",
      jobNameValidRule: () => this.jobNameValid || "Berufsbezeichnung nicht im System!",
      validObject: (v) =>
        typeof v == "object" || !v || "Bitte Berufsbezeichnung auswählen.",
      max100: (value) => !value || value.length <= 100 || "Max. 100 Zeichen!",
      max3Files: (value) =>
        value.length + this.currentFiles.length <= 3 || "Max. 3 Dateien!",

      max1000: (value) => !value || value.length < 1000 || "Max. 1000 Zeichen!",
      max3MB: (value) => !value || this.checkFileSize(value),

      isJdDetailEmpty: false,

      loading: false,
    };
  },

  components: {
    VueEditor,
  },

  watch: {
    company: {
      handler: function(){
        if(this.company){
          for(let i = this.company.length -1; i >= 0; i--){
            if(typeof this.company[i] == "string"){
              this.company.splice(i, 1)
            }
          }
        }
      },
      deep: true
    },
    searchString() {
      if (this.$store.state.permission == permissions.UNTERNEHMEN) {
        return 
      }

      if ( this.searchString && this.searchString.length >= 2) {
        this.getCompanies(this.searchString);
      }
    }
  },

  computed: {
    isEditor() {
      if (this.$store.state.permission <= permissions.REDAKTEUR) {
        return true;
      }
      return false;
    },
    sameCompanyOwner() {
      if(!this.isEditor){
        return true
      }
      if (!this.company) {
        return true;
      } else {
        let companyOwner = undefined;
        for (let i = 0; i < this.company.length; i++) {
          if (i == 0) {
            companyOwner = this.company[i].User;
          } else {
            if (!companyOwner) {
              return false;
            } else if (this.company[i].User != companyOwner) {
              return false;
            }
          }
        }
      }
      return true;
    },
    allowedJobNamesApprenticeship() {
      if (this.allCompaniesList.length == 0) {
        return [];
      } else {
        let result = [];

        for (let i = 0; i < this.allCompaniesList.length; i++) {
          for (
            let j = 0;
            j < this.allCompaniesList[i].Allowed_apprenticeships.length;
            j++
          ) {
            result.push(this.allCompaniesList[i].Allowed_apprenticeships[j]);
          }
        }

        // console.log(result);

        return result;
      }
    },
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "85vw";
        case "sm":
          return "75vw";
        case "md":
          return "65vw";
        case "lg":
          return "55vw";
        case "xl":
          return "45vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    setValues() {
      if (this.jdData) {
        this.title = this.jdData.title;

        for (let i = 0; i < this.jobTypes.length; i++) {
          if (this.jobTypes[i].name == this.jdData.job_type) {
            this.jobType = this.jobTypes[i];
          }
        }

        this.htmlDescription = this.jdData.details;
        this.htmlAnnotation = this.jdData.annotation;

        this.startDate = this.jdData.job_start;

        this.endDate = this.jdData.job_end;

        this.availStartDate = this.jdData.availability_start_date;

        this.availEndDate = this.jdData.availability_end_date;

        this.currentFiles = this.jdData.files;

        this.company = [...this.jdData.Companies];
      } else {
        this.title = undefined;

        this.htmlDescription = "";
        this.htmlAnnotation = "";

        this.startDate = undefined;

        this.endDate = undefined;

        this.availStartDate = undefined;

        this.availEndDate = undefined;

        this.company = [];

        this.currentFiles = [];
      }
    },
    focusEditor() {
      document.getElementById("editorCol").children[0].focus();
    },
    closeAllDatePickers() {
      this.startDateMenu = false;
      this.endDateMenu = false;
      this.availStartDateMenu = false;
      this.availEndDateMenu = false;
    },
    resetFocus(e) {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("closeButton").focus();
    },
    close(resp) {
      this.$emit("close", resp);
    },
    getProfessions() {
      return new Promise((resolve) => {
        axios
          .get(axios.defaults.baseURL + "professions/")
          .then((resp) => {
            this.professions = resp.data;
            if (this.jdData) {
              this.profession = this.jdData.Profession;
            }

            resolve();
          })
          .catch((e) => {
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            console.log(e);
            resolve();
          });
      });
    },
    getJobNames() {
      return new Promise((resolve) => {
        axios
          .get(axios.defaults.baseURL + "jobnames/all/?filter=1")
          .then((resp) => {
            this.jobNames = resp.data;

            if (this.jdData) {
              this.jobName = this.jdData.Job_name;
            }

            resolve();
          })
          .catch((e) => {
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            console.log(e);
            resolve();
          });
      });
    },
    getReadableDate(date) {
      if (!date) return;

      let temp = date.split("-");

      if (temp.length < 3) {
        return "";
      }

      return temp[2] + "." + temp[1] + "." + temp[0];
    },
    getCompanies(query) {
      return new Promise((resolve, reject) => {
        let url = "companies/simple";
        if (this.$store.state.permission == permissions.UNTERNEHMEN) {
          url += "/own";
        }

        if (query) {
          url += "?query=" + query;
        }

        axios
          .get(axios.defaults.baseURL + url)
          .then((resp) => {
            if (this.$store.state.permission == permissions.UNTERNEHMEN) {
              this.allCompaniesList = resp.data;
            } else {
              this.allCompaniesList = resp.data.results;
            }

            resolve();
          })
          .catch((e) => {
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            console.log(e);

            reject();
          });
      });
    },
    onInputCompanies(data) {

      if (typeof data == "string" && data.length > 1) {
        this.getCompanies(data);
      }
    },
    saveData() {
      if (this.htmlDescription.length == 0) {
        this.isJdDetailEmpty = true;
      }

      if (!this.company) {
        this.companyValid = false;
      } else {
        this.companyValid = true;
        for (let i = 0; i < this.company.length; i++) {
          if (!this.company[i].id) {
            this.companyValid = false;
          }
        }
      }

      if (!this.jobName || !this.jobName.id) {
        this.jobNameValid = false;
      } else {
        this.jobNameValid = true;
      }

      if (
        this.$refs.jdForm.validate() &&
        this.htmlDescription.length <= 5000 &&
        this.htmlDescription.length > 0
      ) {
        this.loading = true;

        let temp = new Date().toLocaleString().split(",")[0].split(".");
        let today = temp[2] + "-" + temp[1] + "-" + temp[0];

        if (!this.startDate) {
          this.startDate = today;
        }

        let formData = new FormData();
        formData.append("title", this.title);
        formData.append("Job_name", this.jobName.id);
        formData.append("Profession", this.profession.id);
        formData.append("job_type", this.jobType.id);

        formData.append(
          "details",
          this.htmlDescription.replaceAll("&lt;", "<").replaceAll("&gt;", ">")
        );

        if (this.startDate) {
          formData.append("job_start", this.startDate);
        }

        if (this.availStartDate) {
          formData.append("availability_start_date", this.availStartDate);
        }

        if (this.htmlAnnotation) {
          formData.append("annotation", this.htmlAnnotation);
        }

        if (this.availEndDate) {
          formData.append("availability_end_date", this.availEndDate);
        }
        if (this.endDate) {
          formData.append("job_end", this.endDate);
        }

        formData.append("template_id", this.jdData ? this.jdData.template_id : null);

        this.files.forEach((file, index) => {
          formData.append("file_" + index, file);
        });

        let tempFiles = [];
        for (let i = 0; i < this.currentFiles.length; i++) {
          tempFiles.push(this.currentFiles[i].id);
        }

        formData.append("current_files", JSON.stringify(tempFiles));

        if (!this.availStartDate || this.availStartDate == "") {
          formData.append("published", true);
          formData.append("publish_date", today);
        } else if (this.availStartDate < today) {
          formData.append("published", true);
          formData.append("publish_date", this.availStartDate);
        } else {
          formData.append("published", false);
          formData.append("publish_date", undefined);
        }

        let companies = [];
        for (let i = 0; i < this.company.length; i++) {
          companies.push(this.company[i].id);
        }

        formData.append("Companies", JSON.stringify(companies));

        if (this.jdData && this.jdData.id) {
          formData.append("id", this.jdData.id);
          axios
            .patch(
              axios.defaults.baseURL + "jobdescriptions/patch/" + this.jdData.id,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              this.loading = false;
              this.$emit("close", { data: 1, resp_id: this.jdData.id });
            })
            .catch((e) => {
              this.loading = false;
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              console.log(e);
            });
        } else {
          axios
            .post(axios.defaults.baseURL + "jobdescriptions/post/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.loading = false;
              window.scrollTo(0, 0);
              this.$emit("close", 1);
            })
            .catch((e) => {
              this.loading = false;
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              console.log(e);
            });
        }
      }
    },
    removeCompany(company) {
      if (Array.isArray(this.company)) {
        this.company.splice(this.company.indexOf(company), 1);
      } else {
        this.company = undefined;
      }
    },
    removeFiles(file) {
      this.currentFiles.splice(this.currentFiles.indexOf(file), 1);
    },
    checkFileSize(files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 3 * 1024 * 1024) {
          return "Dateien dürfen maximal 3MB groß sein!";
        }
      }
    },
  },

  mounted() {
    this.getJobNames().then(() => {
      this.getProfessions().then(() => {
        if (this.$store.state.permission == permissions.UNTERNEHMEN) {
          this.getCompanies().then(() => this.setValues());
        } else {
          this.setValues();
        }
      });
    });

    document.getElementById("startDateTextField").addEventListener(
      "focus",
      function () {
        setTimeout(() => {
          this.startDateMenu = true;

          this.endDateMenu = false;
          this.availStartDateMenu = false;
          this.availEndDateMenu = false;
        }, 100);
      }.bind(this)
    );

    document.getElementById("endDateTextField").addEventListener(
      "focus",
      function () {
        setTimeout(() => {
          this.endDateMenu = true;

          this.startDateMenu = false;
          this.availStartDateMenu = false;
          this.availEndDateMenu = false;
        }, 100);
      }.bind(this)
    );

    document.getElementById("availStartDateTextField").addEventListener(
      "focus",
      function () {
        setTimeout(() => {
          this.availStartDateMenu = true;

          this.startDateMenu = false;
          this.endDateMenu = false;
          this.availEndDateMenu = false;
        }, 100);
      }.bind(this)
    );

    document.getElementById("availEndDateTextField").addEventListener(
      "focus",
      function () {
        setTimeout(() => {
          this.availEndDateMenu = true;

          this.startDateMenu = false;
          this.availStartDateMenu = false;
          this.endDateMenu = false;
        }, 100);
      }.bind(this)
    );

    document.getElementById("closeButton").focus();

    //add esc listener to WYSIWYG editor
    this.$refs.vue2Editor.quill.keyboard.bindings[27] = [
      {
        key: 27,
        shiftKey: false,
        handler: () => {
          document.getElementById("annotationField").focus();
        },
      },
    ];
  },
};
</script>
