<template>
  <div id="companylistView" class="mb-3">
    <v-container fluid style="margin: 0 auto; width: 100%" v-if="companies">
      <v-row class="mb-0" style="flex-grow: 0">
        <v-col :cols="isMobile ? 12 : 12" style="display: flex" class="pa-0">
          <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col :cols="6" class="pa-0 pr-1">
                <v-card flat>
                  <v-card-title class="encodeFont pa-0 pb-1">
                    <v-text-field
                      hide-details
                      v-model="searchString"
                      class="px-4 hideInputMessage"
                      append-icon="mdi-magnify"
                      label="Firmenname eingeben"
                    >
                    </v-text-field>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="auto" class="py-0" style="height: inherit">
                <MenuVue @changeFilterQ="changeFilterQ" />
              </v-col>

              <v-spacer />

              <v-col cols="auto" class="pa-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn large
                        block
                        elevation="0"
                        class="primaryOnWhiteButton pa-0"
                        style="width: 64px" @click="showNewCompanyView=true" v-bind="attrs" v-on="on">
                      <v-icon
                        
                        >mdi-plus</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Neue Kontaktperson anlegen</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="auto" class="py-0 pl-7">
                <small>{{ totalCompanies }} Treffer</small>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col
          :cols="isMobile ? 12 : 5"
          class="pa-0"
          style="display: flex; flex-direction: column; margin-top: 12px"
          :style="{ height: !isMobile ? 'calc(-235px + 100vh )' : 'unset' }"
        >
          <div class="scrollable" id="scrollBox">
            <div class="mb-4" v-for="company in companies" :key="company.id + '_card'">
              <CompanyCardVue
                :data="company"
                @select="companyCardClicked(company)"
                @switchFocus="switchFocus"
              />
            </div>
          </div>
          <v-pagination
            class="mt-2"
            v-model="page"
            :length="maxPages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
        <v-col
          v-if="!isMobile"
          cols="7"
          class="pr-0"
          style="overflow-y: auto"
          :style="{ height: !isMobile ? 'calc(-235px + 100vh )' : 'unset' }"
        >
          <v-card
            flat
            class="pa-4 focusable"
            :ripple="false"
            id="companyDetail"
            tabindex="0"
          >
            <CompanyDetailVue
              style="padding-bottom: 32px"
              @edit="showCompanyEditView = true"
              @blacklist="showBlacklistWarning = true"
              @deleteContact="showDeleteContact"
              @editContact="showEditContact"
              :editorMode="true"
              :company="activeCompany"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <CompanyDetailMobileVue
      :company="activeCompany"
      :editorMode="true"
      v-if="isMobile"
      :open="detailDrawer"
      @edit="showCompanyEditView = true"
      @close="closeEditView"
      @blacklist="showBlacklistWarning = true"
      @deleteContact="showDeleteContact"
      @editContact="showEditContact"
    />

    <v-overlay :value="showCompanyEditView" :dark="false" style="z-index: 12 !important">
      <EditCompanyVue
        v-if="companies"
        :companyData="activeCompany"
        @close="closeEditCompany"
        :editorView="true"
      />
    </v-overlay>

    <v-overlay :value="showNewCompanyView" :dark="false" style="z-index: 12 !important">
      <EditCompanyVue
        @close="closeNewCompany"
        :editorView="true"
      />
    </v-overlay>

    <v-overlay :value="showBlacklistWarning" :dark="false" style="z-index: 12 !important">
      <BlacklistComponentVue
        :company="activeCompany"
        @close="showBlacklistWarning = false"
        @confirm="blacklistCompany"
      />
    </v-overlay>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>

    <v-overlay
      :value="showContactDeletionWarning"
      :dark="false"
      style="z-index: 12 !important"
    >
      <DeletionWarningVue
        v-if="activeCompany && activeContact"
        :data="activeContact.first_name + ' ' + activeContact.last_name"
        item=" die Kontaktperson"
        @cancel="hideDeletionWarning"
        @confirm="deleteContact"
      />
    </v-overlay>

    <v-overlay :value="showContactEditView" :dark="false" style="z-index: 12 !important">
      <EditContactVue
        v-if="activeCompany"
        :contactData="activeContact"
        :companyId="activeCompany.id"
        @close="closeEditView"
      />
    </v-overlay>
  </div>
</template>

<script>
import CompanyCardVue from "../../../components/Backoffice/Company/CompanyCard.vue";
import CompanyDetailVue from "../../../components/Backoffice/Company/CompanyDetail.vue";
import CompanyDetailMobileVue from "../../../components/Backoffice/Company/CompanyDetail_MobileDrawer.vue";
import EditCompanyVue from "../../../components/Backoffice/Company/CompanyEdit.vue";
import BlacklistComponentVue from "../../../components/Backoffice/Editor/PutOntoBlacklist.vue";
import DeletionWarningVue from "../../../components/Main/DeletionWarning.vue";
import EditContactVue from "../../../components/Backoffice/Company/ContactEdit.vue";
import MenuVue from "../../../components/Backoffice/Editor/CompanyListFilter.vue";

const axios = require("axios");

export default {
  name: "companyList",

  props: ["width", "height", "search"],

  data() {
    return {
      companies: [],
      companyCount: undefined,

      page: 1,
      maxPages: undefined,

      activeCompany: undefined,

      showCompanyEditView: false,
      showNewCompanyView: false,

      showBlacklistWarning: false,

      showContactDeletionWarning: false,
      showContactEditView: false,

      activeContact: undefined,

      searchString: undefined,
      filterQ: undefined,

      detailDrawer: 0,

      loading: false,
      totalCompanies: 0,
    };
  },

  components: {
    CompanyCardVue,
    CompanyDetailVue,
    CompanyDetailMobileVue,
    EditCompanyVue,
    BlacklistComponentVue,
    DeletionWarningVue,
    EditContactVue,
    MenuVue,
  },

  watch: {
    page() {
      this.getCompaniesByPage();
    },
    searchString() {
      if (this.searchString && this.searchString.length >= 3) {
        if (this.page == 1) {
          this.getCompaniesByPage();
        } else {
          this.page = 1;
        }
      } else {
        this.getCompaniesByPage();
      }
    },
    filterQ() {
      if (this.page == 1) {
        this.getCompaniesByPage();
      } else {
        this.page = 1;
      }
    },
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    switchFocus() {
      document.getElementById("companyDetai").focus();
    },
    closeEditView(data) {
      this.activeContact = undefined;
      this.showContactEditView = false;

      if (data) {
        if (!data.Company) {
          //if data is a company set it back to active
          let newActive = undefined;
          let found = false;

          for (let j = 0; j < this.companies.length; j++) {
            if (this.companies[j].id == this.activeCompany.id) {
              for (let i = 0; i < this.companies[j].contacts.length; i++) {
                if (this.companies[j].contacts[i].id == data.id) {
                  this.companies[j].contacts[i] = data;

                  this.activeCompany = undefined;
                  newActive = this.companies[j];
                  found = true;
                  this.$forceUpdate();

                  break;
                }
              }
            }

            if (found) break;
          }

          setTimeout(() => {
            this.activeCompany = newActive;
          }, 100);
        }
      }
    },
    showEditContact(contact) {
      this.activeContact = contact;
      this.showContactEditView = true;
    },
    showDeleteContact(contact) {
      this.activeContact = contact;
      this.showContactDeletionWarning = true;
    },
    hideDeletionWarning() {
      this.activeContact = undefined;
      this.showContactDeletionWarning = false;
    },
    deleteContact() {
      axios
        .delete(axios.defaults.baseURL + "contactpersons/delete/" + this.activeContact.id)
        .then(() => {
          for (let i = 0; i < this.activeCompany.contacts.length; i++) {
            if (this.activeCompany.contacts[i].id == this.activeContact.id) {
              this.activeCompany.contacts.splice(i, 1);
              this.$forceUpdate();
              break;
            }
          }

          this.activeContact = undefined;
          this.showContactDeletionWarning = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.showContactDeletionWarning = false;
        });
    },

    blacklistCompany(data) {
      this.loading = true;

      axios
        .patch(axios.defaults.baseURL + "companies/patch/" + data.id, {
          id: data.id,
          locked: true,
        })
        .then(() => {
          for (let i = 0; i < this.companies.length; i++) {
            if (this.companies[i].id == data.id) {
              this.companies[i].locked = true;
              this.showBlacklistWarning = false;
              break;
            }
          }

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    closeEditCompany(data) {
      this.showCompanyEditView = false;

      if (data) {
        this.getCompaniesByPage(false);
      }
    },
    closeNewCompany(data){
      this.showNewCompanyView = false

      if(data){
        this.searchString = data.name
        this.getCompaniesByPage(true)
      }
    },
    companyCardClicked(company) {
      this.activeCompany = company;

      if (this.isMobile) {
        this.detailDrawer++;
      }
    },
    getCompaniesByPage(reloadActiveCompany = false) {
      let searchString = "?page=" + this.page;
      if (this.searchString) {
        searchString += "&search=" + this.searchString.replace(/&/g, "%26");
      }
      if (this.filterQ) {
        searchString += this.filterQ;
      }

      axios
        .get(axios.defaults.baseURL + "companies/" + searchString)
        .then((resp) => {
          this.companies = resp.data.results;
          this.totalCompanies = resp.data.count;

          this.maxPages =
            resp.data.count % 20 == 0
              ? resp.data.count / 20
              : Math.floor(resp.data.count / 20) + 1;

          if (!reloadActiveCompany) this.activeCompany = resp.data.results[0];

          document.getElementById("scrollBox").scrollTop = 0;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    changeFilterQ(data) {
      this.filterQ = data;
    },
  },

  mounted() {
    let param = this.$route.params.company;
    if (param) {
      this.searchString = param;
    }

    this.getCompaniesByPage();
  },
};
</script>
