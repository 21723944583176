<template>
  <div>
    <v-card
      flat
      style="min-height: 50vh"
      class="py-12"
      :style="{
        width: cardWidth,
        height: cardHeight != undefined ? cardHeight : 'auto',
        'max-height': isMobile
          ? '80vh'
          : cardHeight == 'auto' || !cardHeight
          ? '80vh'
          : '80vh',

        overflow: 'auto',
      }"
      :class="{
        'pa-8': !setupView,
        scrollable: cardHeight != undefined,
        hiddenScroll: cardHeight != undefined,
      }"
    >
      <v-btn
        v-if="!setupView"
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-form ref="companyForm">
        <v-container fluid>
          <v-row class="mb-6">
            <v-col class="py-0 px-3">
              <h2 v-if="isMobile" class="encodeFont mb-2 px-3">Filialen- information</h2>
              <h2 v-else class="encodeFont px-3">Filialeninformation</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="mainCols" class="pb-0">
              <v-container fluid class="pb-0 pr-0" :class="{ 'pt-0': isMobile }">
                <v-row>
                  <v-col class="pt-0">
                    <v-text-field
                      label="Firmenname*"
                      v-model="name"
                      outlined
                      :rules="[required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="editorView">
                  <v-col class="py-0">
                    <v-combobox
                      class="maxWidthCombobox"
                      hide-selected
                      return-object
                      :items="users"
                      outlined
                      label="Nutzeraccount"
                      :rules="[validObject]"
                      v-model="user"
                      item-text="email"
                    >
                      <template v-slot:selection="{ item }">
                        <span class="pr-2">
                          {{ item.first_name }} {{ item.last_name }}
                        </span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-container style="max-width: 40vw !important; margin-left: 0">
                          <v-row>
                            <v-col>
                             {{ item.email }}
                              <small>{{ item.first_name }} {{ item.last_name }}</small>
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-combobox
                      class="maxWidthCombobox"
                      hide-selected
                      return-object
                      :items="jobNames"
                      item-text="name"
                      multiple
                      outlined
                      small-chips
                      label="Ausbildungsberufe"
                      :rules="[arrayOfObjects]"
                      v-model="allowedApprenticeships"
                    >
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                          v-bind="attrs"
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.name }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-container style="max-width: 40vw !important; margin-left: 0">
                          <v-row>
                            <v-col>
                              {{ item.name }}
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <AddressComponentVue
                  class="pa-0 mt-3"
                  :isRequired="true"
                  :addressData="address"
                  :disabled="false"
                  :validateNow="validateNow"
                  :removePadding="true"
                  @formValid="validAddressForm"
                />

                <v-row>
                  <v-col cols="6">
                    <v-text-field label="Webseite" v-model="weblink" outlined>
                    </v-text-field>
                  </v-col>

                  <v-col v-if="!editorView">
                    <v-combobox
                      :items="contacts"
                      label="Kontaktpersonen"
                      v-model="selectedContacts"
                      outlined
                      multiple
                    >
                      <template v-slot:item="{ item }">
                        {{ item.first_name }} {{ item.last_name }}
                      </template>
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                          v-bind="attrs"
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.first_name }} {{ item.last_name }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="auto" class="pl-0" v-if="!editorView">
                    <v-btn
                      @click="showEditContact()"
                      large
                      block
                      elevation="0"
                      class="primaryOnWhiteButton thinLine pa-0 lightOutline"
                      style="height: 56px !important; width: 56px"
                      ><v-icon> mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col :cols="imageCols" class="pb-0" :class="{ 'pr-0': isMobile }">
              <v-container
                class="pb-0 pr-3"
                fluid
                :class="{ 'pt-0': isMobile }"
                style="display: flex; flex-direction: column"
                :style="{ height: isMobile ? 'unset' : 'calc(100% - 32px)' }"
              >
                <v-row class="mb-2 pa-0">
                  <v-spacer />
                  <v-col cols="12" class="pa-0 pr-3">
                    <img
                      class="imageContain"
                      v-if="logo"
                      style="object-fit: contain !important; height: 219px; width: 100%"
                      :src="getImgSrc(logo)"
                      :alt="
                        'Firmenlogo für ' +
                        (companyData && companyData.name
                          ? companyData.name
                          : 'neues Event')
                      "
                    />
                    <img
                      v-else
                      class="imageContain"
                      style="object-fit: contain !important; height: 219px; width: 100%"
                      src="../../../assets/images/noImg.svg"
                      :alt="
                        'Platzhalterlogo für ' +
                        (companyData && companyData.name
                          ? companyData.name
                          : 'neues Event')
                      "
                    />
                  </v-col>
                </v-row>
                <v-row class="pr-3" :class="{ 'px-3': isMobile, 'mb-4': setupView }">
                  <v-col
                    class="pa-0"
                    :cols="isMobile ? 12 : 6"
                    :class="{
                      'pr-1': !isMobile,
                      'px-3': setupView,
                      'pr-0': isMobile,
                      'mb-4': isMobile,
                    }"
                  >
                    <div class="imageUploader" @keyup.enter="showUpload">
                      <image-uploader
                        tabindex="0"
                        class="imageUploadFocus"
                        style="height: 56px"
                        :debug="0"
                        :maxWidth="1024"
                        :maxHeight="1024"
                        :quality="0.6"
                        :autoRotate="false"
                        outputFormat="verbose"
                        :preview="false"
                        accept="image/*"
                        id="logoID"
                        v-model="logo"
                        @input="setImage"
                      >
                        <label
                          id="uploadLabel"
                          for="logoID"
                          slot="upload-label"
                          style="width: inherit !important"
                        >
                          <span
                            style="
                            border-radius: 4px !important
                              width: 100% !important;
                              display: flex;
                              align-items: center;
                              text-align: center;
                              justify-content: center;
                            "
                            block
                            elevation="0"
                            class="primaryOnWhiteButton thinLine lightOutline pa-2 focusable"
                          >
                            <v-icon class="mr-3 primary--text">mdi-camera</v-icon>
                            Hochladen</span
                          >
                        </label>
                      </image-uploader>
                    </div>
                  </v-col>
                  <v-col
                    :cols="isMobile ? 12 : 6"
                    class="pa-0"
                    :class="{ 'pl-1': !isMobile, 'mb-7': isMobile, 'pl-0': isMobile }"
                  >
                    <v-btn
                      block
                      elevation="0"
                      class="primaryOnWhiteButton thinLine lightOutline px-2 py-3 ma-0"
                      style="height: 56px !important"
                      @click="logo = null"
                      ><v-icon class="mr-3">mdi-delete</v-icon>Löschen</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col class="py-0">
              <v-container fluid>
                <v-row>
                  <v-col class="py-0">
                    <v-combobox
                      class="maxWidthCombobox"
                      hide-selected
                      return-object
                      :items="professions"
                      item-text="name"
                      multiple
                      outlined
                      small-chips
                      :rules="[requiredArray, arrayOfObjects]"
                      label="Branche/n*"
                      v-model="selectedProfessions"
                    >
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                          v-bind="attrs"
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.name }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-container style="max-width: 40vw !important; margin-left: 0">
                          <v-row>
                            <v-col>
                              {{ item.name }}
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row> -->

          <v-row v-if="editorView">
            <v-col :cols="12">
              <EditContactVue
                :contactData="
                  companyData && companyData.contacts
                    ? companyData.contacts[0]
                    : undefined
                "
                :companyId="companyData ? companyData.id : undefined"
                :setupView="false"
                :component="true"
                @change="changeContact"
              />
            </v-col>
          </v-row>

          <v-row v-if="!setupView">
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 4" class="px-6">
              <v-btn
                block
                elevation="0"
                class="primaryOnWhiteButton lightOutline py-3"
                @click="close()"
                ><v-icon class="mr-4 pa-1">mdi-cancel</v-icon> Abbrechen
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              :cols="isMobile ? 12 : 8"
              class="pr-6"
              :class="{ 'pl-0': !isMobile, 'pl-6': isMobile }"
            >
              <v-btn block elevation="0" class="primaryButton py-3" @click="saveData()"
                ><v-icon class="mr-4">mdi-check</v-icon> Speichern
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-form>

      <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">
        Fokus zurück zu "Schließen"
      </div>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>

    <v-overlay :value="showContactEditView" :dark="false">
      <EditContactVue :contactData="activeContact" @close="closeEditView" />
    </v-overlay>
  </div>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
import AddressComponentVue from "../../Main/Address.vue";
import EditContactVue from "./ContactEdit.vue";

const axios = require("axios");

import { permissions } from "@/util/permissions";

export default {
  name: "companyEdit",

  props: ["companyData", "setupView", "cardHeight", "updateNow", "editorView"],

  data() {
    return {
      logo: undefined,
      name: undefined,
      selectedProfessions: [],
      selectedContacts: [],
      activeContact: undefined,
      address: {
        City: {
          city: undefined,
          postcode: undefined,
        },
        street: undefined,
      },

      user: undefined,
      users: [],

      contact: undefined, //editorview only, get infor from editcontact and save here

      addressSave: {},

      weblink: undefined,

      required: (value) => !!value || "Pflichtfeld!",
      requiredArray: (value) => (!!value && value.length > 0) || "Pflichtfeld!",
      arrayOfObjects: (value) =>
        !value ||
        value.length == 0 ||
        this.isValidObjectArray(value) ||
        "Alle Einträge müssen aus der Liste sein",
      validObject: (value) =>
        !value || typeof value == "object" || "Einträge müssen aus der Liste sein",

      professions: [],

      jobNames: [],
      allowedApprenticeships: [],

      validateNow: 0,
      addressFormIsValid: false,

      contacts: [],

      loading: false,
      showContactEditView: false,
    };
  },

  components: {
    ImageUploader,
    AddressComponentVue,
    EditContactVue,
  },

  watch: {
    companyData() {
      this.updateData();
    },
    updateNow() {
      this.saveData();
    },
  },

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "95vw";
        case "sm":
          return "95vw";
        case "md":
          return "85vw";
        case "lg":
          return "85vw";
        case "xl":
          return "80vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    mainCols() {
      if (this.setupView) {
        return 12;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 7;
        case "lg":
          return 7;
        case "xl":
          return 7;
        default:
          return 7;
      }
    },
    imageCols() {
      if (this.setupView) {
        return 12;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 5;
        case "lg":
          return 5;
        case "xl":
          return 5;
        default:
          return 5;
      }
    },
  },

  methods: {
    isValidObjectArray(array) {
      for (let i = 0; i < array.length; i++) {
        if (typeof array[i] != "object") {
          return false;
        }
      }

      return true;
    },
    changeContact(contact) {
      if (this.editorView) {
        this.contact = contact;
      }
    },
    closeEditView(data) {
      this.showContactEditView = false;

      if (data) {
        this.fetchContacts();
      }
    },
    showEditContact() {
      this.showContactEditView = true;
    },
    showUpload(e) {
      e.preventDefault();
      e.stopPropagation();

      document.getElementById("uploadLabel").click();
    },
    resetFocus(e) {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("closeButton").focus();
    },
    validAddressForm(data) {
      this.addressFormIsValid = data;
    },
    close(data) {
      this.$emit("close", data);
    },
    next() {
      this.$emit("next");
    },
    setImage(output) {
      this.logo = output;
    },
    getImgSrc(img) {
      if (img.dataUrl) {
        return img.dataUrl;
      } else {
        return axios.defaults.url + img;
      }
    },
    base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    saveData() {
      ++this.validateNow;

      setTimeout(() => {
        if (this.$refs.companyForm.validate() && this.addressFormIsValid) {
          this.loading = true;

          let formData = new FormData();
          if (this.selectedProfessions) {
            for (let i = 0; i < this.selectedProfessions.length; i++) {
              formData.append("Professions", this.selectedProfessions[i].id);
            }
          }

          if (this.allowedApprenticeships) {
            for (let i = 0; i < this.allowedApprenticeships.length; i++) {
              formData.append(
                "Allowed_apprenticeships",
                this.allowedApprenticeships[i].id
              );
            }
          }

          formData.append("name", this.name);
          formData.append("weblink", this.weblink);

          if (this.logo && this.logo.dataUrl) {
            var dataURL = this.logo.dataUrl;
            dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
            var blob = this.base64toBlob(dataURL, "image/jpeg");
            formData.append("logo", blob, "logo.jpg");
          }

          let newAddressData = {
            street: this.address.street,
            additional: this.address.additional,
            City: this.address.City.city.id,
          };

          formData.append("Address", JSON.stringify(newAddressData));

          if (!this.editorView) {
            formData.append("Contacts", JSON.stringify(this.selectedContacts));
          } else if (this.contact) {
            formData.append("Contact", JSON.stringify(this.contact));
          }


          if(this.editorView){
            if(this.user){
              formData.append("User", this.user.id)
            }
            else{
              formData.append("User", 0)
            }
          }

          // this.close(resp.data);
          if (this.companyData) {
            formData.append("id", this.companyData.id);

            axios
              .patch(
                axios.defaults.baseURL + "companies/patch/" + this.companyData.id,
                formData
              )
              .then((resp) => {
                this.loading = false;
                this.close(resp.data);
              })
              .catch((e) => {
                console.log(e);
                this.loading = false;
                window.dispatchEvent(new CustomEvent("showErrorDisplay", {detail: e}))
              });
          } else {
            axios
              .post(axios.defaults.baseURL + "companies/post/", formData)
              .then((resp) => {
                this.close(resp.data);
                this.loading = false;
              })
              .catch((e) => {
                console.log(e);
                this.loading = false;
                window.dispatchEvent(new CustomEvent("showErrorDisplay", {detail: e}))
              });
          }
        } else {
          this.$emit("error");
        }
      }, 500);
    },
    updateData() {
      if (this.companyData) {
        this.weblink =
          this.companyData.weblink &&
          this.companyData.weblink != "null" &&
          this.companyData.weblink != "undefined"
            ? this.companyData.weblink
            : undefined;
        this.logo = this.companyData.logo;
        this.selectedProfessions = this.companyData.Professions;
        this.name = this.companyData.name;

        this.allowedApprenticeships = this.companyData.Allowed_apprenticeships;

        this.addressSave.street = this.companyData.Address.street;
        this.addressSave.cityID = this.companyData.Address.City.id;
        this.addressSave.additional = this.companyData.Address.additional;

        this.address = this.companyData.Address;

        this.selectedContacts = this.companyData.contacts;
        this.user = this.companyData.User
      } else {
        this.weblink = undefined;
        this.logo = undefined;
        this.selectedProfessions = undefined;
        this.name = undefined;

        this.allowedApprenticeships = undefined;

        this.addressSave.street = undefined;
        this.addressSave.cityID = undefined;
        this.addressSave.additional = undefined;

        this.address = this.address = {
          id: undefined,
          City: {
            city: undefined,
            postcode: undefined,
            id: undefined,
          },
          street: undefined,
          additional: undefined,
        };

        this.user = undefined

        this.$refs.companyForm.resetValidation();
      }

      this.fetchData();
    },
    fetchData() {
      this.fetchProfessions().then(() => {
        this.fetchProfessions().then(() => {
          if (!this.editorView) {
            this.fetchContacts();
          }
        });
      });
    },
    fetchProfessions() {
      return new Promise((resolve, reject) => {
        axios
          .get(axios.defaults.baseURL + "professions")
          .then((resp) => {
            this.professions = resp.data;

            resolve();
          })
          .catch((e) => {
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            console.log(e);
            reject();
          });
      });
    },
    async fetchJobNames() {
      return new Promise((resolve, reject) => {
        axios
          .get(axios.defaults.baseURL + "jobnames/all/?filter=1")
          .then((resp) => {
            this.jobNames = resp.data;
            resolve();
          })
          .catch((e) => {
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            console.log(e);
            reject();
          });
      });
    },
    fetchContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get(axios.defaults.baseURL + "contactpersons/own")
          .then((resp) => {
            this.contacts = resp.data;

            resolve();
          })
          .catch((e) => {
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            console.log(e);
            reject();
          });
      });
    },
    fetchUsers() {
      return new Promise((resolve, reject) => {
        axios
          .get(axios.defaults.baseURL + "users?permission=3")
          .then((resp) => {
            this.users = resp.data;

            resolve();
          })
          .catch((e) => {
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            console.log(e);
            reject();
          });
      });
    },
  },

  mounted() {
    if (this.$store.state.permission == permissions.UNTERNEHMEN) {
      this.fetchJobNames().then(() => {
        this.updateData();
      });
    } else {
      this.fetchUsers().then(() => {
        this.fetchJobNames().then(() => {
          this.updateData();
        });
      });
    }

    try {
      document.getElementById("closeButton").focus();
    } catch (e) {
      console.log("close button not loaded...", e);
    }
  },
};
</script>
