<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        style="height: 64px !important; width: 64px"
        large
        block
        class="primaryOnWhiteButton"
        flat
        v-on="on"
        v-bind="attrs"
      >
        <v-icon size="xx-large">
          mdi-information-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card class="pa-6">
      <slot />
    </v-card>
  </v-menu>
</template>

<script>
export default {};
</script>
