<template>
  <div
    id="footerComp"
    role="contentinfo"
    class="pt-0 mt-16"
    style="
      position: relative;
      height: fit-content;
      background-color: var(--v-primary-base);
    "
  >
    <div color="accent" style="display: flex; height: fit-content">
      <v-container fluid :class="{'px-2': isMobile}">
        <v-row>
          <v-spacer v-if="!isMobile" />
          <v-col :cols="isMobile ? 12 : 8" :class="{'px-2': isMobile}">
            <v-container class="pa-0 mb-8" :class="{'px-2': isMobile}">
              <v-row class="mt-3" style="display: flex; justify-content: center">
                <v-spacer v-if="!isMobile" />
                <v-col cols="auto">
                  <a
                    @click="gotoDatenschutz()"
                    id="datenschutzLink"
                    class="focusableLink"
                    tabindex="0"
                    style="color: white"
                    >Datenschutzerklärung</a
                  >
                </v-col>
                <v-col cols="auto">
                  <a
                    @click="gotoImpressum()"
                    tabindex="0"
                    class="focusableLink"
                    style="color: white"
                    >Impressum</a
                  >
                </v-col>
                <v-col cols="auto">
                  <a
                    @click="gotoKontakt()"
                    tabindex="0"
                    class="focusableLink"
                    style="color: white"
                    >Kontakt</a
                  >
                </v-col>
                <v-col cols="auto">
                  <a
                    @click="gotoInformation()"
                    tabindex="0"
                    class="focusableLink"
                    style="color: white"
                    >Information</a
                  >
                </v-col>
                <v-spacer v-if="!isMobile" />
              </v-row>

              <v-row>
                <v-spacer />
                <v-col cols="12" style="margin: 0 auto; text-align: center">
                  <p style="color: white; text-align: center">
                    © IHK Halle-Dessau <br />
                    Für die Richtigkeit der in dieser Webseite enthaltenen Angaben können
                    wir trotz sorgfältiger Prüfung keine Gewähr übernehmen.
                    <br />
                    Für die Richtigkeit und Aktualität externer Stellenangebote sind die
                    jeweiligen Stellenbörsen verantwortlich.
                  </p>
                  <a
                    style="text-align: center"
                    target="_blank"
                    href="https://www.ihk.de/halle/"
                    >https://www.ihk.de/halle/</a
                  >
                </v-col>
                <v-spacer />
              </v-row>

              <v-row>
                <v-col>
                  <p style="color: white; text-align: center">v 2.2.0</p>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="1" style="position: relative" v-if="!isMobile">
            <v-img
              id="headerImg"
              src="../../assets/images/logo.svg"
              class="headerLink"
              style="
                cursor: pointer;
                width: 150px;
                position: absolute;
                top: -50px;
                right: 0;
              "
            ></v-img>
          </v-col>
          <v-spacer v-if="!isMobile" />
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerComp",

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    gotoDatenschutz() {
      this.$router.push("/datenschutz");
    },
    gotoImpressum() {
      this.$router.push("/impressum");
    },
    gotoKontakt() {
      this.$router.push("/kontakt");
    },
    gotoInformation() {
      this.$router.push("/information/schueler");
    },
  },
};
</script>
