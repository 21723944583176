var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{class:{ 'pb-6': _vm.isMobile }},[_c('v-col',{class:{ 'pl-0': !_vm.isMobile, 'pr-0': _vm.isMobile }},[_c('v-card',{staticClass:"pl-4 py-3",staticStyle:{"width":"100%"},attrs:{"flat":""}},[_c('span',{staticClass:"encodeFont",staticStyle:{"color":"var(--v-primary-base)"}},[_c('h2',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.jobName.name))])]),(_vm.jobName.Mapping)?_c('div',[_vm._v(_vm._s(_vm.jobName.Mapping.name))]):_vm._e()])],1),_c('v-col',{staticClass:"pr-0",class:{ 'pt-0': _vm.isMobile },attrs:{"cols":"auto"}},[_c('v-card',{staticStyle:{"height":"100%","display":"flex","align-items":"center"},attrs:{"flat":""}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({style:({
                        'background-color': hover
                          ? _vm.hoverColor
                          : 'rgba(0,0,0,0)',
                      }),attrs:{"icon":""},on:{"click":_vm.addBrafo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"xx-large","color":_vm.brafos ? 'var(--v-primary-base)' : '#9E9A9A'}},[_vm._v("mdi-earth")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Beruf einer Brafo-Lebenswelt zuordnen")])])],1),_c('v-col',{staticClass:"pa-3",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({style:({
                        'background-color': hover
                          ? _vm.hoverColor
                          : 'rgba(0,0,0,0)',
                      }),attrs:{"icon":""},on:{"click":_vm.addToJobName}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"xx-large","color":_vm.mapping ? 'var(--v-primary-base)' : '#9E9A9A'}},[_vm._v("mdi-briefcase")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Beruf einer anderen Berufsbezeichnung zuordnen")])])],1),_c('v-col',{staticClass:"pa-3",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({style:({
                        'background-color': hover
                          ? _vm.hoverColor
                          : 'rgba(0,0,0,0)',
                      }),attrs:{"icon":""},on:{"click":_vm.blockJobName}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"xx-large","color":_vm.blocked ? 'var(--v-primary-base)' : '#9E9A9A'}},[_vm._v("mdi-cancel")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Beruf sperren")])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }