<template>
  <v-container v-if="city" fluid class="pt-2 chartHover" style="border-radius: 4px !important">
    <v-row
      style="color: white;"
      class="pt-4"
    >
      <v-col class="text-center encodeFont" style="display:flex; justify-content: center; overflow-wrap: anywhere; align-items: center; height: 90px">
        <h2 v-if="$vuetify.breakpoint.smAndDown">{{ city[0][0].toUpperCase() + city[0].substring(1, 12) + (city[0].length > 12 ? '...' : '')}}</h2>
        <h2 v-else>{{ city[0][0].toUpperCase() + city[0].substring(1)}}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        style="display:flex; justify-content: center; flex-grow: 0"
        class="py-0"
      >
        <h2 style="color: white !important;" class="encodeFont">
          {{ city[1]["quarter"] }}
        </h2>
      </v-col>
    </v-row>
    <v-row
      class="pb-4"
    >
      <v-col
        cols="12"
        class="text-center"
        style="display:flex; justify-content: center;"
      >
        <div style="width:80%; color: white !important;">
          +{{ city[1]["week"] }} von dieser Woche
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["city"],
  name: "CityKPI",
};
</script>
