<template>
  <v-card
    id="resultDetail"
    tabindex="0"
    style="height: 100%"
    v-if="jobDescriptionData"
    class="pb-4"
    flat
    :ripple="false"
    :class="{ 'pa-4': !isMobile, focusable: !isMobile }"
  >
    <v-container
      class="scrollable"
      :class="{ hiddenScroll: isMobile, disableOverscroll: isMobile }"
    >
      <v-row style="background-color: black" class="pt-1" v-if="isMobile">
        <v-col
          cols="11"
          style="display: flex; flex-direction: column; justify-content: space-between"
        >
          <div class="pl-4">
            <h2 style="font-size: 1em" class="primaryText encodeFont">
              {{ jobDescriptionData.job_name }}
            </h2>
            <h1 style="font-size: 1.5em; color: white" class="encodeFont">
              {{ jobDescriptionData.title }}
            </h1>
          </div>
        </v-col>
        <v-col
          cols="1"
          style="transform: translate(0, -16px); display: flex; align-items: center"
        >
          <v-btn
            @click="closeDrawer"
            style="position: absolute; right: 0%; top: 0%"
            text
            class="mt-2 headerButton pt-6"
          >
            <v-icon style="color: white !important"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="isEditView">
        <v-col cols="6">
          <small>
            <i
              >Zuletzt geändert:
              {{ jobDescriptionData.last_updated | moment("DD.MM.YY") }} von
              {{
                jobDescriptionData.last_updated_by
                  ? jobDescriptionData.last_updated_by
                  : "-externe API-"
              }}</i
            >
          </small>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <!-- <v-tooltip bottom v-if="isEditor">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class=""
                elevation="0"
                @click="gotoCompany"
                ><v-icon style="color: var(--v-primary-base)"
                  >mdi-warehouse</v-icon
                ></v-btn
              >
            </template>
            <span>Zum Unternehmen</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class=""
                elevation="0"
                @click="showEditView"
                ><v-icon style="color: var(--v-primary-base)"
                  >mdi-pencil</v-icon
                ></v-btn
              >
            </template>
            <span>Bearbeiten</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="px-2" v-if="isMobile">
        <v-col cols="auto" class="px-1">
          <v-chip
            :style="{
              'background-color':
                jobDescriptionData.job_type == 'Praktikum'
                  ? 'var(--v-primary-base) !important'
                  : 'var(--v-accent-base) !important',
              color:
                jobDescriptionData.job_type == 'Praktikum'
                  ? 'white !important'
                  : 'black !important',
            }"
            >{{ jobDescriptionData.job_type }}</v-chip
          >
        </v-col>
        <v-spacer />
        <v-col
          cols="auto"
          class="px-1"
          style="display: flex; flex-direction: column; justify-content: space-between"
        >
          <v-chip
            >Startet am:
            {{ jobDescriptionData.job_start | moment("DD.MM.YY") }}</v-chip
          >
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="px-1">
          <v-chip v-if="jobDescriptionData.job_end"
            >Bis: {{ jobDescriptionData.job_end | moment("DD.MM.YY") }}</v-chip
          >
          <v-chip v-else>Bis: --</v-chip>
        </v-col>
      </v-row>

      <v-row v-if="!isMobile">
        <v-col
          :cols="12"
          style="display: flex; flex-direction: column; justify-content: space-between"
        >
          <div>
            <h2
              style="font-size: 1em"
              class="primaryText encodeFont"
              v-if="jobDescriptionData.job_name"
            >
              {{ jobDescriptionData.job_name }}
            </h2>
            <h2
              style="font-size: 1em"
              class="primaryText encodeFont"
              v-else-if="jobDescriptionData.Job_name"
            >
              {{ jobDescriptionData.Job_name.name }}
            </h2>
            <h1 style="font-size: 1.5em" class="encodeFont">
              {{ jobDescriptionData.title }}
            </h1>
          </div>

          <br />

          <div style="display: flex; justify-content: space-between">
            <v-chip
              :style="{
                'background-color':
                  jobDescriptionData.job_type == 'Praktikum'
                    ? 'var(--v-primary-base) !important'
                    : 'var(--v-accent-base) !important',
                color:
                  jobDescriptionData.job_type == 'Praktikum'
                    ? 'white !important'
                    : 'black !important',
              }"
              >{{ jobDescriptionData.job_type }}</v-chip
            >
            <v-chip
              >Beginn:
              {{ jobDescriptionData.job_start | moment("DD.MM.YY") }}</v-chip
            >
            <v-chip v-if="jobDescriptionData.job_end"
              >Bis:
              {{ jobDescriptionData.job_end | moment("DD.MM.YY") }}</v-chip
            >
            <v-chip v-else>Bis: --</v-chip>
          </div>
        </v-col>
      </v-row>

      <hr class="my-3" />
      
      <v-row class="pb-4">
        <v-col>
          <span
            v-if="jobDescriptionData.details"
            v-html="jobDescriptionData.details"
            class="encodeFont"
          >
          </span>
          <span v-else><i>keine Stellenbeschreibung vorhanden</i></span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0">
          <b>Arbeitsorte:</b>
        </v-col>
      </v-row>
      <v-row
        v-for="company in jobDescriptionData.Companies"
        :key="company.id"
        @click="gotoCompany(company)"
      >
        <v-col class="py-2">
          <v-container
            fluid
            style="background-color: var(--v-backgroundButLighter-base)"
            v-if="getJdByCompany(company)"
          >
            <v-row>
              <v-col style="display: flex; align-items: center">
                {{ company.name }}
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-tooltip bottom v-if="isEditor">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      elevation="0"
                      @click="gotoCompany(company)"
                      ><v-icon style="color: var(--v-primary-base) !important"
                        >mdi-warehouse</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Unternehmen anzeigen</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      elevation="0"
                      @click="showDeleteView(company)"
                      ><v-icon style="color: var(--v-primary-base) !important"
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Löschen</span>
                </v-tooltip> -->
                <v-tooltip
                  bottom
                  v-if="
                    !getJdByCompany(company).published &&
                      getJdByCompany(company).availability_end_date &&
                      new Date(
                        getJdByCompany(company).availability_end_date
                      ).toLocaleString() < new Date().toLocaleString()
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="" elevation="0">
                      <v-icon
                        style="color: gray !important"
                        v-if="
                          getJdByCompany(company).availability_end_date &&
                            new Date(
                              getJdByCompany(company).availability_end_date
                            ).toLocaleString() < new Date().toLocaleString()
                        "
                        >mdi-eye-off</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Abgelaufen </span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      class=""
                      elevation="0"
                      @click="switchVisibility(getJdByCompany(company), $event)"
                    >
                      <v-icon
                        class="pr-2"
                        v-if="!getJdByCompany(company).published"
                        style="color: var(--v-primary-base)"
                        >mdi-eye-off</v-icon
                      >
                      <v-icon v-else style="color: var(--v-primary-base)"
                        >mdi-eye</v-icon
                      ></v-btn
                    >
                  </template>
                  <span v-if="!getJdByCompany(company).published"
                    >Einblenden</span
                  >
                  <span v-else>Ausblenden</span>
                </v-tooltip>

                <span v-if="getJdByCompany(company)">
                  <v-tooltip bottom v-if="getJdByCompany(company).locked">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="width: 32px"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        >mdi-lock
                      </v-icon>
                    </template>
                    <span>
                      Stelle gesperrt und in Suche nicht sichtbar
                    </span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="getJdByCompany(company).verified">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="width: 32px"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        >mdi-check-circle
                      </v-icon>
                    </template>
                    <span>Verifiziert</span>
                  </v-tooltip>
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div v-if="jobDescriptionData.annotation">
            <h2 style="font-size: 1em" class="encodeFont pb-2">Anmerkung</h2>
            <span v-html="jobDescriptionData.annotation"> </span>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="jobDescriptionData.files.length > 0">
        <v-col class="py-0 mb-2">
          <b>Anhänge:</b>
        </v-col>
      </v-row>
      <v-row v-if="jobDescriptionData.files.length > 0">
        <v-col xs="12" sm="12" md="12" v-for="file in jobDescriptionData.files" :key="file.id">
          <JobDescriptionFileCardVue :file="file" />
        </v-col>
      </v-row>

      <v-row
        v-if="
          jobDescriptionData.external != '' &&
            jobDescriptionData.external != undefined
        "
      >
        <v-col
          ><i
            >externe Stelle von
            <a :href="getExternalLink" target="_blank">{{
              jobDescriptionData.external
            }}</a>
            . Für die Richtigkeit und Aktualität externer Stellenangebote sind
            die jeweiligen Stellenbörsen verantwortlich.
          </i>
        </v-col>
      </v-row>
    </v-container>

    <a tabindex="0" style="opacity: 0" @focus="backToSelectionList"
      >zurück zur Liste</a
    >
  </v-card>
</template>

<script>
import { permissions } from "../../util/permissions.js";
import JobDescriptionFileCardVue from "./JobDescriptionFileCard.vue"

// const axios = require("axios");

export default {
  name: "resultDetail",

  props: ["jobDescriptionData", "height", "isEditView", "editorView"],

  data() {
    return {};
  },

  components: {
    JobDescriptionFileCardVue,
  },

  watch: {
    jobDescriptionData() {
      this.$forceUpdate();

      setTimeout(() => {
        if (this.jobDescriptionData && this.jobDescriptionData.contacts) {
          for (let i = 0; i < this.jobDescriptionData.contacts.length; i++) {
            // document.getElementById(
            //   "mailLink_" + this.jobDescriptionData.contacts[i].id
            // ).innerHTML = this.jobDescriptionData.contacts[i].email;

            // document.getElementById(
            //   "phoneLink_" + this.jobDescriptionData.contacts[i].id
            // ).innerHTML = this.jobDescriptionData.contacts[i].phone;

            let jdMail = document.getElementById(
              "mailLink_" + this.jobDescriptionData.contacts[i].id
            );

            if (jdMail) {
              jdMail.innerHTML = this.jobDescriptionData.contacts[i].email;
              jdMail.setAttribute("href", "mailto:" + jdMail.innerHTML);
            }

            let jdPhone = document.getElementById(
              "phoneLink_" + this.jobDescriptionData.contacts[i].id
            );

            if (jdPhone) {
              jdPhone.innerHTML = this.jobDescriptionData.contacts[i].phone;
              jdPhone.setAttribute("href", "tel:" + jdPhone.innerHTML);
            }
          }
        } else if (
          this.jobDescriptionData &&
          this.jobDescriptionData.Company &&
          this.jobDescriptionData.Company.contacts
        ) {
          for (
            let i = 0;
            i < this.jobDescriptionData.Company.contacts.length;
            i++
          ) {
            // document.getElementById(
            //   "mailLink_" + this.jobDescriptionData.Company.contacts[i].id
            // ).innerHTML = this.jobDescriptionData.Company.contacts[i].email;

            let jdMail = document.getElementById(
              "mailLink_" + this.jobDescriptionData.Company.contacts[i].id
            );
            if (jdMail) {
              jdMail.innerHTML = this.jobDescriptionData.Company.contacts[
                i
              ].email;
              jdMail.setAttribute("href", "mailto:" + jdMail.innerHTML);
            }

            let jdPhone = document.getElementById(
              "phoneLink_" + this.jobDescriptionData.Company.contacts[i].id
            );

            if (jdPhone) {
              jdPhone.innerHTML = this.jobDescriptionData.Company.contacts[
                i
              ].phone;
              jdPhone.setAttribute("href", "tel:" + jdPhone.innerHTML);
            }
          }
        }
      }, 100);
    },
  },

  methods: {
    getJdByCompany(company) {
      if (!this.jobDescriptionData) {
        return null;
      }

      for (let i = 0; i < this.jobDescriptionData.siblings.length; i++) {
        if (this.jobDescriptionData.siblings[i].Company == company.id) {
          return this.jobDescriptionData.siblings[i];
        }
      }

      return null;
    },
    backToSelectionList(e) {
      this.$emit("resetFocus");
      e.preventDefault();
      e.stopPropagation();
    },
    gotoExternal(url) {
      if (!url.startsWith("https://")) {
        if (url.startsWith("http://")) {
          url = url.replace("http://", "https://");
        } else {
          url = "https://" + url;
        }
      }

      window.open(url, "_blank").focus();
    },
    switchVisibility(jd, event) {
      event.stopPropagation();
      event.preventDefault();

      this.$emit("hide", jd);
    },
    showEditView() {
      this.$emit("edit");
    },
    showDeleteView(company) {
      this.$emit("delete", company);
    },
    closeDrawer() {
      this.$emit("close");
    },
    gotoCompany(company) {
      if (!this.editorView) {
        return;
      }

      this.$router.push("/redakteure/unternehmensprofil/liste/" + company.name);
    },
  },

  computed: {
    isMobile() {
      if (window.innerHeight < 750) {
        return true;
      }
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    isEditor() {
      if (this.$store.state.permission <= permissions.REDAKTEUR) {
        return true;
      }
      return false;
    },
    getExternalLink() {
      let url = this.jobDescriptionData.external;
      if (
        this.jobDescriptionData.external.startsWith(
          "https://www.arbeitsagentur.de/"
        ) &&
        this.jobDescriptionData.ba_id
      ) {
        url += "suche?id=" + this.jobDescriptionData.ba_id;
        if (this.jobDescriptionData.job_type == "Praktikum") {
          url += "&angebotsart=34";
        } else if (this.jobDescriptionData.job_type == "Ausbildung") {
          url += "&angebotsart=4";
        }
      }
      return url;
    },
  },

  mounted() {},
};
</script>
