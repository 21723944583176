var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.subRoute != 'information')?_c('img',{staticStyle:{"height":"100vh","width":"100vw","position":"absolute","margin-top":"-64px","object-fit":"cover"},attrs:{"src":_vm.imagesForSubpage}}):_c('v-carousel',{staticStyle:{"margin-top":"-64px"},attrs:{"cycle":"","interval":"10000","continuous":"","show-arrows":false,"id":"mainPageSlider"},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.imagesForSubpage),function(image,i){return _c('v-carousel-item',{key:i,staticStyle:{}},[_c('v-sheet',{attrs:{"height":"100%","tile":""}},[_c('v-row',{staticClass:"fill-height",staticStyle:{"margin":"0px !important"},attrs:{"align":"center","justify":"center"}},[_c('v-img',{attrs:{"src":image.file}})],1)],1)],1)}),1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-between"},style:({ 'min-height': _vm.isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)' })},[_c('HeaderComponentVue'),_c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":_vm.mainCols}},[(!_vm.isXS)?_c('v-img',{staticStyle:{"position":"absolute","z-index":"99","cursor":"pointer"},style:({
              right: _vm.fontSize * -3+ 'em',
              top: _vm.fontSize * -3.5 + 'em',
            }),attrs:{"width":_vm.fontSize * 5 + 'em',"height":_vm.fontSize * 5 + 'em',"src":require('../../assets/images/logo.svg')},on:{"click":function($event){return _vm.gotoMain()}}}):_vm._e(),(!_vm.isXS)?_c('v-img',{staticStyle:{"position":"absolute","z-index":"99","cursor":"pointer"},style:({
              left: _vm.fontSize * -2.5 + 'em',
              bottom: _vm.fontSize * -4 + 'em',
            }),attrs:{"width":_vm.fontSize * 5 + 'em',"height":_vm.fontSize * 5 + 'em',"src":require('../../assets/images/STELLEsuchen.png')},on:{"click":function($event){return _vm.gotoMain()}}}):_vm._e(),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isXS ? '12' : 'auto'}},[_c('v-card',{staticClass:"px-4 py-3 my-1",staticStyle:{"background-color":"black"},style:({
                    width: _vm.isXS ? '100%' : 'fit-content',
                  }),on:{"click":function($event){return _vm.gotoSchueler()}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('h1',{staticStyle:{"color":"var(--v-accent-base)"},style:({ 'font-size': _vm.isMobile ? 'x-large' : 'xx-large' })},[_vm._v(" SCHÜLER*INNEN/ELTERN ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0",staticStyle:{"color":"white"}},[_vm._v(" ...probier dich in verschiedenen Berufen aus! ")])])],1)],1)],1)],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.isXS ? '12' : 'auto'}},[_c('v-card',{staticClass:"px-4 py-3 my-1",staticStyle:{"background-color":"black"},style:({
                    width: _vm.isXS ? '100%' : 'fit-content',
                  }),on:{"click":function($event){return _vm.gotoAzubis()}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('h1',{staticStyle:{"color":"var(--v-accent-base)"},style:({ 'font-size': _vm.isMobile ? 'x-large' : 'xx-large' })},[_vm._v(" AUSZUBILDENDE ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0",staticStyle:{"color":"white"}},[_vm._v(" ...hol das Maximale raus aus deiner Ausbildung! ")])])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isXS ? '12' : 'auto'}},[_c('v-card',{staticClass:"px-4 py-3 my-1",staticStyle:{"background-color":"black"},style:({
                    width: _vm.isXS ? '100%' : 'fit-content',
                  }),on:{"click":function($event){return _vm.gotoLehrer()}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('h1',{staticStyle:{"color":"var(--v-accent-base)"},style:({ 'font-size': _vm.isMobile ? 'x-large' : 'xx-large' })},[_vm._v(" LEHRER*INNEN/SCHULEN ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0",staticStyle:{"color":"white"}},[_vm._v(" ...unterstützen Sie bei der Berufsorientierung! ")])])],1)],1)],1)],1),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.isXS ? '12' : 'auto'}},[_c('v-card',{staticClass:"px-4 py-3 my-1",staticStyle:{"background-color":"black"},style:({
                    width: _vm.isXS ? '100%' : 'fit-content',
                  }),on:{"click":function($event){return _vm.gotoUnternehmen()}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('h1',{staticStyle:{"color":"var(--v-accent-base)"},style:({ 'font-size': _vm.isMobile ? 'x-large' : 'xx-large' })},[_vm._v(" UNTERNEHMEN ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0",staticStyle:{"color":"white"}},[_vm._v(" ...Finden Sie Ihren Nachwuchs! ")])])],1)],1)],1)],1)],1)],1)],1),_c('v-spacer')],1)],1),_c('v-container',{staticStyle:{"background-color":"black","z-index":"2","position":"relative"},attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.textCols}},[_c('h1',{staticClass:"text-center",staticStyle:{"color":"white"},style:({ 'font-size': _vm.isMobile ? 'x-large' : 'xx-large' })},[_vm._v(" Ansprechpersonen der gewerblichen Kammern ")])]),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.textCols}},[(_vm.subRoute == 'information')?_c('p',{staticClass:"text-center",staticStyle:{"color":"white"}},[_vm._v(" Mit Praktika noch heute die Fachkräfte von morgen gewinnen –Kontaktieren Sie die Mitarbeitenden des Projekts Bildungsketten der gewerblichen Kammern Sachsen-Anhalt! ")]):_c('p',{staticClass:"text-center",staticStyle:{"color":"white"}},[_c('a',{staticClass:"focusableLink",staticStyle:{"color":"white"},attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.gotoKontakt()}}},[_vm._v("Zu unserem Kontaktformular")])])]),_c('v-spacer')],1)],1)],1),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('RouterView',{attrs:{"id":"informationScrollAnchor","faqData":_vm.faqs}}),_c('FooterComponentVue')],1)}
var staticRenderFns = []

export { render, staticRenderFns }