<template>
  <div id="faqView" style="width: 100%" class="mb-16">
    <v-container fluid style="margin: 0 auto">
      <v-row>
        <v-col :cols="9" class="pa-0">
          <v-card flat class="mb-6">
            <v-card-title class="encodeFont"
              ><v-icon class="mr-2">mdi-chat</v-icon> FAQ</v-card-title
            >
          </v-card>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="pa-0 pl-3">
          <v-btn
            @click="showEditFaq()"
            large
            block
            elevation="0"
            class="primaryOnWhiteButton pa-0"
            style="width: 64px; height: 64px !important"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" class="pa-0">
          <v-expansion-panels flat>
            <v-expansion-panel
              v-for="faq in faqs"
              :key="faq.id"
              class="mt-3 coloredExpansionPanel"
            >
              <v-expansion-panel-header class="pa-4" @click="fetchFiles(faq)">
                <div style="font-size: 1.25rem">
                  <v-tooltip bottom 
                  v-if="faq.public">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-6"
                        color="secondary"
                        >mdi-eye</v-icon
                      >
                    </template>
                    <span>FAQ öffentlich sichtbar</span>
                  </v-tooltip>
                  <span class="mr-6">{{ faq.title }}</span>
                  <v-chip class="mr-2" v-for="c in faq.Categories" :key="c.id">
                    {{ c.key }}
                  </v-chip>
                </div>

                <template v-slot:actions>
                  <div @click="showEditFaq($event, faq)" class="mr-3">
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </div>
                  <v-icon color="primary"> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content :eager="true">
                <!-- <div class="mb-2">
                  <v-chip v-for="c in faq.Categories" :key="c.id" class="mr-2">{{
                    c.key
                  }}</v-chip>
                </div> -->
                <div v-html="faq.description"></div>
                <div v-if="faq.files.length > 0">
                  <div
                    v-for="file in faq.files"
                    :key="file.id"
                    @click="downloadFile(file)"
                  >
                    <span v-if="isGraphic(file)"
                      ><img style="max-height: 600px; max-width: 100%" :src="file.blob"
                    /></span>
                    <span v-else
                      ><v-chip>{{ file.name }}</v-chip></span
                    >
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="faqMenu" :dark="false" style="z-index: 12 !important">
      <EditFAQVue :faqData="activeFaq" @close="closeEditFaq" />
    </v-overlay>
  </div>
</template>

<script>
import EditFAQVue from "@/components/Backoffice/Editor/EditFAQ.vue";
const axios = require("axios");

export default {
  name: "faqView",

  data() {
    return {
      faqs: [],

      loading: false,
      faqMenu: 0,

      activeFaq: undefined,
    };
  },

  components: {
    EditFAQVue,
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    getFileExtension(file) {
      return file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    },
    isGraphic(file) {
      let imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];

      return imageExtensions.indexOf(this.getFileExtension(file)) >= 0;
    },
    showEditFaq(event, faq) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      this.activeFaq = faq;
      this.faqMenu++;
    },
    closeEditFaq(data) {
      if (data) {
        this.fetchData(this.activeFaq);
      }

      this.activeFaq = undefined;
      this.faqMenu = 0;
    },
    async fetchFiles(faq, forceFetch) {
      if (faq.expanded && !forceFetch) {
        return;
      }

      faq.expanded = true;

      for (let i = 0; i < faq.files.length; i++) {
        await this.getFile(faq, faq.files[i].id);
      }
    },
    async getFile(faq, fileId) {
      return new Promise((resolve) => {
        this.axios({
          method: "POST",
          url: this.axios.defaults.baseURL + "faq/files/",
          responseType: "blob", // Important! Set the response type to 'blob' for binary data
          data: {
            id: fileId,
          },
        })
          .then((resp) => {
            this.loading = false;

            for (let i = 0; i < this.faqs.length; i++) {
              if (this.faqs[i].id == faq.id) {
                for (let j = 0; j < faq.files.length; j++) {
                  if (this.faqs[i].files[j].id == fileId) {
                    this.faqs[i].files[j].blob = URL.createObjectURL(resp.data);

                    this.$forceUpdate();
                  }
                }
              }
            }

            resolve();
          })
          .catch((e) => {
            console.log(e);

            resolve();
            // this.loading = false;
            // window.dispatchEvent(new CustomEvent("showErrorDisplay", { detail: e }));
          });
      });
    },
    downloadFile(file) {
      const a = document.createElement("a");
      a.href = file.blob;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
    },
    fetchData(faq) {
      this.loading = true;

      axios
        .get(axios.defaults.baseURL + "faq")
        .then((resp) => {
          this.faqs = resp.data;
          this.loading = false;

          if (faq) {
            for (let i = 0; i < this.faqs.length; i++) {
              if (this.faqs[i].id == faq.id) {
                this.fetchFiles(this.faqs[i], true);
                break;
              }
            }
          }

          this.$forceUpdate();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>
