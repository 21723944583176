<template>
    <v-container fluid>
      <!-- <v-row>
        <v-spacer />
        <v-col :cols="contactCols">
          <v-container fluid v-if="!isMobile">
            <v-row>
              <v-col :cols="cardCols">
                <h1 class="text-center" style="color: var(--v-primary-base)">
                  Für den Norden Sachsen-Anhalts
                </h1>
              </v-col>
              <v-col :cols="cardCols">
                <h1 class="text-center" style="color: var(--v-primary-base)">
                  Für den Süden Sachsen-Anhalts
                </h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col :cols="cardCols">
                <ContactCardVue :contactData="contacts.hwkMagdeburg" />
              </v-col>
              <v-col :cols="cardCols">
                <ContactCardVue :contactData="contacts.hwkHalle" />
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="cardCols">
                <ContactCardVue :contactData="contacts.ihkMagdeburg" />
              </v-col>
              <v-col :cols="cardCols">
                <ContactCardVue :contactData="contacts.ihkHalle" />
              </v-col>
            </v-row>
          </v-container>

          <v-container fluid v-else>
            <v-row>
              <v-spacer />
              <v-col :cols="cardCols">
                <h1 class="text-center" style="color: var(--v-primary-base)">
                  Für den Norden Sachsen-Anhalts
                </h1>
              </v-col>
              <v-spacer />
            </v-row>

            <v-row>
              <v-col :cols="cardCols">
                <ContactCardVue :contactData="contacts.hwkMagdeburg" />
              </v-col>
              <v-col :cols="cardCols">
                <ContactCardVue :contactData="contacts.ihkMagdeburg" />
              </v-col>
            </v-row>

            <v-row class="mt-16">
              <v-spacer />
              <v-col :cols="cardCols">
                <h1 class="text-center" style="color: var(--v-primary-base)">
                  Für den Süden Sachsen-Anhalts
                </h1>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-col :cols="cardCols">
                <ContactCardVue :contactData="contacts.hwkHalle" />
              </v-col>
              <v-col :cols="cardCols">
                <ContactCardVue :contactData="contacts.ihkHalle" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-spacer />
      </v-row> -->
    </v-container>
</template>

<script>
// import ContactCardVue from "../../components/hallo-praktikum/ContactCard.vue";

export default {
  name: "halloPraktikumMainPage",

  data() {
    return {

      contacts: {
        hwkMagdeburg: {
          logo: require("../../assets/images/HWK_Halle_4C.svg"),
          contacts: [
            {
              name: "Nancy Gruß",
              phone: "0391 6268-210",
              mail: "ngruss@hwk-magdeburg.de",
            },
            {
              name: "Manuela Quiel",
              phone: "0391 6268-220",
              mail: "mquil@hwk-magdeburg.de",
            },
          ],
        },
        hwkHalle: {
          logo: require("../../assets/images/logo3.png"),
          contacts: [
            {
              name: "Sylvia Pogert",
              phone: "0345 2999-332",
              mail: "spogert@hwkhalle.de",
            },
            {
              name: "Ralph Niederlomann",
              phone: "0345 2999-187",
              mail: "rniederlohmann@hwkhalle.de",
            },
          ],
        },
        ihkMagdeburg: {
          logo: require("../../assets/images/logo1.png"),
          contacts: [
            {
              name: "Anne Neitzel",
              phone: "0391 5693-151",
              mail: "anne.neitzel@magdeburg.ihk.de",
            },
            {
              name: "Marie-Isabel Brämer",
              phone: "0391 5693-151",
              mail: "marie-isabel.braemer@magdeburg.ihk.de",
            },
          ],
        },
        ihkHalle: {
          logo: require("../../assets/images/logo2.png"),
          contacts: [
            {
              name: "Jonas Meyer",
              phone: "0345 2126-326",
              mail: "jmeyer@halle.ihk.de",
            },
            {
              name: "Solveig Schreiner",
              phone: "0345 2126-328",
              mail: "sschreiner@halle.ihk.de",
            },
          ],
        },
      },
    };
  },

  components: {
    // ContactCardVue,
  },

  computed: {
    fontSize() {
      if (window.innerHeight < 750) {
        return 1.5;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1.5;
        case "sm":
          return 2;
        case "md":
          return 2;
        case "lg":
          return 2.5;
        case "xl":
          return 2.5;
        default:
          return 3;
      }
    },
    mainCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 10;
        case "sm":
          return 9;
        case "md":
          return 8;
        case "lg":
          return 6;
        case "xl":
          return 4;
        default:
          return 6;
      }
    },
    contactCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 11;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 8;
        default:
          return 6;
      }
    },
    cardCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        default:
          return false;
      }
    },
    isXS() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        default:
          return false;
      }
    },
    textCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 11;
        case "md":
          return 10;
        case "lg":
          return 8;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  methods: {
  },

  mounted() {
  },
};
</script>
