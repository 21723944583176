<template>
  <div>
    <v-card color="var(--v-background-base)" flat :href="file.file ? (file.file.startsWith('http') ? file.file : axios.defaults.url + file.file) : axios.defaults.url + file.path" target="_blank" class="pa-0 fileCard">
      <v-container fluid class="py-0 mb-3">
        <v-row align="center" justify="center">
          <v-col class="pb-0" cols="auto">
            <v-icon size="xxx-large" color="primary">mdi-file-document</v-icon>
          </v-col>
          <v-col>{{ fileExtension.toUpperCase() }}-Datei</v-col>
        </v-row>
        <v-row>
          <v-col class="px-5">
            {{ fileName }}
          </v-col>
        </v-row>
        <v-row>
          
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["file"],

  data() {
    return {};
  },

  computed: {
    fileName(){
        let filename_split = this.file.name.split(".")
        let temp = ""
        for(let i = 0; i < filename_split.length - 1; i++){
            
            temp += filename_split[i]
            if(i != filename_split.length - 2){
                temp += "."
            }
        }
        return temp
    },
    fileExtension(){
        let filename_split = this.file.name.split(".")
        return filename_split[filename_split.length - 1]
    }
  }
};
</script>
