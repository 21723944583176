var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"jobSelector"}},[_c('v-container',{staticStyle:{"align-content":"center"},attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"padding":"6px !important","padding-bottom":"12px !important"},attrs:{"cols":"12"}},[_c('v-card',{staticClass:"darkCard",class:{
            'pa-4': !_vm.smallScreen,
            'pa-2': _vm.smallScreen,
            'mt-12': !_vm.smallScreen,
            'mt-6': _vm.smallScreen,
          }},[_c('v-img',{staticClass:"mainPageImageOffset",attrs:{"width":_vm.fontSize * 5 + 'em',"height":_vm.fontSize * 5 + 'em',"src":require('../../assets/images/logo.svg')}}),_c('h1',{staticClass:"mb-4 pr-2",staticStyle:{"color":"white","text-align":"right"},style:({ 'font-size': _vm.fontSize + 'em' })},[_vm._v(" Wonach"),_c('br'),_vm._v(" suchst du? ")]),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('div',{staticClass:"customCheckbox mb-4",attrs:{"disabled":_vm.disabled},on:{"click":_vm.switchAusbildungEnabled}},[_c('input',{attrs:{"disabled":_vm.disabled,"type":"checkbox","id":"ausbildungCheckbox","aria-label":"Ausbildung suchen auswählen"}}),_c('p',{style:({
                  'font-size': _vm.fontSize * 3 + 'em',
                  opacity: _vm.ausbildungChecked ? 1 : 0,
                })},[_vm._v(" X ")]),_c('v-icon',{style:({ 'font-size': _vm.fontSize * 1.5 + 'em' }),attrs:{"color":"accent"}},[_vm._v("mdi-checkbox-blank-outline")]),_c('h1',{staticClass:"ml-4 pr-2",staticStyle:{"text-align":"right"},style:({ 'font-size': _vm.fontSize + 'em' }),attrs:{"id":"ausbildungHeader","disabled":_vm.disabled},on:{"focus":function($event){return _vm.focusAusbildung()}}},[_vm._v(" Ausbildung ")])],1)]),_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('div',{staticClass:"customCheckbox",attrs:{"disabled":_vm.disabled},on:{"click":_vm.switchPraktikumEnabled}},[_c('input',{attrs:{"disabled":_vm.disabled,"type":"checkbox","id":"praktikumCheckbox","aria-label":"Praktikum suchen auswählen"}}),_c('p',{style:({
                  'font-size': _vm.fontSize * 3 + 'em',
                  opacity: _vm.praktikumChecked ? 1 : 0,
                })},[_vm._v(" X ")]),_c('v-icon',{style:({ 'font-size': _vm.fontSize * 1.5 + 'em' }),attrs:{"color":"accent"}},[_vm._v("mdi-checkbox-blank-outline")]),_c('h1',{staticClass:"ml-4 pr-2",style:({ 'font-size': _vm.fontSize + 'em' }),attrs:{"disabled":_vm.disabled,"id":"praktikumHeader"}},[_vm._v(" Praktikum ")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }