<template>
  <v-container
    fluid
    class="pa-0"
    style="overflow-y: auto; display: flex; flex-direction: column; height: 100%; overflow-x: hidden"
  >
    <div
      class="py-1 chartHeader"
      style="min-height: 80px; position:sticky; top: 0px; z-index: 2"
    >
      <h2 class="encodeFont pl-2" style="color: white !important">
        {{ label }}
      </h2>
    </div>

    <v-row class="pt-12" style="color: #909090">
      <v-spacer></v-spacer>

      <v-col cols="5" class="px-8">
        <strong>Praktikum</strong>
      </v-col>

      <v-col cols="5" class="px-8 text-right">
        <strong>Ausbildung</strong>
      </v-col>
      
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mb-4">
      <v-spacer></v-spacer>
      <v-col cols="10" class="px-8 pt-0 pb-0">
        <v-progress-linear
          value="50"
          height="16"
          style="border-radius: 12px"
          color="primary"
          background-color="accent"
        >
        </v-progress-linear>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    
    <v-row v-if="externals" class="ma-0" style="align-items: flex-end">
      <v-col
        :cols="mainCols"
        v-for="(api, index) in apiNames"
        :key="index"
        class="pb-0"
      >
        <ExternalStat
          :icon="externals[api]['logo_name']"
          :stelle="api"
          :num="externals[api]"
          :total="externals['Insgesamt']"
          :dashboard="dashboard"
        />
        <!-- <ExternalStat
          :icon="'Agentur für Arbeit'"
          :stelle="'Agentur für Arbeit'"
          :num="externals['external']['Agentur für Arbeit']"
          :total="externals['Insgesamt']"
          :dashboard="dashboard"
        /> -->
      </v-col>

      <!-- <v-col :cols="mainCols" class="py-0">
        <ExternalStat
          v-if="!$vuetify.breakpoint.lg"
          :icon="'Handwerkskammer Halle'"
          :stelle="'Hand&shy;werks&shy;kammer<br>Halle'"
          :num="externals['external']['Handwerkskammer Halle']"
          :total="externals['Insgesamt']"
          :dashboard="dashboard"
        />
      </v-col> -->

      <!-- &shy; is a soft hyphen, word breaks at the soft hyphen if it overflows
        <ExternalStat
          v-else
          :icon="'Handwerkskammer Halle'"
          :stelle="'Hand&shy;werks&shy;kammer<br>Halle'"
          :num="externals['external']['Handwerkskammer Halle']"
          :total="externals['Insgesamt']"
          :dashboard="dashboard"
        />
      </v-col>

      <v-col :cols="mainCols" class="py-0">
        <ExternalStat
          :icon="'Beruf + Zukunft'"
          :stelle="'Beruf + Zukunft'"
          :num="externals['external']['Beruf + Zukunft']"
          :total="externals['Insgesamt']"
          :dashboard="dashboard"
        />
      </v-col>

      <v-col :cols="mainCols" class="py-0">
        <ExternalStat
          :icon="'Hallo-Beruf'"
          :stelle="'Hallo-Beruf'"
          :num="externals['Hallo-Beruf']"
          :total="externals['Insgesamt']"
          :dashboard="dashboard"
        />
      </v-col> -->

      <v-col :cols="mainCols * 2" class="py-0">
        <ExternalStat
          :icon="'Insgesamt_logo.svg'"
          :stelle="'Insgesamt'"
          :num="externals['Insgesamt']"
          :total="externals['Insgesamt']"
          :dashboard="dashboard"
        />
      </v-col>
    </v-row>
    <div
      v-else
      style="display: flex; flex-grow: 1; justify-content: center; align-items: center"
    >
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import ExternalStat from "./ExternalStat.vue";

export default {
  name: "ExternalChart",
  props: ["externals", "dashboard", "label"],

  data() {
    return {};
  },
  components: {
    ExternalStat,
  },

  computed: {
    mainCols() {
      if (this.dashboard) {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 6;
          case "sm":
            return 6;
          case "md":
            return 6;
          default:
            return 12;
        }
      }
      return 12;
    },
    apiNames() {
      let apiNames = [];
      for (let api of Object.keys(this.externals)) {
        if (api != "Insgesamt") {
          apiNames.push(api);
        }
      }
      return apiNames;
    },
  },
};
</script>
