<template>
  <v-container fluid>
    <v-row :class="{ 'pb-6': isMobile }">
      <v-col :class="{ 'pl-0': !isMobile, 'pr-0': isMobile }">
        <v-card flat style="width: 100%" class="pl-4 py-3">
          <span style="color: var(--v-primary-base)" class="encodeFont">
            <h2 style="font-weight: 500">{{ jobName.name }}</h2>
          </span>
          <div v-if="jobName.Mapping">{{ jobName.Mapping.name }}</div>
        </v-card>
      </v-col>
      <v-col cols="auto" class="pr-0" :class="{ 'pt-0': isMobile }">
        <v-card style="height: 100%; display: flex; align-items: center" flat>
          <v-container>
            <v-row>
              <v-col cols="auto" class="pa-3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :style="{
                          'background-color': hover
                            ? hoverColor
                            : 'rgba(0,0,0,0)',
                        }"
                        icon
                        @click="addBrafo"
                      >
                        <v-icon
                          size="xx-large"
                          :color="brafos ? 'var(--v-primary-base)' : '#9E9A9A'"
                          >mdi-earth</v-icon
                        >
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Beruf einer Brafo-Lebenswelt zuordnen</span>
                </v-tooltip>
              </v-col>

              <v-col cols="auto" class="pa-3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :style="{
                          'background-color': hover
                            ? hoverColor
                            : 'rgba(0,0,0,0)',
                        }"
                        @click="addToJobName"
                      >
                        <v-icon
                          size="xx-large"
                          :color="mapping ? 'var(--v-primary-base)' : '#9E9A9A'"
                          >mdi-briefcase</v-icon
                        >
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Beruf einer anderen Berufsbezeichnung zuordnen</span>
                </v-tooltip>
              </v-col>

              <v-col cols="auto" class="pa-3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        :style="{
                          'background-color': hover
                            ? hoverColor
                            : 'rgba(0,0,0,0)',
                        }"
                        icon
                        @click="blockJobName"
                      >
                        <v-icon
                          size="xx-large"
                          :color="blocked ? 'var(--v-primary-base)' : '#9E9A9A'"
                          >mdi-cancel</v-icon
                        >
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Beruf sperren</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "JobNameMapper",
  props: ["jobName"],

  data() {
    return {
      blocked: undefined,
      mapping: undefined,
      brafos: false,

      hoverColor: "rgba(0,154,147,0.25)",
    };
  },

  computed: {
    mainCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 10;
        case "lg":
          return 10;
        case "xl":
          return 10;
        default:
          return 10;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    addToJobName() {
      this.$emit("addJob", this.jobName);
    },
    addBrafo() {
      this.$emit("addBrafo", this.jobName);
    },
    blockJobName() {
      this.blocked = !this.blocked;
      this.$emit("blockJobName", this.jobName);
    },
  },
  mounted() {
    this.blocked = this.jobName.blocked;

    if (this.jobName.Mapping) this.mapping = true;
    else this.mapping = false;

    if (this.jobName.Brafo.length > 0) this.brafos = true;
    else this.brafos = false;
  },
};
</script>
