<template>
  <div id="imageView" style="width: 100%" class="mb-16">
    <v-container fluid style="margin: 0 auto">
      <v-row>
        <v-col :cols="9" class="pa-0">
          <v-card flat class="mb-6">
            <v-card-title class="encodeFont"
              ><v-icon class="mr-2">mdi-image-multiple</v-icon>
              Bildverwaltung</v-card-title
            >
          </v-card>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="pa-0 pl-3">
          <v-btn
            @click="showEditImage()"
            large
            block
            elevation="0"
            class="primaryOnWhiteButton pa-0"
            style="width: 64px; height: 64px !important"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col
          :cols="imageCols"
          class="pa-0"
          v-for="(image, index) in images"
          :key="image.id"
          :class="{
            'pr-2': index % 2 == 0 && !isMobile ? true : false,
            'pl-2': index % 2 != 0 && !isMobile ? true : false,
            'pr-0': index % 2 != 0 && !isMobile ? true : false,
            'pl-0': index % 2 == 0 && !isMobile ? true : false,
            'px-0': isMobile,
          }"
        >
          <ImagePreviewCardVue
          class="mb-8"
            @showDeletionWarning="showDeletionWarning"
            @switchVisability="switchVisability"
            :imageData="image"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="imageMenu" :dark="false" style="z-index: 12 !important">
      <EditImagesVue :imageData="activeImage" @close="closeEditImage" />
    </v-overlay>

    <v-overlay :value="deletionWarning" :dark="false">
      <DeletionWarningVue
        v-if="activeImage"
        :item="'dieses Hintergrundbild'"
        @cancel="hideDeletionWarning"
        @confirm="deleteImage"
      />
    </v-overlay>
  </div>
</template>

<script>
import EditImagesVue from "@/components/Backoffice/Editor/EditImage.vue";
import ImagePreviewCardVue from "@/components/Backoffice/Editor/ImagePreviewCard.vue";
import DeletionWarningVue from "../../components/Main/DeletionWarning.vue";

const axios = require("axios");

export default {
  name: "imageView",

  data() {
    return {
      images: [],

      loading: false,
      imageMenu: 0,

      activeImage: undefined,
      deletionWarning: false,
    };
  },

  components: {
    EditImagesVue,
    ImagePreviewCardVue,
    DeletionWarningVue,
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    imageCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  methods: {
    getFileExtension(file) {
      return file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    },
    isGraphic(file) {
      let imageExtensions = ["jpg", "jpeg", "png"];

      return imageExtensions.indexOf(this.getFileExtension(file)) >= 0;
    },
    showEditImage(event, image) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      this.activeImage = image;
      this.imageMenu++;
    },
    closeEditImage(data) {
      this.activeImage = undefined;
      this.imageMenu = 0;

      if (data) {
        this.fetchData();
      }
    },
    fetchData() {
      this.loading = true;

      axios
        .get(axios.defaults.baseURL + "backgroundimages")
        .then((resp) => {
          this.images = resp.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    switchVisability(imageData) {
      this.loading = true;
      axios
        .patch(axios.defaults.baseURL + "backgroundimages/patch/" + imageData.id, {
          id: imageData.id,
          visible: !imageData.visible,
        })
        .then(() => {
          imageData.visible = !imageData.visible;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    showDeletionWarning(imageData) {
      this.activeImage = imageData;
      this.deletionWarning = true;
    },
    hideDeletionWarning() {
      this.deletionWarning = false;
      this.activeImage = undefined;
    },
    deleteImage() {
      this.loading = true;

      axios
        .patch(axios.defaults.baseURL + "backgroundimages/patch/" + this.activeImage.id, {
          id: this.activeImage.id,
          deleted: true,
        })
        .then(() => {
          this.hideDeletionWarning();

          this.fetchData();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>
