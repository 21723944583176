<template>
  <div style="height: inherit" class="mb-4">
    <v-container v-if="company" :class="{ scrollable: !isMobile }" fluid>
      <v-row>
        <v-col cols="12" class="pt-0">
          <h2
            class="encodeFont"
            style="display: flex; align-items: center; justify-content: space-between"
          >
            Filialeninformation
            <div style="float: right">
              <v-tooltip bottom v-if="editorMode">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="primaryOnWhiteButton"
                    elevation="0"
                    @click="gotoJDByCompany(company.name)"
                    ><v-icon
                      style="
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                      "
                      class="pr-2"
                      >mdi-text-box-outline</v-icon
                    ></v-btn
                  >
                </template>
                <span>Alle Stellenanzeigen dieses Unternehmens anzeigen</span>
              </v-tooltip>

              <v-tooltip bottom v-if="editorMode">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="primaryOnWhiteButton"
                    elevation="0"
                    @click="blacklistCompany"
                    ><v-icon
                      style="
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                      "
                      class="pr-2"
                      >mdi-lock</v-icon
                    ></v-btn
                  >
                </template>
                <span>Sperren (Stellenanzeigen nicht mehr in Suche)</span>
              </v-tooltip>

              <v-tooltip bottom v-if="editorMode">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    @click="editCompany"
                    ><v-icon
                      style="
                        color: var(--v-primary-base);
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                      "
                      class="pr-2"
                      >mdi-pencil</v-icon
                    ></v-btn
                  >
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
            </div>
          </h2>
        </v-col>
      </v-row>

      <v-row v-if="company.locked">
        <v-col cols="12" style="color: red">
          <b
            >Ihr Unternehmen ist gesperrt. Ihre Stellenanzeigen werden nicht in
            der Suche angezeigt.</b
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="leftCols" class="pr-8">
          <v-container fluid class="pa-0">
            <v-row>
              <v-col class="pb-0"> Name: </v-col>
            </v-row>
            <v-row class="inputFaker ml-0">
              <v-col class="py-1 pl-4">
                <b>{{ company.name }}</b>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0"> Straße & Hausnr.: </v-col>
            </v-row>
            <v-row class="inputFaker ml-0">
              <v-col class="py-1 pl-4">
                <b>{{ company.Address.street }}</b>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0"> PLZ & Ort: </v-col>
            </v-row>
            <v-row class="inputFaker ml-0">
              <v-col class="py-1 pl-4">
                <span v-if="company.Address.City.postcode.postcode">
                  {{
                    company.Address.City.postcode.postcode +
                      " " +
                      company.Address.City.city
                  }}
                </span>
                <span v-else>
                  <b>{{
                    company.Address.City.postcode +
                      " " +
                      company.Address.City.city
                  }}</b>
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0"> Adresszusatz: </v-col>
            </v-row>
            <v-row class="inputFaker ml-0">
              <v-col class="py-1 pl-4">
                <b v-if="company.Address.additional">{{
                  company.Address.additional
                }}</b
                ><b v-else> --</b></v-col
              >
            </v-row>
            <v-row>
              <v-col class="pb-0"> Kontaktpersonen: </v-col>
            </v-row>
            <v-row class="inputFaker ml-0" style="min-height: 32px">
              <v-col class="py-1 pl-4" cols="auto">
                <span v-for="(c, contactIndex) in company.contacts" :key="c.id">
                  <b
                    ><span v-if="c.first_name">{{ c.first_name }} </span>
                    <span v-if="c.last_name">{{ c.last_name }}</span></b
                  >
                  <span v-if="contactIndex != company.contacts.length - 1"
                    >,</span
                  >
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col :cols="rightCols" style="display: flex" class="pr-0 pt-9 pb-0">
          <v-container
            fluid
            class="pa-2"
            fillHeight
            style="background-color: var(--v-backgroundButLighter-base)"
          >
            <v-row style="height: 100%; margin: 0px">
              <v-spacer />
              <v-col style="height: 100%; display: flex; align-items: center">
                <v-img
                  v-if="company.logo"
                  class="imageContain companyDetailLogo"
                  :src="getImgSrc(company.logo)"
                  alt="Firmenlogo"
                ></v-img>
                <v-img
                  style="height: 150px"
                  v-else
                  class="imageCover"
                  alt="Platzhalter für Firmenlogo"
                  src="../../../assets/images/noImg.svg"
                ></v-img>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0"> Webseite: </v-col>
      </v-row>
      <v-row class="inputFaker ml-0">
        <v-col class="py-1 pl-4">
          <b
            v-if="
              company.weblink &&
                company.weblink != 'null' &&
                company.weblink != 'undefined'
            "
          >
            <a
              tabindex="0"
              @click="gotoExternal(company.weblink)"
              @keyup.enter="gotoExternal(company.weblink)"
            >
              {{ company.weblink }}</a
            >
          </b>
          <b v-else>--</b>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col class="pb-0"> Branchen/n: </v-col>
      </v-row>
      <v-row class="inputFaker ml-0">
        <v-col class="py-1 pl-4">
          <span v-for="(b, index) in company.Professions" :key="b.id + 'id'">
            <span v-if="index < company.Professions.length - 1"
              ><b>{{ b.name }}</b
              >,</span
            >
            <span v-else
              ><b>{{ b.name }}</b></span
            >
          </span>

          <span v-if="company.Professions.length == 0">
            <i>Keine Branchen eingetragen</i>
          </span>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="auto"> Ausbildungsberufe: </v-col>
        <v-col
          ><v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                tabindex="0"
                v-bind="attrs"
                v-on="on"
                style="margin-top: -3px"
                color="primary"
                >mdi-information-outline
              </v-icon>
            </template>
            <span
              >Sie dürfen nur in den hier gelisteten Berufen Ausbildungen auf
              unserer Plattform anbieten. Ihre Stellenanzeigen für Ausbildungen
              werden dafür als verifiziert markiert. Kontaktieren Sie uns, um
              weitere Berufe hinzuzufügen.</span
            >
          </v-tooltip></v-col
        >
      </v-row>
      <v-row class="inputFaker ml-0">
        <v-col class="py-1 pl-4">
          <span
            v-for="(ai, index) in company.Allowed_apprenticeships"
            :key="index"
            ><b
              >{{ ai.name }}
              <span v-if="index != company.Allowed_apprenticeships.length - 1"
                >, </span
              ></b
            ></span
          >
          <span v-if="company.Allowed_apprenticeships.length == 0">
            <i>Keine Ausbildungsberufe eingetragen</i>
          </span>
        </v-col>
      </v-row>

      <v-row v-if="editorMode">
        <v-col
          cols="12"
          class="pb-0"
          style="display: flex; justify-content: space-between"
        >
          <h2 class="encodeFont">Kontaktpersonen</h2>
          <v-tooltip bottom v-if="!isEditor">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="company.external"
                icon
                @click="showEditContact()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  style="font-size: xx-large"
                  :style="{
                    color: company.external
                      ? 'gray'
                      : 'var(--v-primary-base) !important',
                  }"
                  >mdi-plus</v-icon
                ></v-btn
              >
            </template>
            <span>Neue Kontaktperson anlegen</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" class="py-1 pl-4">
          <v-card
            :id="'contactPersonCard_' + c.id"
            v-for="c in company.contacts"
            :key="c.id + 'c'"
            elevation="0"
            class="pa-4 mb-4 lightOutline"
          >
            {{
              c.role == "undefined" || c.role == ""
                ? "Keine Rolle eingetragen"
                : c.role
            }}
            <h2 class="encodeFont">
              {{
                (c.first_name == undefined || c.first_name == "undefined"
                  ? ""
                  : c.first_name) +
                  " " +
                  c.last_name
              }}
            </h2>
            <br />

            <v-icon class="pr-2">mdi-phone</v-icon
            ><a :href="'tel:' + c.phone">{{ c.phone }}</a
            ><br />
            <v-icon class="pr-2">mdi-email</v-icon
            ><a :href="'mailto:' + c.email">{{ c.email }}</a>
            <div class="cardEditButtons">
              <span v-if="!company.external">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :disabled="isEditor"
                      @click="showDeleteContact(c)"
                    >
                      <v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                  <span>Löschen</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!isEditor">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="showEditContact(c)"
                    >
                      <v-icon>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>
              </span>

              <span v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon style="color: gray !important"
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Löschen (deaktiviert für externe Unternehmen)</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!isEditor">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon style="color: gray !important"
                        >mdi-pencil</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Bearbeiten (deaktiviert für externe Unternehmen)</span>
                </v-tooltip>
              </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <a
      tabindex="0"
      style="opacity: 0; position: absolute; pointer-events: none"
      @focus="backToSelectionList"
      >zurück zur Liste</a
    >
  </div>
</template>

<script>
import { permissions } from "../../../util/permissions.js";
const axios = require("axios");

export default {
  name: "companyDetail",

  props: ["company", "editorMode"],

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    leftCols() {
      if (this.isMobile) {
        return 12;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 7;
        case "xl":
          return 9;
        default:
          return 8;
      }
    },
    rightCols() {
      if (this.isMobile) {
        return 12;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 5;
        case "xl":
          return 3;
        default:
          return 4;
      }
    },
    isEditor() {
      if (this.$store.state.permission <= permissions.REDAKTEUR) {
        return true;
      }
      return false;
    },
  },

  methods: {
    backToSelectionList(e) {
      this.$emit("resetFocus");
      e.preventDefault();
      e.stopPropagation();
    },
    showEditContact(contact) {
      this.$emit("editContact", contact);
    },
    showDeleteContact(contact) {
      this.$emit("deleteContact", contact);
    },

    gotoExternal(url) {
      if (!url.startsWith("https://")) {
        if (url.startsWith("http://")) {
          url = url.replace("http://", "https://");
        } else {
          url = "https://" + url;
        }
      }

      window.open(url, "_blank").focus();
    },
    editCompany() {
      this.$emit("edit");
    },
    blacklistCompany() {
      this.$emit("blacklist");
    },
    getImgSrc(url) {
      if (url.startsWith("https://")) {
        return url;
      } else {
        return axios.defaults.url + url;
      }
    },
    gotoJDByCompany(companyName) {
      this.$router.push("/redakteure/stellenangebote/" + companyName);
    },
  },
};
</script>
