<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="6" class="pl-0">
        <v-card flat>
          <v-card-title class="encodeFont pa-0 pb-1">
            <v-text-field
              underlined
              hide-details
              class="hideInputMessage px-4"
              append-icon="mdi-magnify"
              label="Berufsbezeichnung eingeben"
              v-model="query"
            >
            </v-text-field>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="auto" class="py-3">
        <MenuVue @resetFilter="resetFilter">
          <v-container>
            <v-row align="center" @click="toggleBrafoFilter">
              <v-col
                cols="auto"
                style="border-radius: 50%"
                :style="{
                  'background-color': filter.brafo
                    ? 'var(--v-accent2-lighten5)'
                    : 'transparent',
                }"
              >
                <v-icon
                  class="pa-0"
                  size="xx-large"
                  :color="filter.brafo == 1 ? 'primary' : ''"
                  >mdi-earth</v-icon
                >
              </v-col>
              <v-col cols="auto">BRAFO</v-col>
            </v-row>
            <v-row align="center" @click="toggleMappingFilter">
              <v-col
                cols="auto"
                style="border-radius: 50%"
                :style="{
                  'background-color': filter.mapping
                    ? 'var(--v-accent2-lighten5)'
                    : 'transparent',
                }"
              >
                <v-icon
                  class="pa-0"
                  size="xx-large"
                  :color="filter.mapping == 1 ? 'primary' : ''"
                  >mdi-briefcase</v-icon
                >
              </v-col>
              <v-col>vorhandener Beruf</v-col>
            </v-row>
            <v-row align="center" @click="toggleBlockedFilter">
              <v-col
                cols="auto"
                style="border-radius: 50%"
                :style="{
                  'background-color': filter.blocked
                    ? 'var(--v-accent2-lighten5)'
                    : 'transparent',
                }"
              >
                <v-icon
                  class="pa-0"
                  size="xx-large"
                  :color="filter.blocked == 1 ? 'primary' : ''"
                  >mdi-cancel</v-icon
                >
              </v-col>
              <v-col>gesperrt</v-col>
            </v-row>
            <v-row align="center" @click="toggleTodoFilter">
              <v-col
                cols="auto"
                style="border-radius: 50%"
                :style="{
                  'background-color': filter.todo
                    ? 'var(--v-accent2-lighten5)'
                    : 'transparent',
                }"
              >
                <v-icon
                  class="pa-0"
                  size="xx-large"
                  :color="filter.todo == 1 ? 'primary' : ''"
                  >mdi-dots-square</v-icon
                >
              </v-col>
              <v-col>zu bearbeiten</v-col>
            </v-row>
          </v-container>
        </MenuVue>
      </v-col>

      <v-col cols="auto" class="py-3">
        <InfoMenuVue>
          <v-container fluid style="display:flex;flex-direction: column">
            <v-row>
              <v-col class="py-2">
                Um Stellenanzeigen in der Suche korrekt darzustellen, müssen sie
                dem System "beigebracht" werden.
              </v-col>
            </v-row>
            <v-row>
              <v-col style="display:flex; align-items: center" class="py-2" cols="auto">
                <v-icon color="primary">mdi-earth </v-icon>
              </v-col>
              <v-col class="py-2">
                <span>
                  Zuordnung zu BRAFO Welten. Diese Berufe sind offiziell im
                  System, also für Unternehmen und in der Suche sichtbar.
                  Beispiel: Fachinformatiker/ -in, BRAFO Welt 10. Alle Berufe,
                  die von Jugendlichen über die Suche verfügbar sein sollen,
                  benötigen eine BRAFO Zuordnung, außer sie enthalten falsche
                  Genderformen. In diesen Fällen wird stattdessen
                  <v-icon color="rgb(170,170,170)" size="medium" style="margin-right: -2px; margin-top: -1px">mdi-briefcase</v-icon> - Berufszuweisung
                  verwendet
                </span>
              </v-col>
            </v-row>
            <v-divider class="my-4" />
            <v-row>
              <v-col style="display:flex; align-items: center" class="py-2" cols="auto">
                <v-icon color="primary">mdi-briefcase </v-icon>
              </v-col>
              <v-col class="py-2">
                Diese Berufe sollen (z.B. auf Grund falscher Genderformen) nicht
                im System, also weder in der Suche noch für Unternehmen,
                angezeigt werden. Die entsprechenden externen Stellenanzeigen
                sollen jedoch trotzdem in die Suche übernommen werden. Beispiel:
                Fachinformatiker/ Fachinformatikerin. Stattdessen soll dem Nutzer
                der zugewiesene Name angezeigt werden, z.B. Fachinformatiker/
                -in
              </v-col>
            </v-row>
            <v-divider class="my-4"/>
            <v-row>
              <v-col style="display:flex; align-items: center" class="py-2" cols="auto">
                <v-icon color="primary">mdi-cancel </v-icon>
              </v-col>
              <v-col class="py-2">
                Es gibt Berufe, die von externen Stellenbörsen hinzukamen, die
                entweder nicht für Jugendliche gedacht sind, z.B Gehirnchirurg,
                oder die auf Grund von Datenqualität falsch und nicht zuweisbar
                sind, z.B ein Unternehmensname statt einem tatsächlichen Beruf.
                Diese Berufe können hier gesperrt werden. Sie sind nicht im
                System sichtbar und Stellenanzeigen mit diesen Berufen werden
                generell nicht in der Suche angezeigt, auch nicht unter anderem
                Namen.
              </v-col>
            </v-row>
          </v-container>
        </InfoMenuVue>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="pa-0 py-3 pt-4">
        <v-btn
          depressed
          class="primaryOnWhiteButton"
          @click="showNewJobOverlay"
          large
          block
          style="width: 64px"
        >
          <v-icon size="xx-large"> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InfoMenuVue from '@/components/Main/InfoMenu.vue';
import MenuVue from "../../../Main/FilterMenu.vue";

export default {
  name: "JobNameFilter",

  data() {
    return {
      filter: {
        brafo: 0,
        mapping: 0,
        blocked: 0,
        todo: 1,
      },
      query: "",
    };
  },

  watch: {
    query(newVal, oldVal) {
      if (newVal.length > 2) {
        this.$emit("search", this.query, this.filter);
      } else if (oldVal && oldVal.length > 2) {
        this.$emit("search", "", this.filter);
      }
    },
  },

  components: {
    MenuVue,
    InfoMenuVue
  },

  methods: {
    resetFilter() {
      this.filter.brafo = 0;
      this.filter.mapping = 0;
      this.filter.blocked = 0;
      this.filter.todo = 1;

      this.$emit("toggleFilter", this.query, this.filter);
    },
    toggleBrafoFilter() {
      if (this.filter.brafo == 1) this.filter.brafo = 0;
      else this.filter.brafo = 1;

      this.$emit("toggleFilter", this.query, this.filter);
    },
    toggleMappingFilter() {
      if (this.filter.mapping == 1) this.filter.mapping = 0;
      else this.filter.mapping = 1;

      this.$emit("toggleFilter", this.query, this.filter);
    },
    toggleBlockedFilter() {
      if (this.filter.blocked == 1) this.filter.blocked = 0;
      else this.filter.blocked = 1;

      this.$emit("toggleFilter", this.query, this.filter);
    },
    toggleTodoFilter() {
      if (this.filter.todo == 1) this.filter.todo = 0;
      else this.filter.todo = 1;

      this.$emit("toggleFilter", this.query, this.filter);
    },
    showNewJobOverlay() {
      this.$emit("create");
    },
  },

  mounted() {
    this.$emit("toggleFilter", this.query, this.filter);
  },
};
</script>
