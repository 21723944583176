<template>
  <div class="mb-3" id="requestList">
    <v-container
      fluid
      class="scrollable"
      style="
        margin: 0 auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        overscroll-behavior: auto !important;
      "
      :style="{ 'min-height': getContainerHeight }"
    >
      <v-row class="mb-0" style="flex-grow: 0">
        <v-col :cols="12" style="display: flex" class="pa-0">
          <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col :cols="6" class="pa-0 pr-1">
                <v-card flat>
                  <v-card-title class="encodeFont pa-0 pb-1">
                    <v-text-field
                      hide-details
                      v-model="searchString"
                      class="px-4 hideInputMessage"
                      append-icon="mdi-magnify"
                      label="Firmenname eingeben"
                    >
                    </v-text-field>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="auto" class="py-0" style="height: inherit">
                <MenuVue @changeFilterQ="changeFilterQ" />
              </v-col>
              <v-col cols="auto" class="pa-0 px-1">
                <InfoMenu>
                  <v-container
                    fluid
                    style="display:flex;flex-direction: column"
                  >
                    <v-row>
                      <v-col class="py-2" cols="auto">
                        <v-icon color="primary">mdi-account-multiple </v-icon>
                      </v-col>
                      <v-col class="py-2">
                        <span>
                          Das Unternehmen hat sich registriert und kann sich
                          einloggen.
                        </span>
                      </v-col>
                    </v-row>
                    <v-divider class="my-4" />
                    <v-row>
                      <v-col class="py-2" cols="auto">
                        <v-icon color="primary">mdi-check </v-icon>
                      </v-col>
                      <v-col class="py-2">
                        Das Unternehmen wurde von einem Redakteur akzeptiert,
                        die Registrierung des Unternehmens ist jedoch noch nicht
                        vollständig abgeschlossen.
                      </v-col>
                    </v-row>
                    <v-divider class="my-4" />
                    <v-row>
                      <v-col class="py-2" cols="auto">
                        <v-icon color="primary">mdi-check-all </v-icon>
                      </v-col>
                      <v-col class="py-2">
                        Das Unternehmen wurde akzeptiert und hat sich
                        erfolgreich registriert. Stellenanzeigen sind in der
                        Suche verfügbar.
                      </v-col>
                    </v-row>
                    <v-divider class="my-4" />
                    <v-row>
                      <v-col class="py-2" cols="auto">
                        <v-icon color="primary"
                          >mdi-close-circle-outline
                        </v-icon>
                      </v-col>
                      <v-col class="py-2">
                        Das Unternehmen wurde abgelehnt. Stellenanzeigen dieses
                        Unternehmens sind nicht öffentlich sichtbar.
                      </v-col>
                    </v-row>
                    <v-divider class="my-4" />
                    <v-row>
                      <v-col class="py-2" cols="auto">
                        <v-icon color="primary">mdi-email-fast </v-icon>
                      </v-col>
                      <v-col class="py-2">
                        Das Unternehmen hat noch keinen Account. Über diesen
                        Knopf kann erneut eine E-Mail mit Registrierungslink
                        versendet werden (nur 1x alle 24h).
                      </v-col>
                    </v-row>
                  </v-container>
                </InfoMenu>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="auto" class="py-0 pl-7">
                <small>{{ totalCompanyRequests }} Treffer</small>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row style="padding-top: 0px; padding-bottom: 0px; flex-grow: 0">
        <v-col
          :cols="isMobile ? 12 : 6"
          v-for="(cr, index) in companyRequests"
          :key="cr.id"
          class="pb-5 pt-0"
          :class="{
            'pr-1': index % 2 == 0 && !isMobile ? true : false,
            'pl-3': index % 2 != 0 && !isMobile ? true : false,
            'pr-0': index % 2 != 0 && !isMobile ? true : false,
            'pl-0': index % 2 == 0 && !isMobile ? true : false,
            'px-0': isMobile,
          }"
          style="display: flex"
        >
          <v-card
            flat
            class="pa-2 disabledCard"
            style="flex-grow: 1; display: flex"
          >
            <v-container
              fluid
              style="flex-grow: 1; display: flex; flex-direction: column"
            >
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0 pb-2" cols="10">
                  <h2 class="encodeFont">
                    {{ cr.name }} <br />
                    <small>
                      erstellt am: {{ cr.created | moment("DD.MM.YY") }}</small
                    >
                  </h2>
                </v-col>
                <v-spacer />
                <v-col
                  cols="auto"
                  v-if="cr.account_status == 2 || cr.account_status == 4"
                  class="ma-0 pa-0 pb-2 mr-3"
                >
                  <v-tooltip bottom v-if="cr.account_status != 4 && cr.status > 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="showResendWarning(cr)"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        >mdi-email-fast
                      </v-icon>
                    </template>
                    <span>Registrierungsmail erneut versenden</span>
                  </v-tooltip>

                  <v-tooltip bottom v-else-if="cr.account_status == 4">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary"
                        >mdi-account-multiple
                      </v-icon>
                    </template>
                    <span>Account erfolgreich registriert</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="auto" v-if="cr.status == 2 && cr.account_status != 4" class="ma-0 pa-0 pb-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary"
                        >mdi-check
                      </v-icon>
                    </template>
                    <span>Akzeptiert</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="auto" v-if="cr.account_status == 4 && (cr.status == 2 || cr.status == 4)" class="ma-0 pa-0 pb-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary"
                        >mdi-check-all
                      </v-icon>
                    </template>
                    <span>Akzeptiert und Firma vollständig registriert</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="auto" v-if="cr.status == 3" class="ma-0 pa-0 pb-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary"
                        >mdi-close-circle-outline
                      </v-icon>
                    </template>
                    <span>Abgelehnt</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="3" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">Adresse:</p>
                </v-col>
                <v-col cols="9" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">
                    <b
                      >{{ cr.street }}, {{ cr.City.postcode }}
                      {{ cr.City.city }}
                    </b>
                  </p>
                </v-col>
              </v-row>

              <v-row class="ma-0 pa-0">
                <v-col cols="3" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">Webseite:</p>
                </v-col>
                <v-col cols="9" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">
                    <b>{{ cr.weblink }}</b>
                  </p>
                </v-col>
              </v-row>

              <br />

              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0" cols="3">
                  <p class="ma-0 pa-0">Kontaktperson:</p>
                </v-col>
                <v-col class="ma-0 pa-0" cols="9">
                  <p class="ma-0 pa-0">
                    <b>{{ cr.first_name }} {{ cr.last_name }}</b>
                  </p>
                </v-col>
              </v-row>

              <v-row class="ma-0 pa-0">
                <v-col cols="3" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">Telefon:</p>
                </v-col>
                <v-col cols="9" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">
                    <b>{{ cr.phone }}</b>
                  </p>
                </v-col>
              </v-row>

              <v-row class="ma-0 pa-0">
                <v-col cols="3" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">Email:</p>
                </v-col>
                <v-col cols="9" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">
                    <b>{{ cr.email }}</b>
                  </p>
                </v-col>
              </v-row>
              <br />
              <v-row class="ma-0 pa-0">
                <v-col
                  :cols="isMobile ? 12 : isLarge ? 4 : 12"
                  class="ma-0 pa-0"
                  :class="{ 'pr-2': !isMobile }"
                >
                  <v-btn
                    :disabled="cr.status != 1"
                    @click="showDecline(cr)"
                    class="primaryOnWhiteButton lightOutline py-3"
                    elevation="0"
                    large
                    block
                    ><v-icon class="mr-2">mdi-close</v-icon>Ablehnen</v-btn
                  >
                </v-col>
                <v-spacer />
                <v-col
                  :cols="isMobile ? 12 : isLarge ? 7 : 12"
                  class="ma-0 pa-0"
                  :class="{
                    'mt-3': isMobile,
                    'pr-2': !isMobile,
                    'pt-2': !isLarge,
                  }"
                >
                  <v-btn
                    :disabled="cr.status != 1"
                    @click="showAccept(cr)"
                    class="primaryButton py-3"
                    elevation="0"
                    large
                    block
                    ><v-icon class="mr-2">mdi-check</v-icon>Akzeptieren</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row class="mt-1 pa-0">
        <v-spacer />
        <v-col class="ma-0 pa-0" cols="5">
          <v-pagination
            class="mt-2 focusableNoBorder"
            v-model="page"
            :length="maxPages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <v-overlay :value="showAcceptWarning" :dark="false">
      <AcceptRequestVue
        @confirm="acceptCR"
        @close="hideAccept"
        :company="currRequestValue"
      />
    </v-overlay>

    <v-overlay :value="showDeclineWarning" :dark="false">
      <DeclineRequestVue
        @confirm="declineCR"
        @close="hideDecline"
        :company="currRequestValue"
      />
    </v-overlay>

    <v-overlay :value="b_showResendEmailWarning" :dark="false">
      <ResendWarningVue
        v-if="currRequestValue"
        :editorRequest="currRequestValue"
        :isCompanyRequest="true"
        @close="hideResendWarning"
        @confirm="resendEmail"
      />
    </v-overlay>

    <v-overlay
      :value="b_showErrorOverlay"
      :dark="false"
      style="z-index: 12 !important"
    >
      <v-card class="pa-6">
        <v-container style="max-width: 50vw">
          <v-row>
            <v-col>
              <h2 class="encodeFont">Ein Fehler ist aufgetreten</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>{{ errorMessage }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                @click="hideErrorOverlay()"
                large
                block
                class="py-3 primaryButton"
                elevation="0"
                >OK</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import DeclineRequestVue from "../../../components/Backoffice/Editor/DeclineRequest.vue";
import AcceptRequestVue from "../../../components/Backoffice/Editor/AcceptRequest.vue";
import ResendWarningVue from "../../../components/Backoffice/Editor/ResendInviteWarning.vue";
import MenuVue from "../../../components/Backoffice/Editor/RequestFilter.vue";
import InfoMenu from "@/components/Main/InfoMenu.vue";

const axios = require("axios");

export default {
  name: "companyRequest",

  props: ["width", "height", "search"],

  components: {
    DeclineRequestVue,
    AcceptRequestVue,
    ResendWarningVue,
    MenuVue,
    InfoMenu,
  },

  data() {
    return {
      companyRequests: [],

      showAcceptWarning: false,
      showDeclineWarning: false,

      b_showResendEmailWarning: false,
      b_showErrorOverlay: false,
      errorMessage: "",

      currRequestValue: undefined,

      loading: false,

      searchString: undefined,

      filterQ: undefined,

      maxPages: undefined,
      page: 1,

      totalCompanyRequests: 0,
    };
  },

  watch: {
    page() {
      this.pullCompanyRequests();
    },
    searchString() {
      if (this.searchString && this.searchString.length >= 3) {
        if (this.page == 1) {
          this.pullCompanyRequests();
        } else {
          this.page = 1;
        }
      } else {
        this.pullCompanyRequests();
      }
    },
    filterQ() {
      if (this.page == 1) {
        this.pullCompanyRequests();
      } else {
        this.page = 1;
      }
    },
  },

  computed: {
    getContainerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "unset";
        case "sm":
          return "unset";
        case "md":
          return "70vh";
        case "lg":
          return "80vh";
        case "xl":
          return "80vh";
        default:
          return "80vh";
      }
    },
    isSuperUser() {
      return this.$store.state.name == "Frau Support";
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    isLarge() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return true;
        case "xl":
          return true;
        default:
          return false;
      }
    },
  },

  methods: {
    changeFilterQ(data) {
      this.filterQ = data;
    },
    hideErrorOverlay() {
      this.b_showErrorOverlay = false;
      this.errorMessage = "";
    },
    showErrorOverlay() {
      this.b_showErrorOverlay = true;
      this.errorMessage =
        "Der Einladungslink konnte nicht zugestellt werden. Bitte prüfen Sie, ob diese Aktion innerhalb der letzten 24h bereits verwendet wurde oder versuchen Sie es zu einem späteren Zeitpunkt erneut.";
    },
    hideResendWarning() {
      this.currRequestValue = undefined;
      this.b_showResendEmailWarning = false;
    },
    showResendWarning(cr) {
      this.currRequestValue = cr;
      this.b_showResendEmailWarning = true;
    },
    resendEmail() {
      this.loading = true;

      let resendVal = parseInt(this.currRequestValue.resend);
      ++resendVal;
      axios
        .patch(
          axios.defaults.baseURL +
            "companies/request/patch/" +
            this.currRequestValue.id,
          {
            id: this.currRequestValue.id,
            resend: resendVal,
          }
        )
        .then(() => {
          this.hideResendWarning();

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.hideResendWarning();
          this.showErrorOverlay();

          this.loading = false;
        });
    },
    hideAccept() {
      this.currRequestValue = undefined;
      this.showAcceptWarning = false;
    },
    hideDecline() {
      this.currRequestValue = undefined;
      this.showDeclineWarning = false;
    },
    showAccept(data) {
      this.currRequestValue = data;
      this.showAcceptWarning = true;
    },
    showDecline(data) {
      this.currRequestValue = data;
      this.showDeclineWarning = true;
    },
    acceptCR(data) {
      this.loading = true;

      let formData = new FormData();

      formData.append("id", data.id);
      formData.append("status", "2");

      for (let i = 0; i < data.allowedApprenticeships.length; i++) {
        formData.append(
          "Allowed_apprenticeships",
          data.allowedApprenticeships[i].id
        );
      }

      axios
        .patch(
          axios.defaults.baseURL + "companies/request/patch/" + data.id,
          formData
        )
        .then(() => {
          this.hideAccept();
          this.pullCompanyRequests();

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.loading = false;
        });
    },
    declineCR(data) {
      this.loading = true;

      axios
        .patch(axios.defaults.baseURL + "companies/request/patch/" + data.id, {
          id: data.id,
          status: 3,
          declined_message: data.reason,
        })
        .then(() => {
          this.hideDecline();
          this.pullCompanyRequests();

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.loading = true;
        });
    },
    pullCompanyRequests() {
      this.loading = true;

      let searchString = "?page=" + this.page;
      if (this.searchString) {
        searchString += "&search=" + this.searchString.replace(/&/g, "%26");
      }
      if (this.filterQ) {
        searchString += this.filterQ;
      }

      axios
        .get(axios.defaults.baseURL + "companies/request/" + searchString)
        .then((resp) => {
          this.companyRequests = resp.data.results;
          this.totalCompanyRequests = resp.data.count;

          this.maxPages =
            resp.data.count % 20 == 0
              ? resp.data.count / 20
              : Math.floor(resp.data.count / 20) + 1;

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.loading = false;
        });
    },
  },

  mounted() {
    this.pullCompanyRequests();
  },
};
</script>
