import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {router} from '../router'

import { permissions } from "../util/permissions.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: localStorage.getItem('access_token') || null, // makes sure the user is logged in even after refreshing the page
    refreshToken: localStorage.getItem('refresh_token') || null,
    permission: localStorage.getItem('permission'),
    name: localStorage.getItem('name') || null,
    setupComplete: localStorage.getItem('setupComplete') || null,
    smallDrawer: localStorage.getItem("smallDrawer") || null,
    id: localStorage.getItem("id") || null
  },
  mutations: {
    updateLocalStorage(state, { access, refresh, name, permission, setupComplete, id }) {

      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);
      localStorage.setItem('name', name);
      localStorage.setItem('permission', permission);
      localStorage.setItem('setupComplete', setupComplete);
      localStorage.setItem('id', id);

      state.accessToken = access;
      state.refreshToken = refresh;
      state.name = name;
      state.permission = permission;
      state.setupComplete = setupComplete;
      state.id = id;
    },
    updateAccess(state, access) {
      state.accessToken = access;
    },
    destroyToken(state) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('name');
      localStorage.removeItem('permission');
      localStorage.removeItem('setupComplete');
      localStorage.removeItem('id');

      axios.defaults.headers.common['Authorization'] = undefined;

      state.accessToken = null;
      state.refreshToken = null;
      state.name = null;
      state.permission = null;
      state.setupComplete = null;
      state.id = null;
    },
    updateDrawer(state, value) {
      state.smallDrawer = value.value;
      localStorage.setItem("smallDrawer", state.smallDrawer ? "1" : "0")
    },
  },
  actions: {
    // run the below action to get a new access token on expiration
    refreshToken(context) {
      return new Promise((resolve, reject) => {
        axios.post(axios.defaults.baseURL + 'token/refresh/', {
          refresh: this.state.refreshToken
        }) // send the stored refresh token to the backend API
          .then(response => { // if API sends back new access and refresh token update the store
            context.commit('updateAccess', response.data.access)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
            resolve(response.data.refresh)
          })
          .catch(err => {
            context.commit('destroyToken')

            router.push('/')
            reject(err) // error generating new access and refresh token because refresh token has expired
          })
      })
    },
    logoutUser(context) {
      return new Promise((resolve, reject) => {
        axios.post(axios.defaults.baseURL + 'rest-auth/logout/', {

        }).then(() => {
          context.commit('destroyToken')

          router.replace('/');

          resolve
        })
          .catch((e) => {
            console.log(e);
            context.commit('destroyToken')

            router.replace('/');
            resolve
          })

      })

    },
    loginUser(context, credentials) {
      // router.push('/mainPage')
      return new Promise((resolve, reject) => {
        // send the username and password to the backend API:
        axios.post(axios.defaults.baseURL + 'token/',
          {
            email: credentials.email,
            password: credentials.password
          }
        )
          // if successful update local storage:
          .then(response => {
            context.commit('updateLocalStorage', { access: response.data.access, refresh: response.data.refresh, permission: response.data.permission, name: response.data.name, setupComplete: response.data.setup_complete, id: response.data.id }) // store the access and refresh token in localstorage
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;

            sessionStorage.setItem("mail", credentials.email)

            resolve(response);

            if (response.data.permission <= permissions.REDAKTEUR) {
              router.push("/redakteure/dashboard");
            }
            else if (response.data.permission == permissions.UNTERNEHMEN) {
              if (!response.data.setup_complete) {
                router.push("/unternehmen/setup");
              }
              else {
                router.push("/unternehmen/unternehmensprofil");
              }
            }
          })
          .catch(err => {
            // router.replace('/login');
            return reject(err)
          })
      })
    },
    switchDrawerSize(context, value){
      context.commit('updateDrawer', { value: value})
            
    }
  }
})