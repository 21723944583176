export class SearchFilterHandler {
  static searchFilters = {
    searchString: "",
    selectedCity: undefined,
    perimeter: "Stadt",
    startDateFilter: 0,
    typeFilter: { ausbildung: false, praktikum: false },
    brafoSelections: [],
    createdFilter: 0,
    // job_ids: [],
  };

  static setSearchQ(searchString) {
    let searchQ = atob(searchString);
    const urlParams = new URLSearchParams(searchQ);

    if (
      urlParams.get("city") ||
      urlParams.get("postcode") ||
      urlParams.get("state") ||
      urlParams.get("location__geo_distance")
    ) {
      SearchFilterHandler.searchFilters.selectedCity = {};
      if (urlParams.get("city"))
        SearchFilterHandler.searchFilters.selectedCity.city = urlParams.get(
          "city"
        );
      if (urlParams.get("postcode"))
        SearchFilterHandler.searchFilters.selectedCity.postcode = urlParams.get(
          "postcode"
        );
      if (urlParams.get("state"))
        SearchFilterHandler.searchFilters.selectedCity.state = urlParams.get(
          "state"
        );

      if (urlParams.get("location__geo_distance")) {
        console.log(
          "location__geo_distance",
          urlParams.get("location__geo_distance").split("__")[2]
        );
        SearchFilterHandler.searchFilters.perimeter = urlParams
          .get("location__geo_distance")
          .split("__")[0];
        SearchFilterHandler.searchFilters.selectedCity.latitude = urlParams
          .get("location__geo_distance")
          .split("__")[1];
        SearchFilterHandler.searchFilters.selectedCity.longitude = urlParams
          .get("location__geo_distance")
          .split("__")[2];
      }
    }

    if (urlParams.get("search_multi_match")) {
      SearchFilterHandler.searchFilters.searchString = urlParams.get(
        "search_multi_match"
      );
    }
    if (urlParams.get("brafo_fields")) {
      let brafos = urlParams.get("brafo_fields").split(",");

      let result = [];
      for (let i = 0; i < brafos.length; i++) {
        result[brafos[i] - 1] = true;
      }

      SearchFilterHandler.searchFilters.brafoSelections = result;
    }
    if (
      urlParams.get("perimeter") &&
      !SearchFilterHandler.searchFilters.perimeter
    ) {
      SearchFilterHandler.searchFilters.perimeter = urlParams.get("perimeter");
    }
    if (urlParams.get("job_type__terms")) {
      SearchFilterHandler.searchFilters.typeFilter.praktikum =
        urlParams.get("job_type__terms") == "praktikum" ? true : false;
      SearchFilterHandler.searchFilters.typeFilter.ausbildung =
        urlParams.get("job_type__terms") == "ausbildung" ? true : false;
      if (urlParams.get("job_type__terms") == "praktikum__ausbildung") {
        SearchFilterHandler.searchFilters.typeFilter.praktikum = true;
        SearchFilterHandler.searchFilters.typeFilter.ausbildung = true;
      }
    }
    if (urlParams.get("job_start__range"))
      SearchFilterHandler.searchFilters.startDateFilter = urlParams.get(
        "job_start__range"
      );
    if (urlParams.get("created__range"))
      SearchFilterHandler.searchFilters.createdFilter = urlParams.get(
        "created__range"
      );
    // if(urlParams.get("job_ids"))
    //     SearchFilterHandler.searchFilters.job_ids = urlParams.get("job_ids")
  }

  static createdRanges = [
    undefined,
    24 * 60 * 60,
    48 * 60 * 60,
    7 * 24 * 60 * 60,
    14 * 24 * 60 * 60,
  ];

  static update = 0;

  static setup() {
    SearchFilterHandler.searchFilters = {
      searchString: "",
      selectedCity: undefined,
      perimeter: "Stadt",
      startDateFilter: 0,
      typeFilter: { ausbildung: false, praktikum: false },
      brafoSelections: [],
      createdFilter: 0,
      // job_ids: [],
    };
  }

  static getSearchFilters() {
    return SearchFilterHandler.searchFilters;
  }

  static getSearchQ() {
    let q;

    if (SearchFilterHandler.searchFilters.searchString == null) {
      q = "?search_multi_match=";
    } else {
      let temp = SearchFilterHandler.searchFilters.searchString.split(" ");
      for (let word of temp) {
        if (word.length <= 3) {
          temp.splice(temp.indexOf(word), 1);
        }
      }
      SearchFilterHandler.searchFilters.searchString = temp.join(" ");
      q =
        "?search_multi_match=" + SearchFilterHandler.searchFilters.searchString;
    }

    if (SearchFilterHandler.searchFilters.selectedCity) {
      if (SearchFilterHandler.searchFilters.perimeter == "Stadt") {
        let strippedCity = SearchFilterHandler.searchFilters.selectedCity.city
          .toLowerCase()
          .replaceAll("-", "").replaceAll("(", "").replaceAll(")", "");
        strippedCity = strippedCity.replaceAll(" ", "");
        let strippedState = SearchFilterHandler.searchFilters.selectedCity.state
          .toLowerCase()
          .replaceAll("-", "");
        strippedState = strippedState.replaceAll(" ", "");

        q +=
          "&city=" +
          strippedCity +
          "&postcode=" +
          SearchFilterHandler.searchFilters.selectedCity.postcode +
          "&state=" +
          strippedState;
      } else {
        q +=
          "&location__geo_distance=" +
          SearchFilterHandler.searchFilters.perimeter +
          "__" +
          SearchFilterHandler.searchFilters.selectedCity.latitude +
          "__" +
          SearchFilterHandler.searchFilters.selectedCity.longitude;
      }
    }

    if (SearchFilterHandler.searchFilters.startDateFilter) {
      let d = new Date();
      let currMonth = d.getMonth();

      currMonth += SearchFilterHandler.searchFilters.startDateFilter;

      if (currMonth < 10) {
        currMonth = "0" + currMonth;
      }

      q += "&job_start__range=" + d.getFullYear() + "-" + currMonth + "-01";
    }

    if (SearchFilterHandler.searchFilters.typeFilter) {
      if (
        SearchFilterHandler.searchFilters.typeFilter.praktikum &&
        SearchFilterHandler.searchFilters.typeFilter.ausbildung
      ) {
        q += "&job_type__terms=praktikum__ausbildung";
      } else if (SearchFilterHandler.searchFilters.typeFilter.praktikum) {
        q += "&job_type__terms=praktikum";
      } else if (SearchFilterHandler.searchFilters.typeFilter.ausbildung) {
        q += "&job_type__terms=ausbildung";
      }
    }

    if (SearchFilterHandler.searchFilters.createdFilter) {
      let start = new Date(
        Date.now() -
          SearchFilterHandler.createdRanges[
            SearchFilterHandler.searchFilters.createdFilter
          ]
      ).toISOString();
      start = start.split("T")[0];
      let end = new Date().toISOString();
      end = end.split("T")[0];
      q += "&created__range=" + start + "__" + end;
    }

    if (SearchFilterHandler.searchFilters.brafoSelections.length > 0) {
      let brafoToNumArray = [];
      for (
        let i = 0; i < SearchFilterHandler.searchFilters.brafoSelections.length; i++
      ) {
        if (SearchFilterHandler.searchFilters.brafoSelections[i]) {
          brafoToNumArray.push(i + 1);
        }
      }

      let brafoToString = "";

      for (let i = 0; i < brafoToNumArray.length - 1; i++) {
        brafoToString += brafoToNumArray[i] + ",";
      }

      brafoToString += brafoToNumArray[brafoToNumArray.length - 1];

      q += "&brafo_fields=" + brafoToString;
    }

    // if(SearchFilterHandler.searchFilters.job_ids.length > 0){
    //     q += "&job_ids=" + SearchFilterHandler.searchFilters.job_ids
    // }

    return q;
  }

  static getSearchQEncoded() {
    return btoa(SearchFilterHandler.getSearchQ()).replaceAll("/", "%2F");
  }

  static setTypeSelection({ ausbildung, praktikum }) {
    SearchFilterHandler.searchFilters.typeFilter = {
      ausbildung: ausbildung,
      praktikum: praktikum,
    };
  }

  static setSearchString(searchString) {
    SearchFilterHandler.searchFilters.searchString = searchString;
  }

  static setCitySelection(city) {
    SearchFilterHandler.searchFilters.selectedCity = city;
  }

  static setSelectedBrafos(brafoArray) {
    SearchFilterHandler.searchFilters.brafoSelections = brafoArray;
  }
}
