var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.jobDescriptionData)?_c('v-card',{staticClass:"pb-4",class:{ 'pa-4': !_vm.isMobile, focusable: !_vm.isMobile },staticStyle:{"height":"100%"},attrs:{"id":"resultDetail","tabindex":"0","flat":"","ripple":false}},[_c('v-container',{staticClass:"scrollable",class:{ hiddenScroll: _vm.isMobile, disableOverscroll: _vm.isMobile }},[(_vm.isMobile)?_c('v-row',{staticClass:"pt-1",staticStyle:{"background-color":"black"}},[_c('v-col',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-between"},attrs:{"cols":"11"}},[_c('div',{staticClass:"pl-4"},[_c('h2',{staticClass:"primaryText encodeFont",staticStyle:{"font-size":"1em"}},[_vm._v(" "+_vm._s(_vm.jobDescriptionData.job_name)+" ")]),_c('h1',{staticClass:"encodeFont",staticStyle:{"font-size":"1.5em","color":"white"}},[_vm._v(" "+_vm._s(_vm.jobDescriptionData.title)+" ")])])]),_c('v-col',{staticStyle:{"transform":"translate(0, -16px)","display":"flex","align-items":"center"},attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-2 headerButton pt-6",staticStyle:{"position":"absolute","right":"0%","top":"0%"},attrs:{"text":""},on:{"click":_vm.closeDrawer}},[_c('v-icon',{staticStyle:{"color":"white !important"}},[_vm._v(" mdi-close ")])],1)],1)],1):_vm._e(),(_vm.isEditView)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('small',[_c('i',[_vm._v("Zuletzt geändert: "+_vm._s(_vm._f("moment")(_vm.jobDescriptionData.last_updated,"DD.MM.YY"))+" von "+_vm._s(_vm.jobDescriptionData.last_updated_by ? _vm.jobDescriptionData.last_updated_by : "-externe API-"))])])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"},on:{"click":_vm.showEditView}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v("mdi-pencil")])],1)]}}],null,false,513975963)},[_c('span',[_vm._v("Bearbeiten")])])],1)],1):_vm._e(),(_vm.isMobile)?_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"auto"}},[_c('v-chip',{style:({
            'background-color':
              _vm.jobDescriptionData.job_type == 'Praktikum'
                ? 'var(--v-primary-base) !important'
                : 'var(--v-accent-base) !important',
            color:
              _vm.jobDescriptionData.job_type == 'Praktikum'
                ? 'white !important'
                : 'black !important',
          })},[_vm._v(_vm._s(_vm.jobDescriptionData.job_type))])],1),_c('v-spacer'),_c('v-col',{staticClass:"px-1",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-between"},attrs:{"cols":"auto"}},[_c('v-chip',[_vm._v("Startet am: "+_vm._s(_vm._f("moment")(_vm.jobDescriptionData.job_start,"DD.MM.YY")))])],1),_c('v-spacer'),_c('v-col',{staticClass:"px-1",attrs:{"cols":"auto"}},[(_vm.jobDescriptionData.job_end)?_c('v-chip',[_vm._v("Bis: "+_vm._s(_vm._f("moment")(_vm.jobDescriptionData.job_end,"DD.MM.YY")))]):_c('v-chip',[_vm._v("Bis: --")])],1)],1):_vm._e(),(!_vm.isMobile)?_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-between"},attrs:{"cols":12}},[_c('div',[(_vm.jobDescriptionData.job_name)?_c('h2',{staticClass:"primaryText encodeFont",staticStyle:{"font-size":"1em"}},[_vm._v(" "+_vm._s(_vm.jobDescriptionData.job_name)+" ")]):(_vm.jobDescriptionData.Job_name)?_c('h2',{staticClass:"primaryText encodeFont",staticStyle:{"font-size":"1em"}},[_vm._v(" "+_vm._s(_vm.jobDescriptionData.Job_name.name)+" ")]):_vm._e(),_c('h1',{staticClass:"encodeFont",staticStyle:{"font-size":"1.5em"}},[_vm._v(" "+_vm._s(_vm.jobDescriptionData.title)+" ")])]),_c('br'),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-chip',{style:({
              'background-color':
                _vm.jobDescriptionData.job_type == 'Praktikum'
                  ? 'var(--v-primary-base) !important'
                  : 'var(--v-accent-base) !important',
              color:
                _vm.jobDescriptionData.job_type == 'Praktikum'
                  ? 'white !important'
                  : 'black !important',
            })},[_vm._v(_vm._s(_vm.jobDescriptionData.job_type))]),_c('v-chip',[_vm._v("Beginn: "+_vm._s(_vm._f("moment")(_vm.jobDescriptionData.job_start,"DD.MM.YY")))]),(_vm.jobDescriptionData.job_end)?_c('v-chip',[_vm._v("Bis: "+_vm._s(_vm._f("moment")(_vm.jobDescriptionData.job_end,"DD.MM.YY")))]):_c('v-chip',[_vm._v("Bis: --")])],1)])],1):_vm._e(),_c('hr',{staticClass:"my-3"}),_c('v-row',{staticClass:"pb-4"},[_c('v-col',[(_vm.jobDescriptionData.details)?_c('span',{staticClass:"encodeFont",domProps:{"innerHTML":_vm._s(_vm.jobDescriptionData.details)}}):_c('span',[_c('i',[_vm._v("keine Stellenbeschreibung vorhanden")])])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('b',[_vm._v("Arbeitsorte:")])])],1),_vm._l((_vm.jobDescriptionData.Companies),function(company){return _c('v-row',{key:company.id,on:{"click":function($event){return _vm.gotoCompany(company)}}},[_c('v-col',{staticClass:"py-2"},[(_vm.getJdByCompany(company))?_c('v-container',{staticStyle:{"background-color":"var(--v-backgroundButLighter-base)"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(company.name)+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.isEditor)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"},on:{"click":function($event){return _vm.gotoCompany(company)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"var(--v-primary-base) !important"}},[_vm._v("mdi-warehouse")])],1)]}}],null,true)},[_c('span',[_vm._v("Unternehmen anzeigen")])]):_vm._e(),(
                  !_vm.getJdByCompany(company).published &&
                    _vm.getJdByCompany(company).availability_end_date &&
                    new Date(
                      _vm.getJdByCompany(company).availability_end_date
                    ).toLocaleString() < new Date().toLocaleString()
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"}},'v-btn',attrs,false),on),[(
                        _vm.getJdByCompany(company).availability_end_date &&
                          new Date(
                            _vm.getJdByCompany(company).availability_end_date
                          ).toLocaleString() < new Date().toLocaleString()
                      )?_c('v-icon',{staticStyle:{"color":"gray !important"}},[_vm._v("mdi-eye-off")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Abgelaufen ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"0"},on:{"click":function($event){_vm.switchVisibility(_vm.getJdByCompany(company), $event)}}},'v-btn',attrs,false),on),[(!_vm.getJdByCompany(company).published)?_c('v-icon',{staticClass:"pr-2",staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v("mdi-eye-off")]):_c('v-icon',{staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[(!_vm.getJdByCompany(company).published)?_c('span',[_vm._v("Einblenden")]):_c('span',[_vm._v("Ausblenden")])]),(_vm.getJdByCompany(company))?_c('span',[(_vm.getJdByCompany(company).locked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock ")])]}}],null,true)},[_c('span',[_vm._v(" Stelle gesperrt und in Suche nicht sichtbar ")])]):_vm._e(),(_vm.getJdByCompany(company).verified)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("Verifiziert")])]):_vm._e()],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)}),_c('v-row',[_c('v-col',[(_vm.jobDescriptionData.annotation)?_c('div',[_c('h2',{staticClass:"encodeFont pb-2",staticStyle:{"font-size":"1em"}},[_vm._v("Anmerkung")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.jobDescriptionData.annotation)}})]):_vm._e()])],1),(_vm.jobDescriptionData.files.length > 0)?_c('v-row',[_c('v-col',{staticClass:"py-0 mb-2"},[_c('b',[_vm._v("Anhänge:")])])],1):_vm._e(),(_vm.jobDescriptionData.files.length > 0)?_c('v-row',_vm._l((_vm.jobDescriptionData.files),function(file){return _c('v-col',{key:file.id,attrs:{"xs":"12","sm":"12","md":"12"}},[_c('JobDescriptionFileCardVue',{attrs:{"file":file}})],1)}),1):_vm._e(),(
        _vm.jobDescriptionData.external != '' &&
          _vm.jobDescriptionData.external != undefined
      )?_c('v-row',[_c('v-col',[_c('i',[_vm._v("externe Stelle von "),_c('a',{attrs:{"href":_vm.getExternalLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.jobDescriptionData.external))]),_vm._v(" . Für die Richtigkeit und Aktualität externer Stellenangebote sind die jeweiligen Stellenbörsen verantwortlich. ")])])],1):_vm._e()],2),_c('a',{staticStyle:{"opacity":"0"},attrs:{"tabindex":"0"},on:{"focus":_vm.backToSelectionList}},[_vm._v("zurück zur Liste")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }