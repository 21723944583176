<template>
  <div>
    <img
      v-if="subRoute != 'information'"
      style="height: 100vh; 
    width: 100vw; position: absolute; margin-top: -64px; object-fit: cover"
      :src="imagesForSubpage"
    />

    <v-carousel
      v-else
      v-model="slide"
      cycle
      interval="10000"
      continuous
      :show-arrows="false"
      id="mainPageSlider"
      style="margin-top: -64px"
    >
      <v-carousel-item
        v-for="(image, i) in imagesForSubpage"
        :key="i"
        style="height=100%"
      >
        <v-sheet height="100%" tile>
          <v-row
            class="fill-height"
            align="center"
            justify="center"
            style="margin: 0px !important"
          >
            <v-img :src="image.file"></v-img>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <div
      style="display: flex; flex-direction: column; justify-content: space-between"
      :style="{ 'min-height': isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)' }"
    >
      <HeaderComponentVue />

      <v-container>
        <v-row>
          <v-spacer />
          <v-col :cols="mainCols" style="position: relative">
            <v-img
              v-if="!isXS"
              :width="fontSize * 5 + 'em'"
              :height="fontSize * 5 + 'em'"
              class=""
              style="position: absolute; z-index: 99; cursor: pointer;"
              :style="{
                right: fontSize * -3+ 'em',
                top: fontSize * -3.5 + 'em',
              }"
              :src="require('../../assets/images/logo.svg')"
              @click="gotoMain()"

            ></v-img>

            <v-img
              v-if="!isXS"
              :width="fontSize * 5 + 'em'"
              :height="fontSize * 5 + 'em'"
              class=""
              style="position: absolute; z-index: 99; cursor: pointer;"
              :style="{
                left: fontSize * -2.5 + 'em',
                bottom: fontSize * -4 + 'em',
              }"
              :src="require('../../assets/images/STELLEsuchen.png')"
              @click="gotoMain()"
            ></v-img>

            <v-container fluid>
              <v-row>
                <v-col :cols="isXS ? '12' : 'auto'">
                  <v-card
                    style="background-color: black"
                    :style="{
                      width: isXS ? '100%' : 'fit-content',
                    }"
                    class="px-4 py-3 my-1"
                    @click="gotoSchueler()"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <h1
                            style="color: var(--v-accent-base)"
                            :style="{ 'font-size': isMobile ? 'x-large' : 'xx-large' }"
                          >
                            SCHÜLER*INNEN/ELTERN
                          </h1>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <p style="color: white" class="mb-0">
                            ...probier dich in verschiedenen Berufen aus!
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer />
                <v-col :cols="isXS ? '12' : 'auto'">
                  <v-card
                    style="background-color: black"
                    :style="{
                      width: isXS ? '100%' : 'fit-content',
                    }"
                    class="px-4 py-3 my-1"
                    @click="gotoAzubis()"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <h1
                            style="color: var(--v-accent-base)"
                            :style="{ 'font-size': isMobile ? 'x-large' : 'xx-large' }"
                          >
                            AUSZUBILDENDE
                          </h1>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <p style="color: white" class="mb-0">
                            ...hol das Maximale raus aus deiner Ausbildung!
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col :cols="isXS ? '12' : 'auto'">
                  <v-card
                    style="background-color: black"
                    :style="{
                      width: isXS ? '100%' : 'fit-content',
                    }"
                    class="px-4 py-3 my-1"
                    @click="gotoLehrer()"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <h1
                            style="color: var(--v-accent-base)"
                            :style="{ 'font-size': isMobile ? 'x-large' : 'xx-large' }"
                          >
                            LEHRER*INNEN/SCHULEN
                          </h1>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <p style="color: white" class="mb-0">
                            ...unterstützen Sie bei der Berufsorientierung!
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-spacer />
              </v-row>

              <v-row>
                <v-spacer />
                <v-col :cols="isXS ? '12' : 'auto'">
                  <v-card
                    style="background-color: black"
                    :style="{
                      width: isXS ? '100%' : 'fit-content',
                    }"
                    class="px-4 py-3 my-1"
                    @click="gotoUnternehmen()"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <h1
                            style="color: var(--v-accent-base)"
                            :style="{ 'font-size': isMobile ? 'x-large' : 'xx-large' }"
                          >
                            UNTERNEHMEN
                          </h1>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <p style="color: white" class="mb-0">
                            ...Finden Sie Ihren Nachwuchs!
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>

      <v-container fluid style="background-color: black; z-index: 2; position: relative">
        <v-row>
          <v-spacer />
          <v-col :cols="textCols">
            <h1
              class="text-center"
              style="color: white"
              :style="{ 'font-size': isMobile ? 'x-large' : 'xx-large' }"
            >
              Ansprechpersonen der gewerblichen Kammern
            </h1>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col :cols="textCols">
            <p class="text-center" style="color: white" v-if="subRoute == 'information'">
              Mit Praktika noch heute die Fachkräfte von morgen gewinnen –Kontaktieren Sie
              die Mitarbeitenden des Projekts Bildungsketten der gewerblichen Kammern
              Sachsen-Anhalt!
            </p>
            <p class="text-center" style="color: white" v-else>
              <a
                @click="gotoKontakt()"
                tabindex="0"
                class="focusableLink"
                style="color: white"
                >Zu unserem Kontaktformular</a
              >
            </p>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />

    <RouterView id="informationScrollAnchor" :faqData="faqs" />

    <FooterComponentVue />
  </div>
</template>

<script>
import axios from "axios";
import HeaderComponentVue from "../../components/Main/Header.vue";
import FooterComponentVue from "../../components/Main/Footer.vue";

export default {
  name: "halloPraktikumRoot",

  data() {
    return {
      slide: 0,

      images: [],
      allFAQs: []
    };
  },

  components: {
    HeaderComponentVue,
    FooterComponentVue,
  },

  computed: {
    faqs(){
      let selectedCategory = "öffentlich-Schüler";
      if (this.subRoute == "schueler") {
        selectedCategory = "öffentlich-Schüler";
      }
      else if (this.subRoute == "azubi") {
        selectedCategory = "öffentlich-Azubis";
      }
      else if (this.subRoute == "unternehmen") {
        selectedCategory = "öffentlich-Unternehmen";
      }
      else if (this.subRoute == "lehrer") {
        selectedCategory = "öffentlich-Lehrer";
      }

      let result = []
      for(let i = 0;  i < this.allFAQs.length; i++){
        for(let j = 0; j < this.allFAQs[i].Categories.length; j++){
          if(this.allFAQs[i].Categories[j].key == selectedCategory){
            result.push(this.allFAQs[i])
            break;
          }
        }
      }

      return result
    },
    imagesForSubpage() {
      if (this.subRoute == "information") {
        return this.images;
      }
      if (this.subRoute == "schueler") {
        return require("../../assets/images/hallo-praktikum/Maurer_Bildungsketten_shutterstock_288241988_.jpg");
      }
      if (this.subRoute == "azubi") {
        return require("../../assets/images/hallo-praktikum/Einzelhandel_shutterstock_1907524750_.jpg");
      }
      if (this.subRoute == "unternehmen") {
        return require("../../assets/images/hallo-praktikum/Friseur_Bildungsketten_shutterstock_284521190_.jpg");
      }
      if (this.subRoute == "lehrer") {
        return require("../../assets/images/hallo-praktikum/Buerokaufmann_contrastwerkstatt_stock.adobe_.com_153535168-Kopie.jpg");
      }

      return "";
    },
    fontSize() {
      if (window.innerHeight < 750) {
        return 1.5;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1.5;
        case "sm":
          return 1.5;
        case "md":
          return 2;
        case "lg":
          return 2.5;
        case "xl":
          return 2.5;
        default:
          return 3;
      }
    },
    mainCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 10;
        case "sm":
          return 9;
        case "md":
          return 8;
        case "lg":
          return 6;
        case "xl":
          return 4;
        default:
          return 6;
      }
    },
    cardCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        default:
          return false;
      }
    },
    isXS() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        default:
          return false;
      }
    },
    textCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 11;
        case "md":
          return 10;
        case "lg":
          return 8;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
    subRoute() {
      let routeSplit = this.$route.path.split("/");

      return routeSplit[routeSplit.length - 1];
    },
  },

  methods: {
    gotoKontakt() {
      this.$router.push("/kontakt");
    },
    gotoSchueler() {
      this.$router.push("/information/schueler");

      this.gotoAnchor();
    },
    gotoAzubis() {
      this.$router.push("/information/azubi");

      this.gotoAnchor();
    },
    gotoLehrer() {
      this.$router.push("/information/lehrer");

      this.gotoAnchor();
    },
    gotoUnternehmen() {
      this.$router.push("/information/unternehmen");

      this.gotoAnchor();
    },
    gotoAnchor() {
      setTimeout(() => {
        var ele = document.getElementById("informationScrollAnchor");   
window.scrollTo(ele.offsetLeft,ele.offsetTop);
        }, 200);
    },
    gotoMain() {
      this.$router.push("/");
    },
    fetchData() {
      axios.get(axios.defaults.baseURL + "backgroundimages/public/").then((resp) => {
        this.images = resp.data;

        let rand = Math.floor(Math.random() * this.images.length);
        this.slide = rand;

        this.getFAQs();
      });
    },
    getFAQs(faq) {
      axios
        .get(axios.defaults.baseURL + "faq/public")
        .then((resp) => {
          this.allFAQs = resp.data;

          for(let i = 0; i < this.allFAQs.length; i++){
            this.allFAQs[i].description = this.allFAQs[i].description.replaceAll("<a ", "<a target='_blank' ")
          }

          console.log(this.allFAQs)
          this.loading = false;

          if (faq) {
            for (let i = 0; i < this.faqs.length; i++) {
              if (this.faqs[i].id == faq.id) {
                this.fetchFiles(this.faqs[i], true);
                break;
              }
            }
          }

          this.$forceUpdate();
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    async fetchFiles(faq, forceFetch) {
      if (faq.expanded && !forceFetch) {
        return;
      }

      faq.expanded = true;

      for (let i = 0; i < faq.files.length; i++) {
        await this.getFile(faq, faq.files[i].id);
      }
    },
    async getFile(faq, fileId) {
      return new Promise((resolve) => {
        this.axios({
          method: "POST",
          url: this.axios.defaults.baseURL + "faq/files/",
          responseType: "blob", // Important! Set the response type to 'blob' for binary data
          data: {
            id: fileId,
          },
        })
          .then((resp) => {
            this.loading = false;

            for (let i = 0; i < this.faqs.length; i++) {
              if (this.faqs[i].id == faq.id) {
                for (let j = 0; j < faq.files.length; j++) {
                  if (this.faqs[i].files[j].id == fileId) {
                    this.faqs[i].files[j].blob = URL.createObjectURL(resp.data);

                    this.$forceUpdate();
                  }
                }
              }
            }

            resolve();
          })
          .catch((e) => {
            console.log(e);

            resolve();
            // this.loading = false;
            // window.dispatchEvent(new CustomEvent("showErrorDisplay", { detail: e }));
          });
      });
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>
