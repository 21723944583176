<template>
  <div role="banner">
    <v-app-bar
      :app="showHeaderImg"
      class="px-0"
      :style="{
        background:
          showHeaderImg && !showMobileHeader && !transparentHeader ? 'white' : 'none',
      }"
      :flat="!isLoggedIn"
    >
      <v-toolbar
        class="px-0"
        :style="{
          'background-color':
            showHeaderImg && !showMobileHeader && !transparentHeader
              ? 'initial'
              : 'transparent',
        }"
        flat
        id="toolbar"
      >
        <div
          :disabled="disabled"
          @click="mainImgClick()"
          tag="button"
          class="headerLink"
          v-if="showHeaderImg"
        >
          <v-img
            id="headerImg"
            src="../../assets/images/logo.svg"
            class="headerLink"
            style="cursor: pointer"
          ></v-img>
        </div>

        <v-spacer v-if="!isMobile" />

        <v-spacer v-if="!isMobile" />
        <div
          v-if="showLogin && !isLoggedIn"
          :class="{'pr-4': !isMobile}"
          style="display: flex; align-items: center; cursor: pointer"
        >
          <p
            @click="gotoRegister"
            :disabled="disabled"
            v-if="!isLoggedIn"
            class="mb-0 mr-4 pa-2"
            style="color: var(--v-accent-base) !important; background-color: black"
            :style="{ 'font-size': isMobile ? 'small' : 'initial' }"
          >
            Jetzt Unternehmen registrieren!
          </p>

          <span
            v-if="!isLoggedIn"
            @click="gotoLogin"
            class="pa-2"
            style="display: flex; align-items: center; background-color: black"
          >
            <p
              :style="{ 'font-size': isMobile ? 'small' : 'initial' }"
              class="mb-0 accent--text mr-2"
            >
              Zum Login
            </p>
            <v-icon
              @click="gotoLogin"
              :disabled="disabled"
              style="color: var(--v-accent-base) !important"
              :style="{ 'font-size': isMobile ? 'medium' : 'x-large' }"
              >mdi-login</v-icon
            >
          </span>
        </div>

        <div v-else-if="showLogin && isLoggedIn && !showMobileHeader">
          <small @click="mainImgClick()">Hallo, {{ name }}</small>
          <v-menu offset-y>
            <v-btn class="headerButton mx-2" text>
              <v-icon>mdi-cog</v-icon>
            </v-btn>

            <v-list>
              <!-- <v-list-item @click="gotoDashboard">
                  <v-list-item-title>Zum Backoffice</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Abmelden</v-list-item-title>
                </v-list-item> -->
            </v-list>
          </v-menu>
          <v-btn class="headerButton mx-2" text @click="logOut">
            <v-icon> mdi-logout </v-icon>
          </v-btn>
        </div>
        <div v-else-if="showLogin && isLoggedIn && showMobileHeader">
          <v-menu top :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="disabled"
                class="pr-0"
                v-if="isLoggedIn"
                style="color: transparent; font-size: 2em"
                text
                v-bind="attrs"
                v-on="on"
                ><v-icon style="color: black; font-size: xxx-large"
                  >mdi-menu</v-icon
                ></v-btn
              >
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>Hallo, {{ name }} </v-list-item-title>
              </v-list-item>
              <hr />
              <v-list-item @click="gotoJobDescriptions()">
                <v-list-item-title>Stellenanzeigen</v-list-item-title>
              </v-list-item>
              <v-list-item @click="gotoCompanyDetail()">
                <v-list-item-title>Unternehmensverwaltung</v-list-item-title>
              </v-list-item>
              <v-list-item @click="gotoCompanyEvents()">
                <v-list-item-title>Events</v-list-item-title>
              </v-list-item>
              <v-list-item @click="gotoAdminPanel()" v-if="isAdmin">
                <v-list-item-title>Redakteure</v-list-item-title>
              </v-list-item>
              <v-list-item @click="gotoJobNames()">
                <v-list-item-title>Berufsbezeichnungen</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logOut">
                <v-list-item-title
                  ><v-icon> mdi-logout </v-icon> Logout</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-toolbar>
    </v-app-bar>
  </div>
</template>

<script>
import { permissions } from "../../util/permissions.js";

/**
 * Header Component. Displayed on all subpages.
 * @component
 * @vue-data {String} name user's name
 * @vue-prop {Boolean} disabled is header input fields disabled
 * @vue-data {Boolean} showLogin determine if login icon is shown
 * @vue-data {Boolean} isLoggedIn determine if user is logged in
 * @vue-event {String} $route.path watch path change
 * @vue-event {null} mounted watch path change
 * @vue-computed {Boolean} showName displays username on big screens.
 */
export default {
  name: "headerComponent",

  props: ["disabled"],

  data() {
    return {
      isLoggedIn: false,

      showLogin: true,
      isMainPage: true,

      name: "",
    };
  },

  computed: {
    showName() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return true;
        default:
          return true;
      }
    },
    showMobileHeader() {
      // switch (this.$vuetify.breakpoint.name) {
      //   case "xs":
      //     return true;
      //   case "sm":
      //     return true;
      //   default:
      //     return false;
      // }
      return false;
    },
    showHeaderImg() {
      return !(this.$route.path == "/") && !(this.$route.path == "/login");
    },
    transparentHeader() {
      return this.$route.path.startsWith("/information");
    },
    showDashboardNavButtons() {
      return (
        this.isLoggedIn &&
        !this.$route.path.includes("/setup") &&
        (this.$route.path.includes("/unternehmen") ||
          this.$route.path.includes("/redakteure"))
      );
    },
    isAdmin() {
      return this.$store.state.permission == permissions.ADMIN;
    },
    isEditor() {
      return this.$store.state.permission <= permissions.REDAKTEUR;
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  watch: {
    "$route.path": {
      handler: function (val) {
        if (val == "/login" || val.startsWith("/information")) {
          this.showLogin = false;
        } else {
          this.showLogin = true;

          if (this.$store.state.accessToken) {
            this.isLoggedIn = true;

            this.name = this.$store.state.name;
          } else {
            this.isLoggedIn = false;
          }
        }
      },
    },
  },

  methods: {
    /**
     * Log out user. Delete all Data from SessionStorage
     */
    logOut() {
      this.$store.dispatch("logoutUser");

      this.showLogin = true;
      this.isLoggedIn = false;
    },
    /**
     * Go To Login Page.
     */
    gotoLogin() {
      this.$router.push("/login");
    },
    gotoRegister() {
      this.$router.push("/registrieren/anfrage");
    },
    /**
     * Go To Job Description Page.
     */
    gotoJobDescriptions() {
      if (this.$store.state.permission == permissions.UNTERNEHMEN) {
        this.$router.push("/unternehmen/stellenangebote");
      } else if (this.$store.state.permission <= permissions.REDAKTEUR) {
        this.$router.push("/redakteure/stellenangebote");
      }
    },

    gotoCompanyDetail() {
      if (this.$store.state.permission == permissions.UNTERNEHMEN) {
        this.$router.push("/unternehmen/unternehmensprofil");
      } else if (this.$store.state.permission <= permissions.REDAKTEUR) {
        this.$router.push("/redakteure/dashboard");
      }
    },
    gotoCompanyEvents() {
      if (this.$store.state.permission == permissions.UNTERNEHMEN) {
        this.$router.push("/unternehmen/events");
      } else if (this.$store.state.permission <= permissions.REDAKTEUR) {
        this.$router.push("/redakteure/events");
      }
    },
    gotoAdminPanel() {
      if (this.$store.state.permission == permissions.ADMIN) {
        this.$router.push("/redakteure/admin");
      }
    },
    gotoJobNames() {
      //redakteur only
      if (this.$store.state.permission <= permissions.REDAKTEUR) {
        this.$router.push("/redakteure/berufe");
      }
    },
    mainImgClick() {
      if (this.isLoggedIn) {
        this.gotoCompanyDetail();
        return;
      } else {
        this.$router.push("/");
        return;
      }
    },
  },

  mounted() {
    if (this.$route.path == "/") {
      this.isMainPage = true;
    } else {
      this.isMainPage = false;
    }

    if (this.$route.path == "/login" || this.$route.path.startsWith("/information")) {
      this.showLogin = false;
    } else {
      this.showLogin = true;

      if (this.$store.state.accessToken) {
        this.isLoggedIn = true;

        this.name = this.$store.state.name;
      } else {
        this.isLoggedIn = false;
      }
    }

    if (this.$store.state.permission == null && this.isLoggedIn) {
      this.$store.dispatch("logoutUser");
    }
  },

  updated() {},
};
</script>
