<template>
  <v-container fluid>
    <v-row>
      <v-spacer />
      <v-col :cols="contactCols">
        <h2 class="encodeFont">Tipps für Schüler*innen und Eltern:</h2>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-spacer />
      <v-col :cols="contactCols">
        <v-expansion-panels v-model="panel" multiple flat class="informationPanel">
          <v-expansion-panel class="my-2 rounded-lg" v-for="faq in faqData" :key="faq.id">
            <v-expansion-panel-header
              ><h3 class="encodeFont" style="color: var(--v-primary-base)">
                {{ faq.title }}
              </h3></v-expansion-panel-header
            >
            <v-expansion-panel-content class="pa-6" style="flex-direction: column">
              <div v-html="faq.description"></div>
              <div v-if="faq.files.length > 0">
                <div v-for="file in faq.files" :key="file.id">
                  <span v-if="isGraphic(file)"
                    ><img
                      style="max-height: 600px; max-width: 100%"
                      :src="axios.defaults.url + file.full_path"
                  /></span>
                  <span v-else-if="isVideo(file)">
                    <video
                      style="width: 100%"
                      controls
                      preload="metadata"
                    >
                      <source
                        :src="axios.defaults.url + file.full_path"
                        type="video/mp4"
                      />
                      Ihr Browser unterstützt das Video-Tag nicht.
                    </video>
                  </span>
                  <span v-else
                    ><v-chip>{{ file.name }}</v-chip></span
                  >
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-spacer />
    </v-row>

    <v-row class="mt-16"
      ><v-spacer />
      <v-col :cols="contactCols">
        <h3 class="encodeFont">
          Noch nicht das Richtige gefunden oder ein individuelles Anliegen?<br />
          Über das Kontaktformular sind unsere Mitarbeiter gern erreichbar.
        </h3> </v-col
      ><v-spacer />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "halloPraktikumSchueler",

  props: ["faqData"],

  data() {
    return {
      panel: [],
    };
  },

  components: {},

  computed: {
    fontSize() {
      if (window.innerHeight < 750) {
        return 1.5;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1.5;
        case "sm":
          return 2;
        case "md":
          return 2;
        case "lg":
          return 2.5;
        case "xl":
          return 2.5;
        default:
          return 3;
      }
    },
    mainCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 10;
        case "sm":
          return 9;
        case "md":
          return 8;
        case "lg":
          return 6;
        case "xl":
          return 4;
        default:
          return 6;
      }
    },
    contactCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 11;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 8;
        default:
          return 6;
      }
    },
    cardCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        default:
          return false;
      }
    },
    isXS() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        default:
          return false;
      }
    },
    textCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 11;
        case "md":
          return 10;
        case "lg":
          return 8;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  methods: {
    getFileExtension(file) {
      return file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    },
    isGraphic(file) {
      let imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];

      return imageExtensions.indexOf(this.getFileExtension(file)) >= 0;
    },
    isVideo(file) {
      let imageExtensions = ["mp4"];

      return imageExtensions.indexOf(this.getFileExtension(file)) >= 0;
    },
  },

  mounted() {},
};
</script>
