<template>
  <v-app>
    <v-dialog
      persistent
      :hide-overlay="true"
      :value="showCookie"
      transition="dialog-bottom-transition"
      no-click-animation
      max-width="90vw"
    >
      <template v-slot:default="">
        <v-card style="background-color: #171717" class="cookieBanner">
          <v-card-title style="font-size: larger; color: white">
            Cookieeinstellungen</v-card-title
          >
          <v-card-text style="font-size: large; color: white">
            <a @click="gotoImpressum">Impressum</a> |
            <a @click="gotoDatenschutz">Datenschutz</a>
            <br />
            <br />
            Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von
            ihnen sind essenziell, während andere uns helfen, diese Website und Ihre
            Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z.
            B. IP-Adressen). Weitere Informationen über die Verwendung Ihrer Daten finden
            Sie in unserer Datenschutzerklärung. Sie können Ihre Auswahl jederzeit unter
            den Einstellungen widerrufen oder anpassen.<br />
            <br />
            <button text @click="showConsentManager = true">
              <u style="color: var(--v-accent-base)">Einstellungen verwalten</u>
            </button>
          </v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-btn large block class="primaryButton py-3" @click="acceptCookies()"
                  >Alle Akzeptieren</v-btn
                >
              </v-col>
              <v-col>
                <v-btn @click="denyCookies()" large block text class="lightOutline py-3"
                  >Ablehnen</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-dialog>

    <v-overlay :value="showConsentManager" :dark="false" style="z-index: 999">
      <v-card
        flat
        class="pa-4 scrollable"
        style="
          max-height: 60vh;
          max-width: 95%;
          margin: 0 auto;
          background-color: #171717;
        "
      >
        <v-container>
          <v-row>
            <v-col>
              <h2 class="encodeFont" style="color: white">Datenschutzeinstellungen</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="color: white">
              Wir verwenden Cookies und andere Technologien auf unserer Website. Einige
              von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre
              Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden
              (z. B. IP-Adressen). Weitere Informationen über die Verwendung Ihrer Daten
              finden Sie in unserer Datenschutzerklärung. <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card flat outlined class="pa-2">
                <h3 class="encodeFont">Essenziell</h3>
                <p>
                  Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die
                  einwandfreie Funktion der Website erforderlich. Speicherungszeitram: 1
                  Jahr
                </p>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card flat outlined class="pa-2">
                <v-container>
                  <v-row>
                    <v-col>
                      <h3 class="encodeFont">Mapbox (externe Medien)</h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>
                        Zum Zwecke der Verbesserung der Nutzererfahrung nutzen wir zur
                        Darstellung von Karten Mapbox der Fa. Mapbox, Inc., 740 15th
                        Street NW, Washington DC 20005, USA. Speicherungszeitram: 1 Jahr.
                        <br />
                        Weitere Informationen können Sie unserer
                        <a @click="gotoDatenschutz">Datenschutzerklärung</a>
                        entnehmen.
                      </p>
                    </v-col>
                    <v-col cols="auto">
                      <v-switch v-model="allowMapbox" :value="allowMapbox"></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            
            <v-col>
              <v-btn
                elevation="0"
                large
                block
                class=" py-3 primaryOnWhiteButton primaryOutline"
                @click="saveSelected()"
                >Auswahl speichern</v-btn
              >
            </v-col>

            <v-col>
              <v-btn
                elevation="0"
                large
                block
                class="primaryButton py-3"
                @click="acceptCookies()"
                >Alle zulassen</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>
    <v-main>
      <router-view />
    </v-main>

    <errorDisplay :showErrorDisplay="showErrorDisplay" />
  </v-app>
</template>

<script>
import errorDisplay from "./components/Main/ErrorDisplay.vue";

const axios = require("axios");

export default {
  name: "app",

  data() {
    return {
      showConsentManager: false,
      showCookie: false,

      allowMapbox: false,

      showErrorDisplay: 0,
    };
  },

  components: {
    errorDisplay,
  },

  methods: {
    saveSelected() {
      if (this.allowMapbox) {
        this.acceptCookies();
      } else {
        this.denyCookies();
      }
    },
    acceptCookies() {
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "cookieConsent" + "=" + true + ";" + expires + ";path=/";

      window.dispatchEvent(
        new CustomEvent("localstorage-changed", {
          detail: {
            storage: localStorage.getItem("cookie:accepted"),
          },
        })
      );

      this.showCookie = false;
      this.showConsentManager = false;

      this.allowMapbox = true;

      if (this.$route.path.includes("/suche")) {
        window.location.reload();
      }
    },
    denyCookies() {
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "cookieConsent" + "=" + false + ";" + expires + ";path=/";

      window.dispatchEvent(
        new CustomEvent("localstorage-changed", {
          detail: {
            storage: localStorage.getItem("cookie:accepted"),
          },
        })
      );

      this.showCookie = false;
      this.showConsentManager = false;

      this.allowMapbox = false;

      if (this.$route.path.includes("/suche")) {
        window.location.reload();
      }
    },
    gotoImpressum() {
      this.$router.replace("/impressum");
    },
    gotoDatenschutz() {
      this.$router.replace("/datenschutz");
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    checkCookie() {
      let consentCookie = this.getCookie("cookieConsent");

      if (consentCookie == "") {
        this.showCookie = true;
        this.allowMapbox = false;

        return 0;
      } else if (!consentCookie || consentCookie == "false") {
        this.showCookie = false;
        this.allowMapbox = false;

        return 1;
      } else {
        this.showCookie = false;
        this.allowMapbox = true;

        return 2;
      }
    },
    detectMobilePlatform() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "Unknown";
    },
  },

  mounted() {
    window.addEventListener("openConsentManager", () => {
      this.showConsentManager = true;
    });

    window.addEventListener("showErrorDisplay", () => {
      this.showErrorDisplay++;
    });

    this.checkCookie();
    axios.post(axios.defaults.baseURL + "click/new/");
  },
};
</script>

<style>
@import "./assets/css/style.css";
@import "./assets/css/componentStyle.css";
@import "./assets/css/vueStyle.css";
@import "./assets/css/icons.css";
</style>
